import { Button } from 'components/Button/Button';
import Table, { TableRow } from 'components/Table';
import formatFieldName from 'pages/Tokens/components/Form/TokenDetails/components/SummaryStep/utils/formatFieldName';
import renderConstituentValue from 'pages/Instruments/components/Form/EtpDetails/components/ReviewStep/utils/renderConstituentValue';
import renderFieldValue from 'pages/Tokens/components/Form/TokenDetails/components/SummaryStep/utils/renderFieldValue';
import {
  StyledColumn,
  StyledTableCell,
} from 'pages/Tokens/components/Form/TokenDetails/components/SummaryStep/SummaryStep.styles';
import {
  TokenDetailsStepType,
  GENERAL_DETAILS_STEP,
  CONSTITUENT_STEP,
} from 'pages/Tokens/components/Form/TokenDetails/TokenDetails.steps';
import { ReactComponent as PencilIcon } from 'assets/pencil-alt.svg';
import { Row } from 'components/Grid';
import { getPartnerDetailsUrl } from 'pages/Tokens/components/Form/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useTokenContext } from 'contexts/tokens';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { ReviewStepColumnsConfig } from 'pages/Instruments/components/Form/EtpDetails/components/ReviewStep/ReviewStepColumnsConfig';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import { TableBody } from '@mui/material';

interface ReviewStepProps {
  goBack: () => void;
  goToStep: (step: TokenDetailsStepType) => void;
}

function SummaryStep({ goBack, goToStep }: ReviewStepProps) {
  const navigate = useNavigate();
  const params = useParams();
  const { currentToken, tokenFormsData } = useTokenContext();
  const goToPartners = () => navigate(getPartnerDetailsUrl(params.id));

  return (
    <>
      <Row>
        <StyledColumn cols={12}>
          <ProductConfigHeader title="General Details" />
          <Button variant="inverse" onClick={() => goToStep(GENERAL_DETAILS_STEP)}>
            <PencilIcon />
          </Button>
        </StyledColumn>
      </Row>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBody>
          {Object.entries(tokenFormsData[GENERAL_DETAILS_STEP] || {}).map(([key, value]) => (
            <TableRow data-qa-id={key} key={key}>
              <StyledTableCell>{formatFieldName(key)}</StyledTableCell>
              <StyledTableCell data-qa-id={value}>
                {renderFieldValue(value, key, currentToken?.ticker, currentToken?.destinationChain)}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Row>
        <StyledColumn cols={12}>
          <ProductConfigHeader
            title={
              (tokenFormsData[CONSTITUENT_STEP] || []).length > 1 ? 'Constituents' : 'Constituent'
            }
          />
          <Button variant="inverse" onClick={() => goToStep(CONSTITUENT_STEP)}>
            <PencilIcon />
          </Button>
        </StyledColumn>
      </Row>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBody>
          {(tokenFormsData[CONSTITUENT_STEP] || []).map((constituent) => (
            <TableRow data-qa-id={constituent.name} key={constituent.name}>
              <StyledTableCell>{formatFieldName(constituent.ticker)}</StyledTableCell>
              <StyledTableCell>{renderConstituentValue(constituent)}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Row>
        <StyledColumn offset={8} cols={2}>
          <Button
            data-qa-id="backButton"
            fullWidth
            type="button"
            variant="secondary"
            onClick={goBack}
          >
            Back
          </Button>
        </StyledColumn>
        <StyledColumn cols={2}>
          <Button
            data-qa-id="nextButton"
            fullWidth
            type="button"
            variant="primary"
            onClick={goToPartners}
          >
            Next
          </Button>
        </StyledColumn>
      </Row>
    </>
  );
}

export default SummaryStep;
