// components
import Notification from 'components/Notification';
import { StyledCutoffContainer } from 'pages/Orders/Orders/Order/Order.styles';
import { CASH, ORDER_IN_KIND } from 'utils/constants';
import { Order } from 'utils/types';

interface CutoffInfoProps {
  deliveryType?: string;
  product: Order['product'];
}

function getClosedMarketMessage(product: Order['product'], deliveryType?: string): string {
  const { ticker, isCashMarketOpen, isInKindMarketOpen, primaryExchange } = product;
  const {
    calendar: { timezone },
    marketOpen: openTime,
  } = primaryExchange;

  const baseMessage = `Market will reopen at <br />${openTime} (${timezone})`;

  if (!isCashMarketOpen && !isInKindMarketOpen) {
    return `${ticker} is past cutoff. ${baseMessage}`;
  }

  if (deliveryType === CASH && !isCashMarketOpen) {
    return `Cash orders for ${ticker} are past cutoff.<br />${baseMessage}`;
  }

  if (deliveryType === ORDER_IN_KIND && !isInKindMarketOpen) {
    return `In-Kind orders for ${ticker} are past cutoff.<br />${baseMessage}`;
  }

  return '';
}

const CutoffInfo = ({ product, deliveryType }: CutoffInfoProps) => {
  const marketClosedMessage = getClosedMarketMessage(product, deliveryType);

  return Boolean(marketClosedMessage) ? (
    <StyledCutoffContainer>
      <Notification
        message={marketClosedMessage}
        multiline="true"
        variant="warning"
        withcountdown="false"
      />
    </StyledCutoffContainer>
  ) : null;
};

export default CutoffInfo;
