import { Contact, getContactType, PartnerContact } from 'utils/types/contacts';
import { MuiStyledTableCell, MuiStyledTableCheckboxCell } from 'components/Table/TableCell';
import { Checkbox, CheckboxProps } from 'components/BooleanInputs';
import { MuiStyledTableCellChevron } from 'shared/Tables/ContactsTable/ContactsTable.styles';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledActionsTableCell } from 'components/Table/table.styles';
import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';

interface EditContactListTableRowProps {
  contact: Contact;
  showActionsCell?: boolean;
  deleteAction?: (data: { contact?: Contact; partner?: PartnerContact }) => void;
  checkboxProps?: CheckboxProps;
}

export const EditContactListTableRow = ({
  contact,
  checkboxProps,
  deleteAction,
}: EditContactListTableRowProps) => {
  return (
    <MuiStyledTableRow data-qa-id={`row-${contact.name}`}>
      {checkboxProps && (
        <MuiStyledTableCheckboxCell data-qa-id={`${contact.name}-checkbox`}>
          <Checkbox {...checkboxProps} />
        </MuiStyledTableCheckboxCell>
      )}
      <MuiStyledTableCellChevron></MuiStyledTableCellChevron>
      <MuiStyledTableCell data-qa-id={`name-${contact.name}`}>{contact.name}</MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`partner-${contact.name}`}>
        {getContactType(contact.type)}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`email-${contact.name}`}>{contact.email}</MuiStyledTableCell>
      <MuiStyledActionsTableCell data-qa-id="editIcons">
        {deleteAction && (
          <TrashIcon
            data-qa-id="trashIcon"
            onClick={(event) => {
              deleteAction({ contact: contact });
              event.stopPropagation();
            }}
          />
        )}
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};
