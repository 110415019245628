import PageTitle from 'components/PageTitle';
import ProductIcon from 'components/ProductIcon';
import { Order } from 'utils/types/orders';
import { ProductType } from 'utils/types/product';
import { Box, Stack, Typography } from '@mui/material';
import Tag from 'components/Tag/Tag';
import replace from 'lodash/replace';
import titelize from 'utils/decorators/titelize';
import { orderStatusTagVariants } from 'utils/constants/orders';
import { variantTypes } from 'components/Tag/Tag.types';

export interface OrderPageHeaderProps {
  productType: ProductType;
  order?: Order | null;
  isNewOrder?: boolean;
  isDelegatedOrder?: boolean;
}

export const OrderPageHeader = ({
  productType,
  order,
  isNewOrder,
  isDelegatedOrder,
}: OrderPageHeaderProps) => {
  return (
    <>
      {order?.status ? (
        <Box display="flex" gap={2}>
          <ProductIcon
            className="product-icon"
            iconUrl={order?.product.fundIcon}
            ticker={order?.product.ticker}
            size="40px"
          />
          <Stack>
            <Typography variant="subheadingLarge" color={'#FFF'}>
              {order?.externalId ?? ''}
            </Typography>
            <Box display="flex" alignItems="center" gap={2}>
              <Tag
                label={replace(titelize(order.status), 'Ap ', 'AP ')}
                variant={orderStatusTagVariants[order.status] as variantTypes}
              />
              {order?.deferredAt && <Tag label="Deferred" variant={'yellow' as variantTypes} />}
              <Typography color={'#838F98'}>{order?.product.ticker} order</Typography>
            </Box>
          </Stack>
        </Box>
      ) : (
        isNewOrder && (
          <PageTitle>
            {isDelegatedOrder
              ? `Create Delegated ${productType} Order`
              : `Create ${productType} Order`}
          </PageTitle>
        )
      )}
    </>
  );
};
