import Modal from 'styled-react-modal';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { styled as MuiStyled, IconButton, Dialog, DialogContent, Stack, Box } from '@mui/material';

export type StyledModalInterface = {
  customwidth?: string;
};

export const StyledModal = Modal.styled`
  width:${(props: any) => props.customwidth ?? '560px'};
  min-height:${(props: any) => props.customwidth ?? '180px'};
  padding: 0;
`;

export const StyledModalContent = MuiStyled(Box)`
  width: ${(props: any) => props.customwidth ?? '560px'};
  min-width: '560px';
  position: relative;
`;

export const StyledModalCloseIcon = MuiStyled(CloseIcon)`
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const StyledModalContacts = MuiStyled(StyledModal)`
  width: 720px;
`;

export const StyledModalNavReview = MuiStyled(StyledModal)`
  width: 980px;
  padding: 16px;
`;

export const StyledDetail = MuiStyled('span')`
  font-weight: bold;
`;

export const StyledFooterWrapper = MuiStyled(Box)`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  gap: 8px;
`;

export const MuiStyledDialog = MuiStyled(Dialog)<StyledModalInterface>(
  ({ theme, customwidth = '560px' }) => ({
    '& .MuiDialog-paper': {
      width: customwidth,
      maxWidth: customwidth,
      minHeight: '180px',
      padding: 0,
      borderRadius: theme.spacing(2),
    },
    '& .MuiDialogContent-root': {
      padding: 0,
    },
  })
);

export const MuiStyledDialogContent = MuiStyled(DialogContent)({
  position: 'relative',
  width: '100%',
  minWidth: '560px',
});

export const MuiStyledCloseButton = MuiStyled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
}));

export const MuiStyledModalFormContent = MuiStyled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  padding: 0,
}));

export const MuiStyledModalFooterButtons = MuiStyled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  flexDirection: 'row',
  width: '100%',
  boxSizing: 'border-box',
  justifyContent: 'flex-end',
}));
