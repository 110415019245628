import { Stack, styled as MuiStyled, TableRow as MuiTableRow } from '@mui/material';

type TableRowProps = {
  breakWords?: boolean;
  hoverEffects?: boolean;
  isExpanded?: boolean;
  iserror?: 'true' | 'false';
  withActionsTableCell?: boolean;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
};

export const MuiStyledTableRow = MuiStyled(MuiTableRow)<TableRowProps>(
  ({ theme, iserror = 'false', isExpanded, withActionsTableCell }) => ({
    ...(iserror === 'true' && {
      backgroundColor: theme.palette.error.lighter,
    }),
    ...(isExpanded && {
      backgroundColor: theme.palette.grey[200],
    }),
    ...(withActionsTableCell && {
      '&:hover': {
        backgroundColor: theme.palette.overlay.secondary.hover,
        '.editIcons': {
          display: 'inline-grid',
        },
        '.actionsCell': {
          visibility: 'unset',
        },
      },
    }),
  })
);

// @TODO useTheme add theme colors
const TableRow = MuiStyled(MuiStyledTableRow)<TableRowProps>`
  height: 48px;
  ${({ breakWords }) => breakWords && 'word-break: break-all'};
  ${({ theme, isExpanded }) => isExpanded && `background-color: ${theme.palette.tertiary.lighter}`};

  ${({ hoverEffects = true, theme, onClick }) =>
    hoverEffects &&
    `
  :hover {
    background-color: #F2F2F2;

    .editIcons {
      display: inline-grid;
    }
    .actionsCell {
      visibility: unset;
    }

    cursor: ${onClick ? 'pointer' : 'auto'};
  }`}
`;

export default TableRow;

export const MuiStyledEmptyTableRow = MuiStyled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const MuiStyledRowTitle = MuiStyled(Stack)(({ theme }) => ({
  height: '54px',
  width: '100%',
  padding: theme.spacing(2),
  justifyContent: 'center',
}));

export const MuiStyledTableHeader = MuiStyled(Stack)(({ theme }) => ({
  height: '64px',
  width: '100%',
  padding: theme.spacing(2),
  justifyContent: 'center',
}));
