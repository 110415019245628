import React from 'react';
import { MenuItem, Typography } from '@mui/material';

interface ButtonDropdownItemProps {
  id: string;
  onClick: () => void;
  label: string;
  disabled?: boolean;
}

export const ButtonDropdownItem: React.FC<ButtonDropdownItemProps> = ({
  id,
  onClick,
  label,
  disabled = false,
}) => {
  return (
    <MenuItem
      sx={{
        minWidth: '192px',
        margin: '0 8px',
        padding: '0 2px 0 0 ',
      }}
      data-qa-id={id}
      onClick={(event) => {
        event?.stopPropagation();
        onClick();
      }}
      disabled={disabled}
    >
      <Typography variant="labelMedium" padding={'12px 16px'}>
        {label}
      </Typography>
    </MenuItem>
  );
};
