import Column from 'components/Grid/Column';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import Row from 'components/Grid/Row';
import Select from 'components/Select';
import useAppDispatch from 'hooks/useAppDispatch';
import useCurrencyAsOptions from 'pages/Instruments/hooks/useCurrencyOptions';
import { CustomModal } from 'shared/Modals/Modal';
import {
  ExchangeLocalTickerType,
  ExchangeType,
  ExchangesProps,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { IModalWithData } from 'shared/Modals/types';
import { ProductExchangeModalData } from 'shared/Modals/ProductExchange/ProductExchangeModal';
import { ProductStatus } from 'utils/types/product';
import { instrumentExchangeLocalTickerSchemaUrl } from 'components/Form/formSchemas';
import { useCurrentInstrument, useCurrentInstrumentFormData } from 'store/instruments/selectors';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { instrumentsActions } from 'store/instruments/slice';
import { useMutation } from 'react-query';
import { saveInstrumentApiCall } from 'utils/api/instruments';
import { EXCHANGE_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { FormFooter } from 'components/Form/components/FormFooter';
import SingleDatePicker from 'components/DatepickerSingleDate';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { Box, Divider, Stack } from '@mui/material';

export const EditProductExchangeModalTicker = ({
  closeModal,
  onCloseModalAction,
  data,
}: IModalWithData) => {
  const dispatch = useAppDispatch();
  const { instrument } = useCurrentInstrument();
  const { localTicker, exchangeFormData } = data?.data as ProductExchangeModalData;
  const isNewTicker = !localTicker?.name;
  const productExchangesFormData = useCurrentInstrumentFormData(EXCHANGE_STEP) as ExchangesProps;
  const currencyOptions = useCurrencyAsOptions();
  const { populateCurrentInstrument } = instrumentsActions;

  const updateProductExchangeTickersMutation = useMutation({
    mutationFn: (updatedExchange: ExchangeType) => {
      const updatedExchangesList = productExchangesFormData.map((exc) => {
        if (exc.id === String(updatedExchange.id)) return updatedExchange;
        return exc;
      });
      return saveInstrumentApiCall(instrument?._actions?.update?.uri ?? '', {
        [EXCHANGE_STEP]: updatedExchangesList,
      });
    },
    onSuccess: (res) => {
      dispatch(populateCurrentInstrument(res));
      dispatch(
        createNotification({
          message: isNewTicker
            ? 'Ticker has been added to the Exchange.'
            : 'Ticker has been updated.',
          title: 'Product Exchange',
          type: 'success',
        })
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(errorNotification(err.message ?? ''), err));
    },
  });

  const handleSubmit = (localTickerUpdates: ExchangeLocalTickerType) => {
    const tickers = (exchangeFormData?.localTickers || []).filter(
      (ticker) =>
        !localTicker || ticker.currency !== localTicker.currency || ticker.name !== localTicker.name
    );

    updateProductExchangeTickersMutation.mutate({
      ...(exchangeFormData as ExchangeType),
      localTickers: [...tickers, localTickerUpdates],
    });
  };

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        header={
          <CardHeaderTitleWithLabel
            label={exchangeFormData?.name}
            title={isNewTicker ? 'Add Local Ticker' : 'Edit Local Ticker'}
          />
        }
        body={
          <Form
            schemaUrl={instrumentExchangeLocalTickerSchemaUrl}
            onSubmit={handleSubmit}
            initialValues={localTicker}
          >
            <Stack padding={2}>
              <Row>
                <Column cols={12} gutter="noXGutter">
                  <Input name="name" />
                </Column>
              </Row>
              <Row>
                <Column cols={12} gutter="noXGutter">
                  <Select name="currency" options={currencyOptions} allCaps />
                </Column>
              </Row>
              <Row>
                <Column cols={12} gutter="noXGutter">
                  <SingleDatePicker name="listingDate" data-qa-id="listingDate" />
                </Column>
              </Row>
            </Stack>
            <Divider sx={{ width: '100%' }} />
            <Box padding={2}>
              <FormFooter
                submitButtonText={
                  instrument?.status === ProductStatus.ACTIVE ? 'Apply and Save' : 'Apply'
                }
                isLoading={updateProductExchangeTickersMutation.isLoading}
                onCancel={closeModal}
              />
            </Box>
          </Form>
        }
        onClose={closeModal}
      ></Card>
    </CustomModal>
  );
};
