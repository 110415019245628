import { PortfolioComposition } from 'utils/types/pcfs';
import Table, { TableCell, TableRow } from 'components/Table';
import { Box, TableBody, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';

interface PCFUnderlyingInformationProps {
  pcf?: PortfolioComposition;
}

function PCFUnderlyingInformation({ pcf }: PCFUnderlyingInformationProps) {
  return pcf?.official?.product?.underling?.name && pcf?.official?.product?.underling?.isin ? (
    <Card
      header={
        <Box padding={'16px'}>
          <Typography variant="subheadingMedium" sx={{ wordBreak: 'break-word' }}>
            Underlying Information
          </Typography>
        </Box>
      }
      headerDivider={false}
      body={
        <Table noPadding>
          <TableBody>
            <TableRow>
              <TableCell>{pcf?.official?.product?.underling.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{pcf?.official?.product?.underling.isin}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      }
    />
  ) : null;
}

export default PCFUnderlyingInformation;
