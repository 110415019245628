/* eslint-disable complexity */

import { Button } from 'components/Button/Button';
import Input from 'components/Input';
import Notification from 'components/Notification';
import useToggle from 'hooks/useToggle';

import {
  ETPType,
  GeneralConstituentAssetFormType,
  Instrument,
  isEtpConstituent,
  ProductConstituentAssetType,
  TokenConstituentAssetType,
} from 'utils/types/product';
import { ConstituentModalData } from 'shared/Modals/Constituents/types';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { ProductStatus, RebalanceStrategy } from 'utils/types/product';
import { Toggle } from 'components/BooleanInputs';
import Select from 'components/Select';
import { useAssetsOptions } from 'hooks/useAssetsQuery';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { useCurrentProduct } from 'hooks/useCurrentProduct';
import { Column, Row } from 'components/Grid';
import { Box, Divider, Stack, styled } from '@mui/material';
import {
  instrumentConstituentAssetsSchemaUrl,
  tokenConstituentAssetsSchemaUrl,
} from 'components/Form/formSchemas';
import Form from 'components/Form/Form';
import { useState } from 'react';
import { CONSTITUENT_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

export const EditConstituentModal = ({ closeModal, data }: IModalWithData) => {
  const isToken = data?.custom?.isToken;

  const { product, etpMutation, constituents, tokenMutation } = useCurrentProduct(
    isToken ? 'Token' : 'ETP',
    closeModal
  );

  const { constituent } = data?.data as ConstituentModalData;

  const [values, setValues] = useState((constituent as GeneralConstituentAssetFormType) ?? {});

  const isStaking = values?.isStaking;

  const { assetsOptions, isLoadingAssets, setFilterText } = useAssetsOptions();

  const isSingleAssetStrategy =
    product &&
    'rebalancingStrategy' in product &&
    ((product as Instrument).rebalancingStrategy?.type === RebalanceStrategy.SHORT_STRATEGY ||
      (product as Instrument).rebalancingStrategy?.type === RebalanceStrategy.LEVERAGED_STRATEGY);

  const [isLeveraged, toggleIsLeveraged] = useToggle(Boolean(constituent?.isLeveraged));

  const handleSubmit = (constituent: GeneralConstituentAssetFormType) => {
    const updatedConstituents = constituents.map((con) => {
      if (con._id === String(constituent._id)) return constituent;
      return con;
    });
    isToken
      ? tokenMutation?.mutate({
          [CONSTITUENT_STEP]: updatedConstituents as TokenConstituentAssetType[],
        })
      : etpMutation?.mutate({
          [CONSTITUENT_STEP]: updatedConstituents as ProductConstituentAssetType[],
        });
  };

  const Footer = (
    <>
      <MuiStyledModalFooterButtons>
        <Button variant="secondary" fullWidth onClick={closeModal} type="button">
          Cancel
        </Button>
        <Button
          fullWidth
          type="submit"
          isLoading={etpMutation?.isLoading || tokenMutation?.isLoading}
          variant="primary"
        >
          {product?.status === ProductStatus.ACTIVE ? 'Apply and Save' : 'Apply'}
        </Button>
      </MuiStyledModalFooterButtons>
      {isEtpConstituent(constituent) && constituent?.updatedBy && constituent?.updatedAt && (
        <StyledUpdateNote>
          Last update by: {constituent?.updatedBy!} at {constituent?.updatedAt}
        </StyledUpdateNote>
      )}
    </>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        header={
          <CardHeaderTitleWithLabel label="Constituent" title={`Configure ${constituent?.name}`} />
        }
        body={
          <Form
            initialValues={constituent}
            schemaUrl={
              isEtpConstituent(constituent)
                ? instrumentConstituentAssetsSchemaUrl
                : tokenConstituentAssetsSchemaUrl
            }
            onSubmit={handleSubmit}
            onChange={setValues}
          >
            <Stack padding={2} gap={1}>
              <Row>
                <Column cols={6} gutter={'noGutter'}>
                  <Toggle name="isStaking" />
                </Column>
                {isEtpConstituent(constituent) && (
                  <Column cols={6} gutter={'onlyRight'}>
                    <Toggle name="isActive" />
                  </Column>
                )}
              </Row>
              {isStaking && (
                <>
                  <Column cols={6} gutter={'noGutter'}>
                    <Input name="stakingFee" step="any" />
                  </Column>
                  <Column cols={6} gutter={'noGutter'}>
                    <Toggle name="isMev" />
                  </Column>
                </>
              )}
              <Column cols={6} gutter={'noGutter'}>
                <Toggle name="interestGenerating" />
              </Column>
              {isSingleAssetStrategy && (
                <>
                  <Column cols={6} gutter={'noGutter'}>
                    <Toggle name="isLeveraged" onChange={toggleIsLeveraged} />
                  </Column>
                  {isLeveraged && (
                    <Row>
                      <Column cols={6} gutter={'noGutter'}>
                        <Input name="leverageRatio" />
                      </Column>
                      <Column cols={6} gutter={'onlyRight'}>
                        <Select
                          filterable
                          name="pairAsset"
                          options={assetsOptions}
                          placeholder="USD"
                          onFilter={setFilterText}
                          disabled={isLoadingAssets}
                        />
                      </Column>
                    </Row>
                  )}
                </>
              )}
              {!isToken && product?.type !== ETPType.SINGLE_ASSET && (
                <>
                  <Column cols={6} gutter={'noGutter'}>
                    <Input name="rebalanceFeeBuyBps" />
                  </Column>
                  <Column cols={6} gutter={'noGutter'}>
                    <Input name="rebalanceFeeSellBps" />
                  </Column>
                </>
              )}
              <Column cols={6} gutter={'noGutter'}>
                <Input name="rounding" />
              </Column>
              <Notification
                message={`Updating rounding will impact all Onyx generated amounts for ${constituent?.name}`}
                title="Changing Rounding"
                variant="warning"
              />
            </Stack>
            <Divider sx={{ width: '100%' }} />
            <Box padding={2}>{Footer}</Box>
          </Form>
        }
        onClose={closeModal}
      ></Card>
    </CustomModal>
  );
};

export const StyledUpdateNote = styled('div')(({ theme }) => ({
  marginInline: 16,
  fontSize: '0.75rem',
  lineHeight: '1rem',
  color: theme.palette.neutral[50],
  marginTop: 16,
  textAlign: 'center',
}));
