import { Box, Typography } from '@mui/material';
import getUTCHours from 'utils/decorators/UTCHours';
import { TimeSlider } from './TimeSlider';
import { Rebalance } from 'utils/types/rebalance';
import { DateTime } from 'utils/datetime';

function isBeforeTime(dateHours: number, closingTime: number) {
  return dateHours < closingTime;
}

function getAMPM(hourOfDay: number = 17) {
  const isPM = hourOfDay >= 12;
  const formattedHours = hourOfDay % 12 || 12;
  const amPm = isPM ? 'PM' : 'AM';
  return `${formattedHours} ${amPm}`;
}

const getSliderValue = (
  dateHoursUTC: number,
  isDuringWorkingHours: boolean,
  border: number,
  range: number,
  isHistoricRebalanceDate: boolean
) => {
  if (isHistoricRebalanceDate) {
    return 100; // End of range
  }

  return isDuringWorkingHours
    ? 100 - ((border - dateHoursUTC) / range) * 100
    : ((dateHoursUTC - border) / range) * 100;
};

const getLengthOfGreenRange = (
  isInRange: Boolean,
  dateHoursZurichTime: number,
  closingTime: number,
  openingTime: number
) => {
  if (isInRange && dateHoursZurichTime > closingTime) {
    if (dateHoursZurichTime - openingTime > 12) return 12;
    return dateHoursZurichTime - openingTime;
  }
  return 8;
};

// Default Start time is used for the TimeBar presentation
export const TimeBar = ({
  date,
  pricingTime = { generationTime: '17:00', generationTimeZone: 'Europe/Zurich' },
  startTime = 9,
  width = 300,
  isCryptoCompare,
  hideCryptoCompare,
}: {
  date: Date | string;
  startTime?: number;
  pricingTime?: Rebalance['product']['portfolioCompositionConfiguration'];
  width?: number;
  isCryptoCompare: boolean;
  hideCryptoCompare?: boolean;
}) => {
  const [hours, minutes] = pricingTime.generationTime.split(':');
  const isHistoricRebalanceDate = new DateTime(date).toDateString() < DateTime.now().toDateString();

  const priceCloseTimeUTC = new DateTime()
    .set('hours', parseInt(hours))
    .set('minutes', parseInt(minutes))
    .fromZonedTimeToUtc(pricingTime.generationTimeZone);
  const priceOpenTimeUTC = new DateTime()
    .set('hours', startTime)
    .set('minutes', 0)
    .fromZonedTimeToUtc(pricingTime.generationTimeZone);

  const closingHour = Number(priceCloseTimeUTC.format('HH'));
  const zurichOpeningTimeInUTC = Number(priceOpenTimeUTC.format('HH'));
  const dateHoursUTC = getUTCHours.hours(date) + new Date(date).getUTCMinutes() / 60;
  const currentHours = Number(new DateTime().tzAware('UTC').format('HH'));

  // In case of crytpocompare price if it is provided after closing hours we are extending closing hours
  const hoursOfGreenRange = getLengthOfGreenRange(
    isCryptoCompare,
    dateHoursUTC,
    closingHour,
    zurichOpeningTimeInUTC
  );

  // Time border that is used for split between green and dark bar part
  const timeBorder = Math.round(startTime + hoursOfGreenRange);

  const isDuringWorkingHours = isBeforeTime(currentHours, timeBorder);

  const sliderBarPositionValue = getSliderValue(
    currentHours,
    isDuringWorkingHours,
    timeBorder,
    isDuringWorkingHours ? hoursOfGreenRange : 12 - hoursOfGreenRange,
    isHistoricRebalanceDate
  );

  const withingWorkingHours = isHistoricRebalanceDate
    ? false // If historicRebalanceDate is true
    : isDuringWorkingHours; // Else use isDuringWorkingHours

  return (
    <Box width={`${width}px`}>
      <Box display={'flex'} justifyContent={'space-between'} marginBottom={0.5}>
        {!hideCryptoCompare && <Typography variant="bodySmall">Cryptocompare source</Typography>}
        <Typography variant="bodySmall">Index provider source</Typography>
      </Box>
      <Box height={'6px'} display={'flex'} marginBottom={0.5}>
        <TimeSlider
          withingWorkingHours={withingWorkingHours}
          workingHoursRangeLength={(hoursOfGreenRange * 100) / 12}
          nonWorkingHoursRangeLength={100 - (hoursOfGreenRange * 100) / 12}
          sliderValue={sliderBarPositionValue}
        />
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} marginBottom={0.5}>
        <Typography
          fontSize={'10px'}
          lineHeight={'10px'}
          marginLeft={`${width * ((timeBorder - startTime) / 12) - 10}px`}
        >
          {`${getAMPM(timeBorder)}`}
        </Typography>
      </Box>
    </Box>
  );
};
