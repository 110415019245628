import { StyledColoredTableCell } from 'pages/Rebalance/RebalancePage.styles';
import { TableCell, TableRow } from 'components/Table';
import { RebalanceDetails } from 'utils/types/rebalance';
import { escapeNaN, formatAsPercentage, getColorForNumber } from 'utils/formatting';
import Tag from 'components/Tag/Tag';

type TradeTableRowProps = {
  trade: RebalanceDetails;
  index: number;
};

export const RebalancesCardTradeTableRow = ({ trade, index }: TradeTableRowProps) => (
  <TableRow data-qa-id={`${index}-row`}>
    <TableCell data-qa-id="ticker">{trade.ticker}</TableCell>
    <TableCell data-qa-id="action">
      <Tag label={trade.action} variant={trade.action === 'SELL' ? 'red' : 'green'} />
    </TableCell>
    <StyledColoredTableCell data-qa-id="difference" color={getColorForNumber(trade.preDifference)}>
      {formatAsPercentage(trade.preDifference)}
    </StyledColoredTableCell>
    <TableCell data-qa-id="netTrades">{escapeNaN(trade.actualTradeCash)}</TableCell>
    <TableCell data-qa-id="priceQuote">{escapeNaN(trade.price)}</TableCell>
  </TableRow>
);
