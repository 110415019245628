import { OptionType } from 'components/Select/Select.types';
import { OrderStatus } from 'utils/types/orders';

export enum ORDERS_TAB_STATUS {
  IN_PROGRESS = 'In Progress',
  HISTORICAL_ORDERS = 'Historical Orders',
}

export const DEFAULT_TAB_STATUS = ORDERS_TAB_STATUS.IN_PROGRESS;

export const tabInProgress = 'inprogress';
export const tabHistorical = 'historical';

export type OrderTabType = typeof tabInProgress | typeof tabHistorical;

export const ORDER_TABS = [
  [ORDERS_TAB_STATUS.IN_PROGRESS, tabInProgress],
  [ORDERS_TAB_STATUS.HISTORICAL_ORDERS, tabHistorical],
];

export type OrdersFiltersData = {
  company?: string | null;
  product?: string | null;
  search?: string | null;
  date?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  status?: string | string[] | null;
  tab?: string | null;
  sort?: string | string[] | null;
  page?: number | null;
  pageSize?: number | null;
  // type?: string; // @TODO TOKENS - Add filter for type, i.e. mint/burn for tokens and creation/redemption for ETPs when BE work is done
};

export const defaultOrdersFiltersData = {
  company: null,
  product: null,
  search: null,
  date: null,
  startDate: null,
  endDate: null,
  status: null,
  sort: null,
  page: 1,
  pageSize: 50,
  tab: tabInProgress,
};

export const OrderStatusListLabel: { [key in OrderStatus]: string } = {
  PENDING: 'Pending',
  CONFIRMED: 'Confirmed',
  READY: 'Ready',
  SETTLED: 'Settled',
  CANCELED: 'Canceled',
};

export const orderStatusList = Object.keys(OrderStatusListLabel) as OrderStatus[];

export const orderStatusTagVariants: { [key in OrderStatus]: string } = {
  PENDING: 'yellow',
  CONFIRMED: 'blue',
  READY: 'blue',
  SETTLED: 'green',
  CANCELED: 'red',
};

export const ORDERS_TAB_ALL_STATUSES: { [key in OrderTabType]: OptionType<OrderStatus>[] } = {
  [tabInProgress]: [
    { label: OrderStatusListLabel.CONFIRMED, value: OrderStatus.CONFIRMED },
    { label: OrderStatusListLabel.READY, value: OrderStatus.READY },
    { label: OrderStatusListLabel.PENDING, value: OrderStatus.PENDING },
  ],
  [tabHistorical]: [
    { label: OrderStatusListLabel.CANCELED, value: OrderStatus.CANCELED },
    { label: OrderStatusListLabel.SETTLED, value: OrderStatus.SETTLED },
  ],
};
