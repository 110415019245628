import BigNumber from 'bignumber.js';
import { formatFiat } from 'utils/formatting';
import { Deliverable, Order } from 'utils/types';

interface DeliverableValueProps {
  cashValue?: boolean;
  deliverable?: Deliverable;
  order: Order;
  expectedTotalCash?: boolean;
}

const ZERO_UNITS = 0;

const DeliverableValue = ({
  cashValue,
  deliverable,
  order,
  expectedTotalCash,
}: DeliverableValueProps) => {
  let value = 0;
  if (deliverable && !deliverable.amount) {
    return <span>-</span>;
  }

  value = cashValue
    ? new BigNumber(deliverable?.totalInCurrency || ZERO_UNITS).toNumber()
    : new BigNumber(deliverable?.amount ?? ZERO_UNITS).toNumber();

  if (expectedTotalCash && order.deliveries) {
    value = order.deliveries?.expectedTotalCash;
  }

  let displayValue;

  if (cashValue) {
    const currency = order.deliveries?.expectedTotalCurrency ?? 'USD'; // Default to USD if no currency is provided

    if (currency) {
      displayValue = formatFiat(currency, value);
    }
  } else {
    displayValue = value; // If cashValue is false, just return the raw value
  }

  return <>{Boolean(value || value === 0) ? displayValue : '-'}</>;
};

export default DeliverableValue;
