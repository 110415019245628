import BigNumber from 'bignumber.js';
import { CustomBasketDeliverable } from 'utils/types/orders';

export const recalculateCustomBasketDeliverables = (
  customBasketDeliverables: CustomBasketDeliverable[],
  deliverable: CustomBasketDeliverable
): CustomBasketDeliverable[] => {
  const oldWeight = getOldWeight(customBasketDeliverables, deliverable);
  const lockedWeights = calculateLockedWeights(customBasketDeliverables);

  return customBasketDeliverables.map((customBasketDeliverable) => ({
    ...customBasketDeliverable,
    isLocked:
      customBasketDeliverable.ticker == deliverable.ticker
        ? deliverable.isLocked
        : customBasketDeliverable.isLocked,
    weight: calculateNewWeight(
      customBasketDeliverable,
      deliverable,
      lockedWeights,
      oldWeight
    ).toString(),
  }));
};

function calculateNewWeight(
  customBasketDeliverable: CustomBasketDeliverable,
  updatedDeliverable: CustomBasketDeliverable,
  lockedWeights: BigNumber,
  oldWeight: BigNumber
): BigNumber {
  const updatedDeliverableNewWeight = new BigNumber(updatedDeliverable.weight || 0);
  const currentWeight = new BigNumber(customBasketDeliverable.weight || 0);

  if (customBasketDeliverable.ticker == updatedDeliverable.ticker) {
    return updatedDeliverableNewWeight;
  }

  if (customBasketDeliverable.isLocked) {
    return currentWeight;
  }

  return new BigNumber(1)
    .minus(lockedWeights)
    .minus(updatedDeliverableNewWeight)
    .multipliedBy(
      new BigNumber(currentWeight || 0).dividedBy(
        new BigNumber(1).minus(lockedWeights).minus(oldWeight)
      )
    );
}

function getOldWeight(
  customBasketDeliverables: CustomBasketDeliverable[],
  deliverable: CustomBasketDeliverable
): BigNumber {
  return new BigNumber(
    customBasketDeliverables.find(
      (customBasketDeliverable) => customBasketDeliverable.ticker === deliverable.ticker
    )?.weight || 0
  );
}

function calculateLockedWeights(customBasketDeliverables: CustomBasketDeliverable[]): BigNumber {
  return customBasketDeliverables.reduce((totalLocked, customBasketDeliverable) => {
    if (customBasketDeliverable.isLocked) {
      totalLocked = totalLocked.plus(customBasketDeliverable.weight || 0);
    }
    return totalLocked;
  }, new BigNumber(0));
}
