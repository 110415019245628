import { TableHeaderColumn } from 'components/Table/Table.types';

export const ExternalContactsTableColumnsConfig: TableHeaderColumn[] = [
  { label: 'Name', propName: 'name', propType: 'string', sort: false, width: '30%' },
  { label: 'Email', propName: 'email', propType: 'string', sort: false, width: '30%' },
  {
    label: 'Distribution Lists',
    propName: 'distributionList',
    propType: 'string',
    sort: false,
    width: '30%',
    colSpan: 2,
  },
];
