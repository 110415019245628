import { ClickAwayListener, Popper } from '@mui/material';
import { Button } from 'components/Button/Button';
import {
  StyledButtonsWrapper,
  StyledFiltersContainer,
  StyledFiltersLabel,
  StyledInputWrapper,
} from 'components/Filters/Filters.styles';

import { MouseEventHandler, useState } from 'react';
import { ReactComponent as FilterIcon } from 'assets/filter.svg';

interface FiltersProps extends React.PropsWithChildren {
  onReset: MouseEventHandler<HTMLButtonElement>;
  onApply: MouseEventHandler<HTMLButtonElement>;
}

export default function Filters({ onReset, onApply, children }: FiltersProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handlePoperClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const closePopper = () => setAnchorEl(null);

  return (
    <div>
      <Button onClick={handlePoperClick} size="medium" square="true">
        <FilterIcon />
      </Button>
      <ClickAwayListener
        onClickAway={closePopper}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
          <StyledFiltersContainer>
            <StyledFiltersLabel>Filters</StyledFiltersLabel>
            <StyledInputWrapper>{children}</StyledInputWrapper>
            <StyledButtonsWrapper>
              <Button onClick={onReset} size="small" variant="secondary">
                Reset
              </Button>
              <Button
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  onApply(event);
                  closePopper();
                }}
                size="small"
                variant="primary"
              >
                Apply
              </Button>
            </StyledButtonsWrapper>
          </StyledFiltersContainer>
        </Popper>
      </ClickAwayListener>
    </div>
  );
}
