import { Button } from 'components/Button/Button';
import {
  StyledContentWrapper,
  StyledLoginContainer,
  StyledLoginFormWrapper,
  StyledLoginLogoWrapper,
} from 'pages/Login/Login.styles';
import { FC } from 'react';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { useNavigate } from 'react-router-dom';
import { publicRoutesUrls } from 'router/constants';
import { Card } from 'components/Card/Card';
import { Box, Typography } from '@mui/material';

const InvitationLinkErorrPage: FC<{ errorMessage: { title: string; content: string } }> = ({
  errorMessage,
}) => {
  const navigate = useNavigate();

  const Footer = (
    <Button variant="primary" fullWidth onClick={() => navigate(publicRoutesUrls.login)}>
      Back to Homepage
    </Button>
  );
  return (
    <StyledLoginContainer>
      <StyledContentWrapper>
        <StyledLoginLogoWrapper>
          <Logo width={192} height={96} />
        </StyledLoginLogoWrapper>
        <StyledLoginFormWrapper>
          <Card
            noPadding
            header={
              <Box padding={2}>
                <Typography variant="subheadingMedium">{errorMessage.title}</Typography>
              </Box>
            }
            body={
              <Box padding={2}>
                <Typography variant="bodyMedium">{errorMessage.content}</Typography>
              </Box>
            }
            footer={<Box padding={2}>{Footer}</Box>}
          ></Card>
        </StyledLoginFormWrapper>
      </StyledContentWrapper>
    </StyledLoginContainer>
  );
};

export default InvitationLinkErorrPage;
