import { OptionType, OptionValueType } from 'components/Select/Select.types';
import findSelected from 'components/Select/helpers/findSelected';
import { ReactComponent as InfoSvg } from 'assets/info.svg';
import Tooltip from 'components/Tooltip';
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
  OutlinedInput,
  Typography,
  Stack,
  FormLabel,
  Box,
} from '@mui/material';
import {
  MuiSingleSelectGridContainer,
  MuiSingleSelectValueWrapper,
  StyledGrid,
  StyledGridItem,
} from 'components/Select/Select.styles';
import { SelectProps } from 'components/Select';
import transformOptions from 'components/Select/helpers/transformOptions';

export function Dropdown<T extends OptionValueType>({
  disabled,
  errorMessage,
  helperText,
  label,
  onChange,
  options,
  value,
  className,
  placeholder,
  isPaginationSelect,
  size = 'small',
  'data-qa-id': dataQaId,
  'data-qa-options-id': dataQaOptionsId,
  backgroundColor = 'transparent',
}: SelectProps<T>) {
  const selectedValue = findSelected(value, options) as OptionType<T>;
  const items = transformOptions(options);

  const handleChange = (event: SelectChangeEvent<typeof selectedValue>) => {
    onChange && onChange(event.target.value as T);
  };

  const inputSizeProps = {
    small: { size: 'small', height: 32 },
    medium: { size: 'medium', height: 40 },
    large: { size: 'medium', height: 48 },
  }[size];
  return (
    <FormControl fullWidth variant="outlined" disabled={disabled} error={Boolean(errorMessage)}>
      <Stack spacing="4px">
        {label && <FormLabel>{label}</FormLabel>}
        <Select
          sx={{
            background: backgroundColor,
            '& .MuiOutlinedInput-notchedOutline': isPaginationSelect ? { border: 'none' } : {},
          }}
          data-qa-id={dataQaId}
          displayEmpty
          className={className}
          value={selectedValue ?? ''}
          onChange={handleChange}
          input={
            <OutlinedInput
              notched={false}
              style={{ height: `${inputSizeProps.height}px` }}
              size={size}
            />
          }
          renderValue={(selected) => {
            if (!selected || (Array.isArray(selected) && selected.length === 0)) {
              return <Typography variant="labelMedium">{placeholder ?? label}</Typography>;
            }
            return (
              <MuiSingleSelectValueWrapper spacing={1}>
                {selected.icon && selected.icon}
                {selected.label}
                <OptionTooltip title={selected.tooltip} />
              </MuiSingleSelectValueWrapper>
            );
          }}
        >
          {items.map((option, index) => (
            <MenuItem
              sx={{
                background: selectedValue?.label === option.label ? '#FFE4D6' : '', // @TODO useTheme
              }}
              key={index}
              value={String(option.value)}
              data-qa-options-id={dataQaOptionsId}
            >
              <MuiSingleSelectGridContainer container>
                {option.icon && <StyledGridItem item>{option.icon}</StyledGridItem>}
                <StyledGrid>
                  <StyledGridItem item>
                    {option.label}
                    <OptionTooltip title={option.tooltip} />
                  </StyledGridItem>
                </StyledGrid>
              </MuiSingleSelectGridContainer>
            </MenuItem>
          ))}
        </Select>
        {helperText && (
          <FormHelperText sx={{ whiteSpace: 'pre-line' }}>{helperText}</FormHelperText>
        )}
      </Stack>
    </FormControl>
  );
}

const OptionTooltip = ({ title }: { title: string | React.ReactNode | undefined }) => {
  if (!title) return null;
  return (
    <Box sx={{ height: 'inherit', width: '12px' }}>
      <Tooltip placement="top" title={title} bgColor="black" color="white">
        <InfoSvg fontSize={12} fill="black" style={{ alignItems: 'center', display: 'flex' }} />
      </Tooltip>
    </Box>
  );
};
