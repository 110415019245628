import { Button } from 'components/Button/Button';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import useAppDispatch from 'hooks/useAppDispatch';
import { User } from 'utils/types';
import { UserForm } from 'shared/Modals/Users/UpdateUser/UpdateUserModal';
import { createNotification } from 'store/notifications/actions';
import { isProductionEnvironment } from 'utils/env';
import { updateMeSchemaUrl } from 'components/Form/formSchemas';
import { updateUserReducer } from 'store/user/slice';
import { updateUserRequest } from 'utils/api/partners';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useMutation } from 'react-query';
import { userEditNotifications } from 'shared/Notifications/users.notifications';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import FormGridItem from 'components/Form/components/FormGridItem';
import { Box, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';

function userToUserForm(user: User): UserForm {
  return {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.contact.email,
  };
}

export const UserSettingsProfile = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuthenticatedUser();

  const updateUserMutation = useMutation({
    mutationFn: (userData: UserForm) => updateUserRequest('/users/me', userData),
    onSuccess: (res, userData) => {
      dispatch(updateUserReducer(res.data));
      dispatch(
        createNotification(
          userEditNotifications.success(`${userData.firstName} ${userData.lastName}`)
        )
      );
    },
    onError: (error: Error) => {
      dispatch(createNotification(userEditNotifications.error(error.message), error));
    },
  });

  const handleUpdate = (formValues: UserForm) => {
    const userData = { ...formValues, email: formValues.email.toLowerCase() };
    updateUserMutation.mutate(userData);
  };

  return (
    <div>
      {user && (
        <Card
          noPadding
          header={
            <Box padding={2}>
              <Typography variant="subheadingMedium">General Details</Typography>
            </Box>
          }
          body={
            <Box padding={1}>
              <Form
                schemaUrl={updateMeSchemaUrl}
                onSubmit={handleUpdate}
                initialValues={userToUserForm(user)}
              >
                <FormGridContainer>
                  <FormGridItem>
                    <Input label="Status" name="status" value={user.status} readOnly disabled />
                  </FormGridItem>
                  <FormGridItem>
                    <Input label="Role" name="role" value={user.role} readOnly disabled />
                  </FormGridItem>
                  <FormGridItem>
                    <Input
                      label="Company Name"
                      name="companyName"
                      value={user.organization.name}
                      readOnly
                      disabled
                    />
                  </FormGridItem>
                  <FormGridItem>
                    <Input
                      label="Company Type"
                      name="companyType"
                      value={user.organization.type}
                      readOnly
                      disabled
                    />
                  </FormGridItem>
                  {!isProductionEnvironment && (
                    <FormGridItem>
                      <Input label="ID" name="id" value={user._id} readOnly disabled />
                    </FormGridItem>
                  )}
                  <FormGridContainer>
                    {' '}
                    <FormGridItem>
                      <Input name="firstName" />
                    </FormGridItem>
                    <FormGridItem>
                      <Input name="lastName" />
                    </FormGridItem>
                    <FormGridItem>
                      <Input name="email" />
                    </FormGridItem>
                    <FormGridItem>
                      <Input name="phoneNumber" label="Phone Number" disabled />
                    </FormGridItem>
                    <FormGridItem marginTop={2} display={'flex'} justifyContent={'right'} md={12}>
                      <Button
                        type="submit"
                        variant="primary"
                        isLoading={updateUserMutation.isLoading}
                      >
                        Save Changes
                      </Button>
                    </FormGridItem>
                  </FormGridContainer>
                </FormGridContainer>
              </Form>
            </Box>
          }
        />
      )}
    </div>
  );
};
