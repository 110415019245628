import { Button } from 'components/Button/Button';
import {
  StyledRebalanceActionBarWrapper,
  StyledDownloadXSLButton,
  StyledButtonWrapper,
  StyledFilterWrapper,
} from 'pages/Rebalance/RebalancePage.styles';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { DateTime } from 'utils/datetime';
import { RebalanceWithDetails } from 'utils/types/rebalance';
import { REBALANCE_TAB_STATUS } from 'utils/constants/rebalance';
import ProductSelector from 'components/ProductSelector';
import { ETPType } from 'utils/types/product';
import SingleDatePicker from 'components/DatepickerSingleDate';
import { DateValue } from 'components/DatepickerSingleDate';
import { getUtcDateObject } from 'utils/date';
import { isNil } from 'lodash';
import {
  ActionButtonWithTooltipProps,
  ActionButtonWithTooltip,
} from 'components/Button/variants/ActionButtonWithTooltip';

interface RebalancesActionBarInterface {
  showDateFilter: Boolean;
  rebalance?: RebalanceWithDetails;
  date: string | null;
  tab?: string | null;
  xlsButton: ActionButtonWithTooltipProps;
  approveButton: ActionButtonWithTooltipProps | null;
  skipRebalanceButton?: ActionButtonWithTooltipProps;
  onDateChange: (date: DateValue) => void;
  onProductChange?: (product: string) => void;
  product?: string;
}

export const RebalancesActionBar = ({
  tab,
  xlsButton,
  approveButton,
  skipRebalanceButton,
  onDateChange,
  date,
  rebalance,
  showDateFilter,
  product,
  onProductChange,
}: RebalancesActionBarInterface) => {
  const inputDateFormat = 'dd/MM/yyyy';

  const isDetailsPage = isNil(tab);

  const maxDate =
    tab === REBALANCE_TAB_STATUS.HISTORIC ? new DateTime().subtract(1, 'days').toDate() : undefined;

  const minDate =
    tab === REBALANCE_TAB_STATUS.UPCOMING ? new DateTime().add(1, 'days').toDate() : undefined;
  return (
    <StyledRebalanceActionBarWrapper>
      <StyledFilterWrapper>
        {showDateFilter && tab !== REBALANCE_TAB_STATUS.UPCOMING && (
          <SingleDatePicker
            disableManualDateInput={tab === REBALANCE_TAB_STATUS.HISTORIC || isDetailsPage}
            date={getUtcDateObject(date)}
            minDate={minDate}
            maxDate={isDetailsPage ? new DateTime().toDate() : maxDate}
            inputDateFormat={inputDateFormat}
            disableClearButton
            onChange={onDateChange}
          />
        )}
        {onProductChange && (
          <ProductSelector
            clearable
            onChange={(product) => onProductChange(product ?? '')}
            value={product}
            productType={ETPType.INDEX}
          />
        )}
      </StyledFilterWrapper>
      <StyledButtonWrapper>
        <StyledDownloadXSLButton
          onClick={xlsButton.onClick}
          size="medium"
          variant="tertiary"
          disabled={xlsButton.disabled}
        >
          <span>{xlsButton.label}</span>
          <DownloadIcon />
        </StyledDownloadXSLButton>
        {rebalance?._actions.skip && skipRebalanceButton && (
          <Button
            onClick={skipRebalanceButton.onClick}
            size="medium"
            variant="secondary"
            disabled={skipRebalanceButton.disabled}
          >
            {skipRebalanceButton.label}
          </Button>
        )}
        <ActionButtonWithTooltip button={approveButton} />
      </StyledButtonWrapper>
    </StyledRebalanceActionBarWrapper>
  );
};
