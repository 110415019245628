/* eslint-disable complexity */
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentInstrument } from 'store/instruments/selectors';
import { Fragment, useMemo } from 'react';
import parseInstrumentToFormData from 'pages/Instruments/helpers/parseInstrumentToFormData';
import { getPartnerDetailsUrl } from 'pages/Instruments/components/Form/utils';
import { Row } from 'components/Grid';
import { StyledColumn } from './ReviewStep.styles';
import { Button } from 'components/Button/Button';
import {
  BENCHMARK_STEP,
  CONSTITUENT_STEP,
  EXCHANGE_STEP,
  EtpDetailsStepType,
  FACTSHEET_STEP,
  GENERAL_DETAILS_STEP,
  MARKET_STEP,
} from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import Table from 'components/Table';
import formatFieldName from './utils/formatFieldName';
import renderFieldValue from './utils/renderFieldValue';
import renderConstituentValue from './utils/renderConstituentValue';
import yesOrNo from 'utils/decorators/yesOrNo';
import renderLocalTickersValue from './utils/renderLocalTickersValue';
import { ReactComponent as PencilIcon } from 'assets/pencil-alt.svg';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { ReviewStepColumnsConfig } from './ReviewStepColumnsConfig';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import {
  MuiStyledRowTitle,
  MuiStyledTableHeader,
  MuiStyledTableRow,
} from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { IconButton, Stack, Typography } from '@mui/material';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';

export interface ReviewStepProps {
  goBack: () => void;
  goToStep: (step: EtpDetailsStepType) => void;
}

function ReviewStep({ goBack, goToStep }: ReviewStepProps) {
  const navigate = useNavigate();
  const params = useParams();
  const { instrument } = useCurrentInstrument();
  const instrumentFormsData = useMemo(() => parseInstrumentToFormData(instrument), [instrument]);
  const goToPartners = () => navigate(getPartnerDetailsUrl(params.id));

  return (
    <>
      <MuiStyledTableHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="General Details" />
          <IconButton onClick={() => goToStep(GENERAL_DETAILS_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </MuiStyledTableHeader>

      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean(
            Object.entries(instrumentFormsData[GENERAL_DETAILS_STEP] || {}).length
          )}
          noContentLabel="No data at the moment."
        >
          {Object.entries(instrumentFormsData[GENERAL_DETAILS_STEP] || {}).map(([key, value]) => (
            <MuiStyledTableRow data-qa-id={key} key={key}>
              <MuiStyledTableCell>{formatFieldName(key)}</MuiStyledTableCell>
              <MuiStyledTableCell data-qa-id={value}>
                {renderFieldValue(value, key, instrument?.ticker)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
          ))}
        </TableBodyWithStates>
      </Table>
      <MuiStyledTableHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Market Details" />
          <IconButton onClick={() => goToStep(MARKET_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </MuiStyledTableHeader>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean(Object.entries(instrumentFormsData[MARKET_STEP] || {}).length)}
          noContentLabel="No data at the moment."
        >
          {Object.entries(instrumentFormsData[MARKET_STEP] || {}).map(([key, value]) => (
            <MuiStyledTableRow data-qa-id={key} key={key}>
              <MuiStyledTableCell>{formatFieldName(key)}</MuiStyledTableCell>
              <MuiStyledTableCell data-qa-id={value}>{renderFieldValue(value)}</MuiStyledTableCell>
            </MuiStyledTableRow>
          ))}
        </TableBodyWithStates>
      </Table>
      {instrument?.type !== 'Single Asset' && (
        <>
          <MuiStyledTableHeader>
            <Stack alignItems="center" spacing={1} direction="row">
              <ProductConfigHeader title="Benchmark Details" />
              <IconButton onClick={() => goToStep(BENCHMARK_STEP)}>
                <PencilIcon />
              </IconButton>
            </Stack>
          </MuiStyledTableHeader>
          <Table>
            {Boolean(Object.entries(instrumentFormsData[BENCHMARK_STEP] || {}).length) && (
              <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
            )}
            <TableBodyWithStates
              hasContent={Boolean(Object.entries(instrumentFormsData[BENCHMARK_STEP] || {}).length)}
              noContentLabel="No data at the moment."
            >
              {Object.entries(instrumentFormsData[BENCHMARK_STEP] || {}).map(([key, value]) => (
                <MuiStyledTableRow data-qa-id={key} key={key}>
                  <MuiStyledTableCell>{formatFieldName(key)}</MuiStyledTableCell>
                  <MuiStyledTableCell data-qa-id={value}>
                    {renderFieldValue(value)}
                  </MuiStyledTableCell>
                </MuiStyledTableRow>
              ))}
            </TableBodyWithStates>
          </Table>
        </>
      )}
      <MuiStyledTableHeader>
        <Stack direction="row">
          <ProductConfigHeader
            title={
              (instrumentFormsData[CONSTITUENT_STEP] || []).length > 1
                ? 'Constituents'
                : 'Constituent'
            }
          />
          <IconButton onClick={() => goToStep(CONSTITUENT_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </MuiStyledTableHeader>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean((instrumentFormsData[CONSTITUENT_STEP] || []).length)}
          noContentLabel="No data at the moment."
        >
          {(instrumentFormsData[CONSTITUENT_STEP] || []).map((constituent) => (
            <MuiStyledTableRow data-qa-id={constituent.name} key={constituent.name}>
              <MuiStyledTableCell>{formatFieldName(constituent.ticker)}</MuiStyledTableCell>
              <MuiStyledTableCell>{renderConstituentValue(constituent)}</MuiStyledTableCell>
            </MuiStyledTableRow>
          ))}
        </TableBodyWithStates>
      </Table>
      <MuiStyledTableHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Exchanges" />
          <IconButton onClick={() => goToStep(EXCHANGE_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </MuiStyledTableHeader>
      <Table>
        <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        <TableBodyWithStates
          hasContent={Boolean((instrumentFormsData[EXCHANGE_STEP] || []).length)}
          noContentLabel="No data at the moment."
        >
          {(instrumentFormsData[EXCHANGE_STEP] || []).map((exchange) => (
            <Fragment key={exchange.name}>
              <MuiStyledRowTitle>
                <Typography variant="labelLargeStrong">{exchange.name}</Typography>
              </MuiStyledRowTitle>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Primary Exchange</MuiStyledTableCell>
                <MuiStyledTableCell>{yesOrNo(exchange.isPrimary).toUpperCase()}</MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Local Tickers</MuiStyledTableCell>
                <MuiStyledTableCell>
                  {renderLocalTickersValue(exchange.localTickers)}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            </Fragment>
          ))}
        </TableBodyWithStates>
      </Table>
      <MuiStyledTableHeader>
        <Stack alignItems="center" spacing={1} direction="row">
          <ProductConfigHeader title="Factsheet Details" />
          <IconButton onClick={() => goToStep(FACTSHEET_STEP)}>
            <PencilIcon />
          </IconButton>
        </Stack>
      </MuiStyledTableHeader>
      <Table>
        {Boolean(Object.entries(instrumentFormsData[FACTSHEET_STEP] || {}).length) && (
          <TableHeaderWithMultiSort columns={ReviewStepColumnsConfig} />
        )}
        <TableBodyWithStates
          hasContent={Boolean(Object.entries(instrumentFormsData[FACTSHEET_STEP] || {}).length)}
          noContentLabel="No data at the moment."
        >
          {Object.entries(instrumentFormsData[FACTSHEET_STEP] || {}).map(([key, value]) => (
            <MuiStyledTableRow data-qa-id={key} key={key}>
              <MuiStyledTableCell>{formatFieldName(key)}</MuiStyledTableCell>
              <MuiStyledTableCell data-qa-id={value}>{renderFieldValue(value)}</MuiStyledTableCell>
            </MuiStyledTableRow>
          ))}
        </TableBodyWithStates>
      </Table>
      <Row>
        <StyledColumn offset={8} cols={2}>
          <Button
            data-qa-id="backButton"
            fullWidth
            type="button"
            variant="secondary"
            onClick={goBack}
          >
            Back
          </Button>
        </StyledColumn>
        <StyledColumn cols={2}>
          <Button
            data-qa-id="nextButton"
            fullWidth
            type="button"
            variant="primary"
            onClick={goToPartners}
          >
            Next
          </Button>
        </StyledColumn>
      </Row>
    </>
  );
}

export default ReviewStep;
