import { Button } from 'components/Button/Button';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { OrdersModalData } from 'shared/Modals/Orders/OrdersModal';
import { useButtonsActions } from 'pages/Orders/Orders/hooks/useButtonsActions';
import { Card } from 'components/Card/Card';
import { Box, Typography } from '@mui/material';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

const OrderCancelConfirmationModal = ({ closeModal, data }: IModalWithData) => {
  const orderModalData = data?.data as OrdersModalData;
  const actions = useButtonsActions(orderModalData?.order, orderModalData?.refreshQueryKey);
  const confirmAction = actions.find((action) => action.status === orderModalData.targetStatus);

  const onConfirm = () => {
    confirmAction && confirmAction.callback();
    closeModal();
  };

  const Footer = (
    <>
      <Button variant="primary" fullWidth onClick={onConfirm}>
        Confirm
      </Button>
      <Button variant="secondary" fullWidth onClick={closeModal}>
        Cancel
      </Button>
    </>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        onClose={closeModal}
        noPadding
        header={<CardHeaderTitleWithLabel label={'Order'} title={'Cancel Order'} />}
        body={
          <Box padding={2}>
            <Typography variant="bodyMedium">
              Are you sure you want to cancel this order?
            </Typography>
          </Box>
        }
        footer={<Box padding={2}>{Footer}</Box>}
      />
    </CustomModal>
  );
};

export default OrderCancelConfirmationModal;
