import { StyledTableCellContentWithIcon } from 'shared/Tables/RegisterTable/RegisterTable.styles';
import PageTitle from 'components/PageTitle';
import { TOKENS_STATUS_LABEL } from 'pages/Tokens/Tokens.constants';
import { Token, ProductStatus } from 'utils/types/product';
import ProductIcon from 'components/ProductIcon';
import { Box } from '@mui/material';
import Tag from 'components/Tag/Tag';

export interface TokensPageHeaderProps {
  token?: Token | null;
  isNewProduct?: boolean;
}

export const TokensPageHeader = ({ token, isNewProduct }: TokensPageHeaderProps) => {
  return (
    <>
      {token ? (
        <StyledTableCellContentWithIcon>
          <ProductIcon
            className="product-icon"
            iconUrl={token?.fundIcon}
            ticker={token?.ticker ?? ''}
            size="40px"
          />
          <PageTitle>
            {token?.ticker}
            {' / '}
            {token?.name}
          </PageTitle>
          <Box marginLeft={1}>
            <Tag
              label={
                TOKENS_STATUS_LABEL[token.status.toUpperCase() as ProductStatus] ??
                TOKENS_STATUS_LABEL.PENDING
              }
              variant="default"
            />
          </Box>
        </StyledTableCellContentWithIcon>
      ) : (
        isNewProduct && (
          <StyledTableCellContentWithIcon>
            <PageTitle>Create Token</PageTitle>
          </StyledTableCellContentWithIcon>
        )
      )}
    </>
  );
};
