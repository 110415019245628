import { AxiosError, AxiosResponse } from 'axios';
import { axiosInstance } from 'utils/axiosOnyx';
import { CompanyCustodianWallet, CompanyOwnWallet } from 'utils/types/wallets';
import { errorHandler } from 'utils/apiUtils';
import { getQueryString, ParamsType } from 'hooks/useUrlParams';
import { PaginationWithDataResponse } from 'utils/types';

export const getWalletsForCompanyRequest = async (
  id: string,
  type: 'CUSTODIAN' | 'OWN',
  params?: ParamsType
): Promise<
  AxiosResponse<
    PaginationWithDataResponse<(CompanyCustodianWallet | CompanyOwnWallet)[]>,
    AxiosError
  >
> => {
  try {
    const queryString = getQueryString({ page: 1, pageSize: 9999, ...params });

    return await axiosInstance.get(`/companies/id=${id}/wallets/type=${type}?${queryString}`);
  } catch (err) {
    return errorHandler(err, 'Error while loading wallets');
  }
};

export const deleteWalletRequest = async (
  uriPath: string
): Promise<AxiosResponse<CompanyCustodianWallet | CompanyOwnWallet, AxiosError>> => {
  try {
    return await axiosInstance.delete(uriPath);
  } catch (err) {
    return errorHandler(err, 'Error while deleting Wallet');
  }
};

export const createCustodianWalletForCompanyRequest = async (
  companyId: string,
  wallet: CompanyCustodianWallet
): Promise<AxiosResponse<CompanyCustodianWallet, AxiosError>> => {
  try {
    return await axiosInstance.post(`/companies/id=${companyId}/wallets/type=CUSTODIAN`, wallet);
  } catch (err) {
    return errorHandler(err, 'Error while creating a wallet');
  }
};

export const editCustodianWalletForCompanyRequest = async (
  uriPath: string,
  wallet: CompanyCustodianWallet
): Promise<AxiosResponse<CompanyCustodianWallet, AxiosError>> => {
  try {
    return await axiosInstance.patch(uriPath, wallet);
  } catch (err) {
    return errorHandler(err, 'Error while updating Wallets');
  }
};

export const createOwnWalletForCompanyRequest = async (
  companyId: string,
  wallet: CompanyOwnWallet
): Promise<AxiosResponse<CompanyOwnWallet, AxiosError>> => {
  try {
    return await axiosInstance.post(`/companies/id=${companyId}/wallets/type=OWN`, wallet);
  } catch (err) {
    return errorHandler(err, 'Error while creating a wallet');
  }
};

export const editOwnWalletForCompanyRequest = async (
  uriPath: string,
  wallet: CompanyOwnWallet
): Promise<AxiosResponse<CompanyOwnWallet, AxiosError>> => {
  try {
    return await axiosInstance.patch(uriPath, wallet);
  } catch (err) {
    return errorHandler(err, 'Error while updating Wallet');
  }
};
