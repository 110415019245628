import { alpha } from '@mui/material/styles';
import { darkThemeTokens } from './themeTokens/darkTheme/darkThemeTokens';
import { lightThemeTokens } from './themeTokens/lightTheme/lightThemeTokens';
import { blue, cyan, gold, green, grey, neutral, orange, red, yellow } from './colors/baseColors';
import {
  action,
  cellRange,
  common,
  error,
  info,
  interactive,
  notificationsColorRange,
  primary,
  secondary,
  success,
  support,
  tertiary,
  warning,
} from './utils/paletteColors';
import type { ThemeTokens } from './themeTokens/types/ThemeTokensTypes';

const base = {
  grey,
  neutral,
  yellow,
  gold,
  orange,
  red,
  cyan,
  green,
  blue,
  primary,
  secondary,
  tertiary,
  info,
  interactive,
  action,
  success,
  warning,
  error,
  common,
  cellRange,
  notificationsColorRange,
};

// @TODO
// use the updated 'light' and 'dark' theme tokens
// remove fields from palette that are not needed, eg support & action (included in updated theme tokens)
// remove all base colors
// update background and text values
const themedPalette = (themeTokens: ThemeTokens, theme: 'light' | 'dark') => {
  const { static: staticTokens, interactive } = themeTokens;

  return {
    ...base,
    theme,
    customBackground: staticTokens.background,
    background: {
      paper: theme === 'light' ? staticTokens.background.default.weak : grey[800],
      default: theme === 'light' ? staticTokens.background.default.weak : grey[900],
      neutral: theme === 'light' ? grey[200] : alpha(grey[500], 0.12),
    },
    text: {
      ...staticTokens.text,
      primary: theme === 'light' ? grey[800] : staticTokens.background.inverse.weak,
      secondary: theme === 'light' ? grey[600] : grey[500],
      disabled: theme === 'light' ? grey[500] : grey[600],
    },
    icon: staticTokens.icon,
    support: {
      // mathias
      ...themeTokens.static.support,
      // base theme
      ...support,
    },
    fill: interactive.fill,
    overlay: interactive.overlay,
    utline: interactive.outline,
    action: {
      ...base.action,
      active: theme === 'light' ? grey[600] : grey[500],
    },
  };
};

export function palette(theme: 'light' | 'dark') {
  return theme === 'light'
    ? themedPalette(lightThemeTokens, 'light')
    : themedPalette(darkThemeTokens, 'dark');
}
