import { TableHeaderColumn } from 'components/Table/Table.types';

export const YourContactsTableColumnsconfig: TableHeaderColumn[] = [
  { label: 'Name', propName: 'name', propType: 'string', sort: false, width: '30%' },
  { label: 'Email', propName: 'email', propType: 'string', sort: false, width: '30%' },
  { label: 'Type', propName: 'type', propType: 'string', sort: false, width: '16%' },
  {
    label: 'Distribution Lists',
    propName: 'distributionList',
    propType: 'string',
    sort: false,
    width: '16%',
  },
];
