import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export function tooltip(theme: Theme) {
  const lightMode = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '4px 8px',
          maxWidth: 400,
          color: lightMode ? 'black' : 'white',
          alignItems: 'flex-start',
          gap: '8px',
        },
        arrow: {
          color: 'inherit',
        },
      },
    },
  };
}
