import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { MuiStyledActionsTableCell } from 'components/Table/table.styles';
import { Exchange } from 'utils/types';
import dateToString from 'utils/decorators/dateToString';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';

type ExchangeTableRowProps = {
  exchange: Exchange;
  editAction: () => void;
  deleteAction: () => void;
  showActionsCell: boolean;
};

const ExchangeTableRow = ({
  exchange,
  editAction,
  deleteAction,
  showActionsCell = true,
}: ExchangeTableRowProps) => {
  return (
    <MuiStyledTableRow data-qa-id={`${exchange.name}-row`} key={exchange._id} onClick={editAction}>
      <MuiStyledTableCell data-qa-id={`${exchange.name}-name`}>{exchange.name}</MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${exchange.name}-country`}>
        {exchange.countries.join(', ')}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${exchange.name}-updated`}>
        {exchange?.updatedAt ? dateToString(exchange.updatedAt) : '-'}
      </MuiStyledTableCell>
      <MuiStyledActionsTableCell data-qa-id="editIcons">
        {showActionsCell && (
          <ButtonDropdown id={exchange._id ?? exchange.name} className="actionsCell">
            {exchange._actions?.update && (
              <ButtonDropdownItem id="editExchange" label="Edit Exchange" onClick={editAction} />
            )}
            {exchange._actions?.delete && (
              <ButtonDropdownItem
                id="deleteExchange"
                label="Delete Exchange"
                onClick={deleteAction}
              />
            )}
          </ButtonDropdown>
        )}
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};

export default ExchangeTableRow;
