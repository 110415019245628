//components
import { Card } from 'components/Card/Card';

//styles
import { Button } from 'components/Button/Button';
import { CustomModal } from 'shared/Modals/Modal';
import { Box, Typography } from '@mui/material';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { MuiStyledFooterWrapper } from 'shared/CompositeComponents/2FASetupFlow/TwoFaSetup.styles';

interface UploadPCFModalProps {
  onCancel: () => void;
  onApprove: () => void;
}

function PCFApprovalConfirmationModal({ onApprove, onCancel }: UploadPCFModalProps) {
  return (
    <CustomModal open onCloseModal={onCancel}>
      <Card
        noPadding
        onClose={onCancel}
        header={
          <CardHeaderTitleWithLabel label={'PCF'} title={'Portfolio Composition File Approval'} />
        }
        body={
          <>
            <Box padding={2}>
              <Typography variant="bodyMedium">
                Are you sure you want to approve this PCF?
              </Typography>
            </Box>
          </>
        }
        footer={
          <Box padding={2}>
            <MuiStyledFooterWrapper>
              <Button variant="secondary" onClick={onCancel} fullWidth>
                Cancel
              </Button>
              <Button variant="primary" onClick={onApprove} fullWidth>
                Approve
              </Button>
            </MuiStyledFooterWrapper>
          </Box>
        }
      />
    </CustomModal>
  );
}

export default PCFApprovalConfirmationModal;
