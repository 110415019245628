import { Box, styled as MuiStyled } from '@mui/material';
import { TableCell } from 'components/Table';
import { Switch, Typography } from '@mui/material';

export const StyledMessageWithLink = MuiStyled(Box)`
  a {
    color: ${({ theme }) => theme.palette.blue[600]};
    text-decoration: underline;
  }
`;

export const StyledNotificationsWrapper = MuiStyled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TotalTableCell = MuiStyled(TableCell)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.light};
`;

export const IndexVsRealTimePriceSwitch = MuiStyled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.customPalette.static.support.strong.positive,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.customPalette.static.support.strong.positive,
  },
  '& .MuiSwitch-switchBase': {
    color: theme.customPalette.interactive.fill.primary.enabled,
    '&:hover': {
      '&+.MuiSwitch-track': {
        backgroundColor: theme.customPalette.interactive.fill.primary.hover,
      },
    },
    '&.Mui-checked': {
      '&:hover': {
        '&+.MuiSwitch-track': {
          backgroundColor: theme.customPalette.static.support.standard.positive,
        },
      },
    },
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: theme.customPalette.interactive.fill.primary.enabled,
  },
}));

export const StyledTooltipTypography = MuiStyled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
  color: theme.customPalette.static.text.inverse.strong,
}));
