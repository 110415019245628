import { MuiStyledTableRow } from 'components/Table/TableRow';
import { ProductCustodianWallet } from 'utils/types/wallets';
import { CompanySuggestionDto } from 'utils/types';
import { StyledActionsTableCell } from 'components/Table/table.styles';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';

type Props = {
  wallet: ProductCustodianWallet;
  custodianName: string;
  partners: CompanySuggestionDto[];
  editAction: () => void;
  deleteAction: () => void;
  activateAction: () => void;
};

export const TokenWalletsTableRow = ({
  wallet,
  partners,
  custodianName,
  editAction,
  deleteAction,
  activateAction,
}: Props) => {
  return (
    <MuiStyledTableRow
      key={wallet._id}
      onClick={() => {
        wallet?._actions?.update && editAction();
      }}
    >
      <MuiStyledTableCell>{wallet.description} </MuiStyledTableCell>
      <MuiStyledTableCell>{wallet.chain}</MuiStyledTableCell>
      <MuiStyledTableCell>{custodianName}</MuiStyledTableCell>
      <MuiStyledTableCell>
        {partners.find((partner) => partner._id === wallet.transactingCompany)?.name}
      </MuiStyledTableCell>
      <StyledActionsTableCell
        align="right"
        noMinWidth
        onClick={(event) => {
          event?.stopPropagation();
        }}
      >
        {wallet?._actions && Object.keys(wallet?._actions) && (
          <ButtonDropdown id={wallet._id ?? ''} className="actionsCell">
            {wallet?._actions?.activate && (
              <ButtonDropdownItem
                id="activateWallet"
                label="Activate wallet"
                onClick={activateAction}
              />
            )}
            {wallet?._actions?.delete && (
              <ButtonDropdownItem
                id="deleteWallet"
                label="Deactivate wallet"
                onClick={deleteAction}
              />
            )}
          </ButtonDropdown>
        )}
      </StyledActionsTableCell>
    </MuiStyledTableRow>
  );
};
