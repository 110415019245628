import { CustomBasketDeliverable, Order, OrderScope } from 'utils/types/orders';
import { ProductType } from 'utils/types/product';
import EmptyCustomBasketDeliverablesTable from './components/EmptyCustomBasketDeliverablesTable';
import CustomBasketDeliverablesTable from './components/CustomBasketDeliverablesTable';

export interface ActualDeliverablesProps {
  productType: ProductType;
  onChangeCustomBasketDeliverable?: (
    deliverables: CustomBasketDeliverable | CustomBasketDeliverable[]
  ) => void;
  order?: Order;
  customBasketDeliverables?: CustomBasketDeliverable[];
}

const CustomBasketDeliverables = ({
  order,
  onChangeCustomBasketDeliverable,
  productType,
  customBasketDeliverables,
}: ActualDeliverablesProps) => {
  if (
    !order?.deliveries?.expected ||
    order.deliveries.expected.length <= 1 ||
    order?.scope !== OrderScope.CUSTOM_BASKET
  ) {
    return null;
  }

  const showEmptyTable =
    order?.scope === OrderScope.CUSTOM_BASKET && productType === 'ETP' && !Boolean(order.product);

  return (
    <>
      {showEmptyTable && <EmptyCustomBasketDeliverablesTable order={order} />}
      {!showEmptyTable && (
        <CustomBasketDeliverablesTable
          deliverables={customBasketDeliverables}
          onChangeDeliverable={onChangeCustomBasketDeliverable}
          order={order}
        />
      )}
    </>
  );
};

export default CustomBasketDeliverables;
