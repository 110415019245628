import { Button } from 'components/Button/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { WalletModalData } from 'shared/Modals/Wallets/WalletsModal';
import { createNotification } from 'store/notifications/actions';
import { deleteWalletRequest } from 'utils/api/wallets';
import { useMutation } from 'react-query';
import { walletDeactivatedNotifications } from 'shared/Notifications/wallets.notifications';
import { MuiStyledFooterWrapper } from 'shared/CompositeComponents/2FASetupFlow/TwoFaSetup.styles';
import { Box, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

export const DeleteWalletModal = ({ onCloseModalAction, data, closeModal }: IModalWithData) => {
  const dispatch = useAppDispatch();

  const walletModalData = data.data as WalletModalData;
  const companyId = data.companyData?.id ?? '';
  const walletId = walletModalData.wallet?._id;

  const wallet = walletModalData.wallet!;

  const deleteUriPath =
    wallet._actions?.delete?.uri ??
    `companies/id=${companyId}/wallets/type=CUSTODIAN/id=${walletId}`;

  const deleteWalletMutation = useMutation({
    mutationFn: () => deleteWalletRequest(deleteUriPath),
    onSuccess: () => {
      dispatch(createNotification(walletDeactivatedNotifications.success(wallet.address)));
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (error: Error) => {
      dispatch(createNotification(walletDeactivatedNotifications.error(error.message), error));
    },
  });

  const handleDelete = () => {
    deleteWalletMutation.mutate();
  };

  const Footer = (
    <MuiStyledFooterWrapper>
      <Button
        data-qa-id="cancelButton"
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
      >
        Cancel
      </Button>
      <Button
        data-qa-id="deleteButton"
        isLoading={deleteWalletMutation.isLoading}
        variant="danger"
        fullWidth
        onClick={handleDelete}
        type="button"
      >
        Deactivate
      </Button>
    </MuiStyledFooterWrapper>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        header={<CardHeaderTitleWithLabel label="Wallets" title="Deactivate Wallet" />}
        body={
          <Box padding={2}>
            <Typography variant="bodyMedium">
              Are you sure you want to deactivate wallet {wallet.address}?
            </Typography>
          </Box>
        }
        footer={<Box padding={2}>{Footer}</Box>}
        onClose={closeModal}
      />
    </CustomModal>
  );
};
