import Table from 'components/Table';
import replace from 'lodash/replace';
import titelize from 'utils/decorators/titelize';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { NavOptionsTitles } from 'pages/Orders/Orders/types';
import { Order, User, ProductType, OrderFlow } from 'utils/types';
import { Box, TableBody } from '@mui/material';
import { capitalizeFirstLetter } from 'utils/formatting';
import { orderStatusTagVariants } from 'utils/constants/orders';
import { renderOrderValue } from 'shared/Tables/table.utils';
import { variantTypes } from 'components/Tag/Tag.types';
import Tag from 'components/Tag/Tag';
import ExplorerLink from 'components/ExplorerLink';
import TableHeader from 'components/Table/TableHeader';
import { Card } from 'components/Card/Card';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import TransactionSummary from './TransactionSummary';
import { ReactComponent as TransactionSummaryIcon } from 'assets/transaction-summary.svg';

export interface DealInformationProps {
  productType: ProductType;
  order?: Order;
  user: User | null;
  orderFlow: OrderFlow;
}

const ZERO_UNITS = 0;

export const dealInformationColumnsConfig = [
  { label: 'Deal Information', propName: 'dealInformation', propType: 'string', width: '50%' },
];

const DealInformation = ({ productType, order, user, orderFlow }: DealInformationProps) => {
  const showTransactionSummary = orderFlow === 'confirm';
  const showOrderSummary =
    order?.deliveryType === 'CASH' && (order?.status === 'READY' || order?.status === 'SETTLED');

  return (
    <>
      {productType == 'ETP' && (
        <Box
          style={{
            gridTemplateColumns: showTransactionSummary ? '1fr 1fr' : '1fr',
          }}
          display="grid"
          gap={3}
        >
          <Table noPadding={true}>
            <TableHeader columns={dealInformationColumnsConfig} />
            <TableBody>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Order Number</MuiStyledTableCell>
                <MuiStyledTableCell align="right">{order?.externalId || '-'}</MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow data-html2canvas-ignore>
                <MuiStyledTableCell>State</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.status ? (
                    <Tag
                      label={replace(titelize(order?.status), 'Ap ', 'AP ')}
                      variant={orderStatusTagVariants[order.status] as variantTypes}
                    />
                  ) : (
                    '-'
                  )}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Deal Date</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {renderOrderValue(Boolean(order?.dealDate), order?.dealDate)}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Settlement Date</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.settlement &&
                    renderOrderValue(Boolean(order?.settlement.date), order?.settlement.date)}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Order</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.type ? capitalizeFirstLetter(order?.type) : '-'}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Delivery Type</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.deliveryType ? order?.deliveryType?.replaceAll('_', '-') : '-'}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Order Type</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.pricingType ? NavOptionsTitles[order?.pricingType] : '-'}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Delivery Currency</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.deliveryCurrency || '-'}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Authorised Participant</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {renderOrderValue(
                    Boolean(order?.createdBy?.companyName),
                    order?.createdBy.onBehalfOf
                      ? order.createdBy.onBehalfOf?.companyName
                      : order?.createdBy?.companyName
                  )}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>User Email</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {renderOrderValue(
                    Boolean(order?.createdBy.email ? order.createdBy.email : user?.contact.email),
                    order?.createdBy.email ? order.createdBy?.email : user?.contact.email
                  )}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>AP Code</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {renderOrderValue(
                    Boolean(order?.authorizedParticipantCode),
                    order?.authorizedParticipantCode
                  )}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Number of Securities</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {renderOrderValue(Boolean(order), order?.numberOfSecurities || ZERO_UNITS)}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Number of Creation Units</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {renderOrderValue(Boolean(order), order?.numberOfUnits || ZERO_UNITS)}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Settlement Type</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.settlement && renderOrderValue(Boolean(order), order?.settlement.type)}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
              <MuiStyledTableRow>
                <MuiStyledTableCell>Standard Settlement Horizon</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.settlement &&
                    renderOrderValue(Boolean(order), `${order?.settlement.horizon}`)}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            </TableBody>
          </Table>
          {/* Transaction Summary */}
          {showTransactionSummary && (
            <Card
              sx={{ padding: '24px' }}
              header={
                <TableTitleWithActions
                  icon={<TransactionSummaryIcon />}
                  title="Transaction Summary"
                />
              }
              headerDivider={false}
              body={<TransactionSummary order={order} showOrderSummary={showOrderSummary} />}
              maxCardHeight={showOrderSummary ? `500px` : '210px'}
            />
          )}
        </Box>
      )}
      {productType === 'Token' && (
        <Table noPadding={true}>
          <TableHeader columns={dealInformationColumnsConfig} />
          <TableBody>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Order Number</MuiStyledTableCell>
              <MuiStyledTableCell align="right">{order?.externalId || '-'}</MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow data-html2canvas-ignore>
              <MuiStyledTableCell>State</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {order?.status ? (
                  <Tag
                    label={replace(titelize(order.status), 'Ap ', 'AP ')}
                    variant={orderStatusTagVariants[order.status] as variantTypes}
                  />
                ) : (
                  '-'
                )}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Deal Date</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(order?.dealDate), order?.dealDate)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Settlement Date</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {(order?.settlementDate &&
                  renderOrderValue(Boolean(order?.settlementDate), order?.settlementDate)) ||
                  '-'}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Mint/Burn</MuiStyledTableCell>
              <MuiStyledTableCell align="right">{order?.type || '-'}</MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Authorized Merchant</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(
                  Boolean(order?.createdBy?.companyName),
                  order?.createdBy.onBehalfOf
                    ? order.createdBy.onBehalfOf?.companyName
                    : order?.createdBy?.companyName
                )}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>User Email</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(
                  Boolean(order?.createdBy.email ? order.createdBy.email : user?.contact.email),
                  order?.createdBy.email ? order.createdBy?.email : user?.contact.email
                )}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Number of Tokens</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(order), order?.numberOfUnits || ZERO_UNITS)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Blockchain Transaction</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {order?.transactionHash && order?.product?.destinationChain ? (
                  <ExplorerLink
                    destinationChain={order.product.destinationChain}
                    data={order.transactionHash}
                    type="transaction"
                  />
                ) : (
                  '-'
                )}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default DealInformation;
