import { Button } from 'components/Button/Button';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import Select from 'components/Select';
import useAppDispatch from 'hooks/useAppDispatch';
import { Calendar } from 'utils/types/calendar';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { apiUrls } from 'utils/constants/apiUrls';
import { createAdminCaledarSchemaUrl } from 'components/Form/formSchemas';
import { createNotification } from 'store/notifications/actions';
import { useMutation } from 'react-query';
import {
  calendarCreatedNotification,
  calendarUpdatedNotification,
} from 'shared/Notifications/calendars.notifications';
import { createEntityApiCall, putEntityApiCall } from 'utils/api/crudActions';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { Box, Divider, Stack } from '@mui/material';

export const CalendarCreateEditModal = ({
  closeModal,
  data,
  onCloseModalAction,
}: IModalWithData) => {
  const dispatch = useAppDispatch();
  const calendar = data.data as Calendar;
  const { companyData } = data;
  const { isAdmin } = data;
  const isNewCalendar = data.type === MODAL_ACTIONS.CREATE;
  const initialValues = isNewCalendar ? {} : calendar;

  let uriPath = isAdmin
    ? `${apiUrls.admin}${apiUrls.calendars}`
    : `${apiUrls.companies}/id=${companyData?.id ?? ''}${apiUrls.calendars}`;

  const calendarCreateMutation = useMutation({
    mutationFn: (formValues: Calendar) => {
      return createEntityApiCall(
        formValues,
        uriPath,
        'Error has happened during Calendar creation!'
      );
    },
    onSuccess: (res) => {
      dispatch(createNotification(calendarCreatedNotification.success(`${res.data.name}`)));
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(calendarCreatedNotification.error(err.message), err));
    },
  });

  const calendarEditMutation = useMutation({
    mutationFn: (formValues: Calendar) => {
      return putEntityApiCall(
        formValues,
        `${uriPath}/id=${calendar._id}`,
        'Error while updating Calendar!'
      );
    },
    onSuccess: (res) => {
      dispatch(createNotification(calendarUpdatedNotification.success(`${res.data.name}`)));
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(calendarUpdatedNotification.error(err.message), err));
    },
  });

  const handleUpdate = (formValues: Calendar) => {
    isNewCalendar
      ? calendarCreateMutation.mutate(formValues)
      : calendar && calendarEditMutation.mutate(formValues);
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        data-qa-id="cancelButton"
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
      >
        Cancel
      </Button>
      <Button
        data-qa-id="saveChangesButton"
        fullWidth
        type="submit"
        isLoading={calendarCreateMutation.isLoading || calendarEditMutation.isLoading}
        variant="primary"
      >
        {isNewCalendar ? 'Create Calendar' : 'Save changes'}
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        header={
          <CardHeaderTitleWithLabel
            title={isNewCalendar ? 'New Calendar' : `Edit ${calendar.name} Calendar`}
            label={'Calendars'}
          />
        }
        body={
          <Form
            schemaUrl={createAdminCaledarSchemaUrl}
            onSubmit={handleUpdate}
            initialValues={initialValues}
          >
            <Stack padding={2} gap={1}>
              <Input size="medium" name="name" />
              <Select name="country" data-qa-id="country" data-qa-options-id="country" allCaps />
              <Select
                name="timezone"
                data-qa-id="timezone"
                data-qa-options-id="timezone"
                capitalize={false}
              />
              <Input size="medium" name="year" />
            </Stack>
            <Divider sx={{ width: '100%' }} />
            <Box padding={2}>{Footer}</Box>
          </Form>
        }
        onClose={closeModal}
      ></Card>
    </CustomModal>
  );
};
