import { TableCell, TableRow } from 'components/Table';
import { EditableTableCell } from './EditableTableCell/EditableTableCell';
import { RebalanceDetails, RebalanceWithDetails } from 'utils/types/rebalance';
import { escapeNaN, formatAsPercentage, getColorForNumber } from 'utils/formatting';
import { StyledColoredTableCell } from 'pages/Rebalance/RebalancePage.styles';
import { decimalToPercentage, percentageToDecimal } from 'utils/numbers';
import Tag from 'components/Tag/Tag';

interface RebalanceDetailsTableRowProps {
  rebalanceDetails: RebalanceDetails;
  rebalance?: RebalanceWithDetails;
  initialRebalanceDetails?: RebalanceDetails;
  updateRow: (rebDet: RebalanceDetails) => void;
}

const isEditable = (value?: string | number) => {
  if (Number(value) === 0 || value === 'NaN' || value === undefined) return true;

  return false;
};

const getRawFeeBps = (rebalanceDetails: RebalanceDetails) => {
  return rebalanceDetails.action === 'BUY'
    ? rebalanceDetails?.buyFeesBps
    : rebalanceDetails.sellFeesBps;
};

const getFeeBps = (rebalanceDetails: RebalanceDetails) => {
  const feeBPS =
    rebalanceDetails.action === 'BUY' ? rebalanceDetails?.buyFeesBps : rebalanceDetails.sellFeesBps;
  if (feeBPS) return `${feeBPS} bps`;
  return '-';
};

const getRebalanceDetailsWithUpdatedFees = (rebalanceDetails: RebalanceDetails, val: string) => {
  return rebalanceDetails.action === 'BUY'
    ? { ...rebalanceDetails, buyFeesBps: val }
    : { ...rebalanceDetails, sellFeesBps: val };
};

export const RebalanceDetailsTableRow = ({
  rebalanceDetails,
  rebalance,
  initialRebalanceDetails,
  updateRow,
}: RebalanceDetailsTableRowProps) => {
  return (
    <TableRow data-qa-id={`${rebalanceDetails.ticker}-row`}>
      <TableCell data-qa-id={`${rebalanceDetails.ticker}-ticker`}>
        {rebalanceDetails.ticker}
      </TableCell>
      <TableCell data-qa-id={`${rebalanceDetails.preBalance}-name`}>
        {escapeNaN(rebalanceDetails.preBalance)}
      </TableCell>
      <EditableTableCell
        data-qa-id={`${rebalanceDetails.price}-type`}
        value={rebalanceDetails.price}
        formatedValue={escapeNaN(rebalanceDetails.price)}
        updateValue={(val) => {
          updateRow({ ...rebalanceDetails, price: val as string });
        }}
        editable={isEditable(initialRebalanceDetails?.price ?? rebalanceDetails?.price)}
      />
      <TableCell data-qa-id={`${rebalanceDetails.preValue}-type`}>
        {escapeNaN(rebalanceDetails.preValue)}
      </TableCell>
      <TableCell data-qa-id={`${rebalanceDetails.preWeight}-type`}>
        {formatAsPercentage(rebalanceDetails.preWeight)}
      </TableCell>
      <EditableTableCell
        data-qa-id={`${rebalanceDetails.targetWeight}-type`}
        value={decimalToPercentage(rebalanceDetails.targetWeight)}
        updateValue={(val) =>
          updateRow({
            ...rebalanceDetails,
            targetWeight: percentageToDecimal(val as string),
          })
        }
        formatedValue={formatAsPercentage(rebalanceDetails.targetWeight)}
        editable={isEditable(
          initialRebalanceDetails?.targetWeight ?? rebalanceDetails?.targetWeight
        )}
      />

      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.preDifference}-type`}
        color={getColorForNumber(rebalanceDetails.preDifference)}
      >
        {formatAsPercentage(rebalanceDetails.preDifference)}
      </StyledColoredTableCell>
      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.tradeCash}-type`}
        color={getColorForNumber(rebalanceDetails.tradeCash)}
      >
        {escapeNaN(rebalanceDetails.tradeCash)}
      </StyledColoredTableCell>
      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.tradeCoin}-type`}
        color={getColorForNumber(rebalanceDetails.tradeCoin)}
      >
        {escapeNaN(rebalanceDetails.tradeCoin)}
      </StyledColoredTableCell>
      <TableCell data-qa-id={`${rebalanceDetails.postBalance}-type`}>
        {escapeNaN(rebalanceDetails.postBalance)}
      </TableCell>
      <TableCell data-qa-id={`${rebalanceDetails.postValue}-type`}>
        {escapeNaN(rebalanceDetails.postValue)}
      </TableCell>
      <TableCell data-qa-id={`${rebalanceDetails.postWeight}-type`}>
        {formatAsPercentage(rebalanceDetails.postWeight)}
      </TableCell>
      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.postDifference}-type`}
        color={getColorForNumber(rebalanceDetails.postDifference)}
      >
        {formatAsPercentage(rebalanceDetails.postDifference)}
      </StyledColoredTableCell>
      <TableCell data-qa-id={`${rebalanceDetails.action}-type`}>
        <Tag
          label={rebalanceDetails.action}
          variant={rebalanceDetails.action === 'SELL' ? 'red' : 'green'}
        />
      </TableCell>
      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.actualTrade}-type`}
        color={getColorForNumber(rebalanceDetails.actualTrade)}
      >
        {escapeNaN(rebalanceDetails.actualTrade)}
      </StyledColoredTableCell>
      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.actualTradeCash}-type`}
        color={getColorForNumber(rebalanceDetails.actualTradeCash)}
      >
        {escapeNaN(rebalanceDetails.actualTradeCash)}
      </StyledColoredTableCell>
      <TableCell data-qa-id={`${rebalanceDetails.actualBalance}-type`}>
        {escapeNaN(rebalanceDetails.actualBalance)}
      </TableCell>
      <TableCell data-qa-id={`${rebalanceDetails.actualPostValue}-type`}>
        {escapeNaN(rebalanceDetails.actualPostValue)}
      </TableCell>
      <TableCell data-qa-id={`${rebalanceDetails.actualPostWeight}-type`}>
        {formatAsPercentage(rebalanceDetails.actualPostWeight)}
      </TableCell>
      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.actualPostWeightDifference}-type`}
        color={getColorForNumber(rebalanceDetails.actualPostWeightDifference)}
      >
        {formatAsPercentage(rebalanceDetails.actualPostWeightDifference)}
      </StyledColoredTableCell>
      <TableCell data-qa-id={`rebalance-quoteCurrency`}>
        {rebalance?.product.quoteCurrency}
      </TableCell>
      <EditableTableCell
        data-qa-id="feeBPS"
        value={getRawFeeBps(rebalanceDetails) ?? 0}
        formatedValue={getFeeBps(rebalanceDetails)}
        updateValue={(val) => {
          updateRow(getRebalanceDetailsWithUpdatedFees(rebalanceDetails, val as string));
        }}
        editable
      />
    </TableRow>
  );
};
