import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import Table, { TableProps } from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { ParamsType } from 'hooks/useUrlParams';
import { WalletUnionType } from 'utils/types/wallets';
import { WalletsTableRow } from './WalletsTableRow';
import { getWalletsTableColumnsConfig } from './tableColumnsConfig';
import { ProductType } from 'utils/types/product';

interface WalletsTableProps {
  productType?: ProductType;
  tableProperties: TableProps;
  editAction?: (wallet: WalletUnionType) => void;
  deleteAction?: (wallet: WalletUnionType) => void;
  activateAction?: (wallet: WalletUnionType) => void;
  wallets: WalletUnionType[];
  populateProducts?: boolean;
  loadingData?: boolean;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
}

const WalletsTable = ({
  productType,
  tableProperties,
  editAction,
  deleteAction,
  activateAction,
  wallets,
  loadingData,
  populateProducts,
  setUrlParams,
  urlParams,
}: WalletsTableProps) => {
  const walletTableColumns = getWalletsTableColumnsConfig(productType, populateProducts);

  return (
    <Table {...tableProperties}>
      <TableHeaderWithMultiSort
        showActionsCell
        defaultSortedColumns={urlParams.sort ?? []}
        columns={walletTableColumns}
        onColumnSort={(columns) => {
          setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
        }}
      />
      <TableBodyWithStates
        loadingData={Boolean(loadingData)}
        hasContent={!isEmpty(wallets)}
        noContentLabel="No connected wallets at the moment."
      >
        {wallets?.map((wallet) => (
          <WalletsTableRow
            key={wallet._id}
            wallet={wallet}
            editAction={() => {
              if (editAction) editAction(wallet);
            }}
            deleteAction={() => {
              if (deleteAction) deleteAction(wallet);
            }}
            activateAction={() => {
              if (activateAction) activateAction(wallet);
            }}
            populateProductsRow={populateProducts}
          />
        ))}
      </TableBodyWithStates>
    </Table>
  );
};

export default WalletsTable;
