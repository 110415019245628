import { ReactComponent as TrashIcon } from 'assets/trash-alt.svg';
import { MuiStyledActionsTableCell } from 'components/Table/table.styles';
import { Contact, PartnerContact } from 'utils/types';
import { StyledIcon } from 'layouts/DashboardContainer/SideNavigationMenu.style';
import { ReactComponent as ChevronDown } from 'assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/chevron-up.svg';
import { useState } from 'react';
import { MuiStyledTableCellChevron } from 'shared/Tables/ContactsTable/ContactsTable.styles';
import { EditContactListTablePartnerRowDetails } from './EditContactListTablePartnerRowDetails';
import {
  MuiStyledTableCell,
  MuiStyledTableCheckboxCell,
  MuiStyledTableCollapseCell,
} from 'components/Table/TableCell';
import { Checkbox, CheckboxProps } from 'components/BooleanInputs';
import Collapse from '@mui/material/Collapse';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { TableRow as MuiTableRow } from '@mui/material';

interface EditContactListTablePartnerRowProps {
  partner: PartnerContact;
  deleteAction?: (data: { contact?: Contact; partner?: PartnerContact }) => void;
  checkboxProps?: CheckboxProps;
}

export const EditContactListTablePartnerRow = ({
  partner,
  deleteAction,
  checkboxProps,
}: EditContactListTablePartnerRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <MuiStyledTableRow isExpanded={isExpanded}>
        {checkboxProps && (
          <MuiStyledTableCheckboxCell data-qa-id={`${partner.name}-checkbox`}>
            <Checkbox {...checkboxProps} />
          </MuiStyledTableCheckboxCell>
        )}
        <MuiStyledTableCellChevron
          onClick={() => setIsExpanded((prevState) => !prevState)}
          data-qa-id={`${partner.name}-dropdown`}
        >
          <StyledIcon>{isExpanded ? <ChevronUp /> : <ChevronDown />}</StyledIcon>
        </MuiStyledTableCellChevron>
        <MuiStyledTableCell style={{ width: '12%' }}>{partner.name}</MuiStyledTableCell>
        <MuiStyledTableCell></MuiStyledTableCell>
        <MuiStyledTableCell>{partner.contacts.length}</MuiStyledTableCell>
        <MuiStyledActionsTableCell
          data-qa-id="editIcons"
          onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
            event.stopPropagation()
          }
        >
          <>
            {deleteAction && (
              <TrashIcon
                data-qa-id="trashIcon"
                onClick={(event) => {
                  deleteAction({ partner: partner });
                  event.stopPropagation();
                }}
                className="editIcons"
              />
            )}
          </>
        </MuiStyledActionsTableCell>
      </MuiStyledTableRow>
      <MuiTableRow>
        <MuiStyledTableCollapseCell colSpan={6}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <EditContactListTablePartnerRowDetails contacts={partner.contacts} isExpanded />
          </Collapse>
        </MuiStyledTableCollapseCell>
      </MuiTableRow>
    </>
  );
};
