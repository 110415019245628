import { OptionType, OptionValueType, OptionTypeWithGroup } from 'components/Select/Select.types';
import findSelected from 'components/Select/helpers/findSelected';
import { TextField, InputAdornment, Stack, FormLabel } from '@mui/material';
import { Autocomplete } from '@mui/material';
import {
  MuiSingleSelectGridContainer,
  StyledGrid,
  StyledGridItem,
} from 'components/Select/Select.styles';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import transformOptions from 'components/Select/helpers/transformOptions';
import { SelectProps } from 'components/Select/index';

export function SelectAutocomplete<T extends OptionValueType>({
  disabled,
  errorMessage,
  helperText,
  label,
  onChange,
  options,
  value,
  className,
  placeholder,
  size = 'small',
  'data-qa-id': dataQaId,
  'data-qa-options-id': dataQaOptionsId,
}: SelectProps<T>) {
  const selectedValue = findSelected(value, options) as OptionType<T>;

  const inputSizeProps = {
    small: { size: 'small', height: 32 },
    medium: { size: 'medium', height: 40 },
    large: { size: 'medium', height: 48 },
  }[size];

  return (
    <Stack spacing="4px">
      {label && <FormLabel>{label}</FormLabel>}
      <Autocomplete<OptionTypeWithGroup<T>>
        data-qa-id={dataQaId}
        className={className}
        options={transformOptions(options)}
        groupBy={(option) => option.group ?? ''}
        getOptionLabel={(option) => option.label}
        value={selectedValue ?? null}
        onChange={(_, newValue) => {
          onChange && onChange(newValue?.value ?? undefined);
        }}
        style={{ padding: 0 }}
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: '0px',
          },
        }}
        renderOption={(props, option) => (
          <li {...props}>
            <MuiSingleSelectGridContainer container data-qa-options-id={dataQaOptionsId}>
              {option.icon && <StyledGridItem item>{option.icon}</StyledGridItem>}
              <StyledGrid>{option.label}</StyledGrid>
              {selectedValue?.label === option.label && (
                <StyledGridItem item>
                  <CheckIcon />
                </StyledGridItem>
              )}
            </MuiSingleSelectGridContainer>
          </li>
        )}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            size={inputSizeProps.size as 'small' | 'medium'}
            placeholder={placeholder}
            helperText={errorMessage || helperText}
            error={Boolean(errorMessage)}
            sx={{
              '& .MuiOutlinedInput-root': {
                padding: '0',
              },
              '& .MuiInputAdornment-root': {
                marginRight: size === 'small' ? '0px' : '4px',
              },
            }}
            InputProps={{
              style: { height: `${inputSizeProps.height}px`, padding: '0 8px 0 8px' },
              ...params.InputProps,
              startAdornment: (
                <>
                  {selectedValue?.icon && (
                    <InputAdornment position="start">{selectedValue.icon}</InputAdornment>
                  )}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
}
