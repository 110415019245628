import { default as MuiCard } from '@mui/material/Card';
import { Box, CardContent, CardActions, Divider } from '@mui/material';
import { ReactNode } from 'react';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { OnCloseContainer } from './Card.styles';

interface CardProps extends React.PropsWithChildren {
  classes?: string;
  header?: ReactNode;
  headerDivider?: boolean;
  body?: ReactNode;
  footer?: ReactNode;
  maxCardHeight?: string;
  maxBodyHeight?: string;
  footerDivider?: boolean;
  onClose?: () => void;
  sx?: {
    [key: string]: any;
  };
  sxBody?: {
    [key: string]: any;
  };
  noPadding?: Boolean;
}

export const Card = ({
  classes,
  header,
  headerDivider = true, // useful for cards that wrap tables as the divider may not be needed
  body,
  footer,
  maxCardHeight,
  maxBodyHeight,
  footerDivider = true, // useful for cards that wrap tables as the divider may not be needed
  onClose,
  noPadding = false, // use true when Card requires header & footer (to accommodate the dividers)
  sx,
  sxBody,
}: CardProps) => {
  return (
    <MuiCard
      classes={classes}
      sx={{
        maxHeight: maxCardHeight,
        padding: !noPadding ? '24px' : '0',
        ...sx,
      }}
    >
      {onClose && (
        <OnCloseContainer onClick={onClose}>
          <CloseIcon />
        </OnCloseContainer>
      )}
      {header && (
        <>
          <Box minHeight={'40px'}>{header}</Box>
          {headerDivider && (
            <Divider
              sx={{
                width: '100%',
              }}
            />
          )}
        </>
      )}
      {body && <CardContent sx={{ maxHeight: maxBodyHeight, ...sxBody }}>{body}</CardContent>}
      {footer && (
        <>
          {footerDivider && (
            <Divider
              sx={{
                width: '100%',
              }}
            />
          )}
          <CardActions sx={{ padding: '0' }}>{footer}</CardActions>
        </>
      )}
    </MuiCard>
  );
};
