import { Deliverable, Order } from 'utils/types/orders';
import { ProductType } from 'utils/types/product';
import ExpectedSeedDeliverablesTable from './components/ExpectedSeedDeliverablesTable';
import ExpectedDeliverablesTable from './components/ExpectedDeliverablesTable';

export interface ExpectedDeliverablesProps {
  productType: ProductType;
  onChangeSeedDeliverable?: (deliverables: Deliverable) => void;
  order?: Order;
  seedDeliverables?: Deliverable[];
}

const ExpectedDeliverables = ({
  productType,
  onChangeSeedDeliverable: onChangeSeedDeliverable,
  order,
  seedDeliverables,
}: ExpectedDeliverablesProps) => {
  const isSeedOrder = Boolean(order) && !Boolean(order?._id) && !order?.hasPortfolioComposition;

  return (
    <>
      {productType === 'ETP' && isSeedOrder ? (
        <ExpectedSeedDeliverablesTable
          onChangeSeedDeliverable={onChangeSeedDeliverable}
          seedDeliverables={seedDeliverables}
        />
      ) : (
        <ExpectedDeliverablesTable productType={productType} order={order} />
      )}
    </>
  );
};

export default ExpectedDeliverables;
