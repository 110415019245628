import { Box, Typography, useTheme } from '@mui/material';
import { lightThemeTokens } from 'theme/themes';
interface CounterCardProps {
  number?: number;
  cardColor: 'success' | 'error' | 'default' | 'warning';
  label: string;
}

const CardColors: Record<'success' | 'error' | 'default' | 'warning', string> = {
  default: lightThemeTokens.sentiment.text.neutral,
  success: lightThemeTokens.sentiment.text.positive,
  error: lightThemeTokens.sentiment.text.negative,
  warning: lightThemeTokens.sentiment.text.notice,
};
const CardBorderColors: Record<'success' | 'error' | 'default' | 'warning', string> = {
  default: lightThemeTokens.support.neutral.standard,
  success: lightThemeTokens.support.positive.standard,
  error: lightThemeTokens.support.negative.standard,
  warning: lightThemeTokens.support.notice.standard,
};

const CardBackgroundColors: Record<'success' | 'error' | 'default' | 'warning', string> = {
  default: lightThemeTokens.support.neutral.weak,
  success: lightThemeTokens.support.positive.weak,
  error: lightThemeTokens.support.negative.weak,
  warning: lightThemeTokens.support.notice.weak,
};

export const CounterCard = ({ number, cardColor, label }: CounterCardProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 1,
        display: 'flex',
        color: 'red',
        height: 'inherit',
      }}
    >
      <Box
        sx={{
          background: cardColor ? CardBackgroundColors[cardColor] : '#f7f7f7',
          borderBottom: `1px solid ${CardBorderColors[cardColor]}`,
          display: 'flex',
          padding: theme.spacing(1, 1.5),
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
          borderRadius: 0.5,
          minWidth: '40px',
          minHeight: '36px',
        }}
      >
        <Typography
          variant="labelMediumStrong"
          color={cardColor ? CardColors[cardColor] : `#4d4d4d`}
          margin={'auto'}
          lineHeight={'20px'}
        >
          {number ?? 0}
        </Typography>
      </Box>
      <div>{label}</div>
    </Box>
  );
};
