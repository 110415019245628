import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { Link as MuiLink } from '@mui/material';
import { useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { LinkPalette } from 'theme/themeTokens/types/ThemeComponentTypes';

export interface LinkProps extends ComponentPropsWithoutRef<'a'> {
  text: string | ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  href?: string;
  variant: 'primary' | 'secondary';
  type?: 'inline' | 'block';
}

const linkStyles = (linkPallete: LinkPalette, type: 'inline' | 'block') => {
  return {
    color: linkPallete.color,
    ...(type === 'inline' && {
      padding: '2px 4px',
    }),
    ...(type === 'block' && {
      padding: '2px 0',
    }),
    '&:hover': {
      ...(type === 'block' && {
        borderBottom: `2px solid ${linkPallete.color}`,
      }),
      ...(type === 'inline' && {
        backgroundColor: linkPallete.hover.backgroundColor,
        borderRadius: 0.5,
      }),
    },
    'a:visited': {
      ...(type === 'inline' && {
        fontWeight: 500,
      }),
      ...(type === 'block' && {
        fontWeight: 400,
      }),
    },
  };
};

const disabledLinkStyles = (theme: Theme, type: 'inline' | 'block') => {
  return {
    color: theme.customPalette.static.text.default.weak,
    '&:hover': {
      cursor: 'not-allowed',
    },
    ...(type === 'inline' && {
      fontWeight: 500,
    }),
    ...(type === 'block' && {
      fontWeight: 400,
      borderBottom: 'none',
    }),
  };
};

const Link = ({ text, disabled, variant = 'primary', type = 'inline', ...props }: LinkProps) => {
  const theme = useTheme();
  const link = disabled
    ? disabledLinkStyles(theme, type)
    : linkStyles(theme.customComponents.linkPalette[variant], type);

  return (
    <MuiLink sx={{ ...link }} {...props}>
      {text}
    </MuiLink>
  );
};

export default Link;
