import { Button } from 'components/Button/Button';
import TextAreaComponent from 'components/TextArea';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { NavReviewItem } from 'utils/types/nav';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { createNotification } from 'store/notifications/actions';
import { postRebook } from 'utils/api/portfolio-composition';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { Card } from 'components/Card/Card';
import { Box } from '@mui/material';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

export interface RebookWithNoteIModal {
  portfolioId: string;
  navReviewItem: NavReviewItem;
  closeRebookModal: () => void;
  onCloseModalAction: () => void;
}

export const RebookWithNoteModal = ({
  portfolioId,
  navReviewItem,
  closeRebookModal,
  onCloseModalAction,
}: RebookWithNoteIModal) => {
  const [note, setNote] = useState('');
  const dispatch = useAppDispatch();

  const rebookMutation = useMutation({
    mutationFn: (navItem: NavReviewItem) => {
      return postRebook(portfolioId, navReviewItem._id, navItem, note);
    },
    onSuccess: () => {
      dispatch(
        createNotification({
          message: 'NAV is succesfully rebooked.',
          title: 'NAV rebooked',
          type: 'success',
        })
      );
      closeRebookModal();
      onCloseModalAction();
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(
        createNotification({
          message: error.message,
          title: 'NAV refresh error',
          type: 'error',
        })
      );
    },
  });

  const onSubmitHandler = () => {
    rebookMutation.mutate(navReviewItem);
  };

  return (
    <CustomModal open onCloseModal={closeRebookModal}>
      <Card
        noPadding
        header={<CardHeaderTitleWithLabel label={'  NAV Review'} title={'Approval Notes'} />}
        body={
          <Box padding={2}>
            <TextAreaComponent
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Enter notes (optional)"
              label={''}
            />
          </Box>
        }
        footer={
          <Box padding={2}>
            <MuiStyledModalFooterButtons>
              <Button
                variant="secondary"
                fullWidth
                onClick={closeRebookModal}
                type="button"
                data-qa-id="cancelButton"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                fullWidth
                onClick={onSubmitHandler}
                type="submit"
                data-qa-id="confirmButton"
                isLoading={rebookMutation.isLoading}
              >
                Submit
              </Button>
            </MuiStyledModalFooterButtons>
          </Box>
        }
      />
    </CustomModal>
  );
};
