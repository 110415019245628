import { FC } from 'react';
import Tooltip from 'components/Tooltip';
import { Column } from 'components/Grid';
import { Button } from 'components/Button/Button';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import copyToClipboard from 'utils/copyToClipboard';
import { StyledInfoIcon } from 'pages/Login/Login.styles';
import {
  StyledTextWithTooltip,
  StyledQRCodeWrapper,
  StyledTextWithTopBorder,
  StyledQRTextWrapper,
} from 'shared/CompositeComponents/2FASetupFlow/TwoFaSetup.styles';
import { Request2FAResponse } from 'utils/types/auth';
import QRCode from 'qrcode.react';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Box, Stack, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

interface SetupQRCodeInterface {
  twoFARequest: Request2FAResponse;
  handleSkip: () => void;
  handleNext: () => void;
}

export const SetupQRCode: FC<SetupQRCodeInterface> = ({ twoFARequest, handleSkip, handleNext }) => {
  return (
    <Card
      noPadding
      header={<CardHeaderTitleWithLabel title="Scan QR code" label="2FA Setup" />}
      body={
        <Stack padding={2} gap={1}>
          <StyledTextWithTooltip>
            Launch your OTP authenticator app of preference, and then scan barcode.
            <Tooltip
              title={'Launch your OTP authenticator app of preference, and then scan barcode.'}
              placement="top"
              bgColor="black"
            >
              <StyledInfoIcon />
            </Tooltip>
          </StyledTextWithTooltip>
          <StyledQRCodeWrapper>
            <QRCode size={124} value={twoFARequest.otpSetupCodeUrl} fgColor={'black'} />
          </StyledQRCodeWrapper>
          <Column>
            <StyledTextWithTopBorder>
              Or enter the below key into your authenticator app
            </StyledTextWithTopBorder>
          </Column>
          <Column>
            <StyledQRTextWrapper>
              <Typography variant="bodyMedium">{twoFARequest.otpSetupCode}</Typography>
              <CopyIcon onClick={() => copyToClipboard(twoFARequest.otpSetupCode)} />
            </StyledQRTextWrapper>
          </Column>
        </Stack>
      }
      footer={
        <Box padding={2}>
          <MuiStyledModalFooterButtons>
            <Button variant="inverse" fullWidth type="submit" onClick={handleSkip} disabled>
              Skip for now
            </Button>
            <Button onClick={handleNext} variant="primary" fullWidth>
              <>
                <span>Continue</span>
              </>
            </Button>
          </MuiStyledModalFooterButtons>
        </Box>
      }
    />
  );
};
