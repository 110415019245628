import { Button } from 'components/Button/Button';
import {
  StyledContentWrapper,
  StyledLoginContainer,
  StyledLoginLogoWrapper,
  StyledNeedHelp,
} from 'pages/Login/Login.styles';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { Box, Stack, Typography } from '@mui/material';
import { contactOnyxSupport } from 'utils/externalLinks';
import { useNavigate } from 'react-router-dom';
import { Card } from 'components/Card/Card';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <StyledLoginContainer>
      <StyledContentWrapper>
        <StyledLoginLogoWrapper>
          <Logo width={192} height={96} />
        </StyledLoginLogoWrapper>
        <Card
          noPadding
          header={
            <Box padding={2}>
              <Typography variant="subheadingMedium">Page not found</Typography>
            </Box>
          }
          body={
            <Box padding={2}>
              <Typography variant="bodyMedium">Requested page does not exist.</Typography>
              <Stack></Stack>
            </Box>
          }
          footer={
            <Box padding={2}>
              <Button variant="primary" type="submit" onClick={() => navigate(-1)}>
                Go back
              </Button>
            </Box>
          }
        />
        <StyledNeedHelp cols={12}>
          Need Help?{' '}
          <a href={contactOnyxSupport} target="_blank" rel="noreferrer">
            Contact Onyx support.
          </a>
        </StyledNeedHelp>
      </StyledContentWrapper>
    </StyledLoginContainer>
  );
};

export default NotFoundPage;
