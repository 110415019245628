import React from 'react';
import { Box } from '@mui/material';
import { Order, OrderStatus } from 'utils/types/orders';
import { isFuture } from 'utils/date';
import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { getCurrentOrderQueryKey } from 'utils/constants/reactQueries';
import useAppModal from 'hooks/useAppModal';
import { useButtonsActions } from 'pages/Orders/Orders/hooks/useButtonsActions';
import Button from 'components/Button';

interface OrderActionsProps {
  productType: string;
  order?: Order;
}

const checkIfOrderCanBeCanceled = (orderStatus?: OrderStatus | '') => {
  return (
    orderStatus === OrderStatus.PENDING ||
    orderStatus === OrderStatus.CONFIRMED ||
    orderStatus === OrderStatus.READY
  );
};

export const OrderActions: React.FC<OrderActionsProps> = ({ productType, order }) => {
  const openModal = useAppModal();
  const showOrderActions = checkIfOrderCanBeCanceled(order?.status ?? '');
  const orderActions = useButtonsActions(order || ({} as Order));

  return showOrderActions ? (
    <Box display="flex" justifyContent={'space-between'}>
      <Box display="flex" gap={1}>
        {orderActions.map(
          (action, index) =>
            action.permission && (
              <Button
                disabled={
                  productType !== 'Token' &&
                  action.status === OrderStatus.SETTLED &&
                  isFuture(order?.settlement?.date!)
                }
                onClick={() => {
                  if (order) {
                    openModal({
                      modalName: action.modal,
                      modalData: {
                        data: {
                          targetStatus: action.status,
                          order,
                          refreshQueryKey: getCurrentOrderQueryKey,
                        },
                        type: MODAL_ACTIONS.CUSTOM,
                        custom: {
                          isToken: productType === 'Token',
                        },
                      },
                    });
                  }
                }}
                size="medium"
                variant={action.variant}
                type="button"
                key={index}
              >
                {action.label}
              </Button>
            )
        )}
      </Box>
    </Box>
  ) : null;
};
