import { CustodianAccount } from 'utils/types';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { CustodianStatus } from 'utils/constants/custodians';
import { MuiStyledActionsTableCell } from 'components/Table/table.styles';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';

export type CustodianTableRowProps = {
  custodian: CustodianAccount;
  editAction: () => void;
  deleteAction: () => void;
  activateAction: () => void;
};

export const CustodianTableRow = ({
  custodian,
  editAction,
  deleteAction,
  activateAction,
}: CustodianTableRowProps) => {
  return (
    <MuiStyledTableRow
      key={custodian.name}
      onClick={() => {
        custodian?.status !== CustodianStatus.DEACTIVATED && editAction();
      }}
    >
      <MuiStyledTableCell>{custodian.provider}</MuiStyledTableCell>
      <MuiStyledTableCell>{custodian.name}</MuiStyledTableCell>
      <MuiStyledTableCell>{custodian.description}</MuiStyledTableCell>
      <MuiStyledActionsTableCell
        align="right"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        <ButtonDropdown id={custodian._id} className="actionsCell">
          {custodian?.status !== CustodianStatus.ACTIVE && (
            <ButtonDropdownItem
              id="resendInvitation"
              label="Activate account"
              onClick={activateAction}
            />
          )}
          {custodian?.status !== CustodianStatus.DEACTIVATED && (
            <ButtonDropdownItem
              id="deleteUser"
              label=" Deactivate account"
              onClick={deleteAction}
            />
          )}
        </ButtonDropdown>
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};
