import { JSXElementConstructor, ReactElement } from 'react';
import { Box, Chip } from '@mui/material';
import { ReactComponent as Close } from 'assets/close.svg';
import { variantTypes } from 'components/Tag/Tag.types';
import { ReactComponent as ArrowDown } from 'assets/chevron-down.svg';

interface TagProps {
  disabled?: boolean;
  closeIcon?: boolean;
  expandable?: boolean;
  label: string;
  variant?: variantTypes;
  onClose?: () => void;
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  sx?: {
    [key: string]: any;
  };
  icon?: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
  fullWidth?: Boolean;
  isExpanded?: Boolean;
}

const Tag = ({
  variant = 'default',
  disabled = false,
  closeIcon = false,
  expandable = false,
  label = '',
  onClose = () => {},
  fullWidth,
  isExpanded,
  icon,
  onClick,
  sx,
  ...props
}: TagProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-block',
        width: fullWidth ? '100%' : 'auto',
        ...sx,
      }}
      onClick={(event) => onClick && onClick(event)}
    >
      <Chip
        tabIndex={0}
        sx={{
          cursor: expandable ? 'pointer' : 'default',
          width: 'inherit',
          '& > svg': {
            ...(expandable && isExpanded ? { transform: 'rotate(180deg)' } : {}),
          },
        }}
        label={label}
        onDelete={closeIcon && !disabled ? onClose : undefined}
        deleteIcon={
          closeIcon && !disabled ? (
            <Box
              sx={{
                display: 'flex',
                width: '12px',
                height: '12px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Close height={8} width={8} />
            </Box>
          ) : undefined
        }
        icon={
          expandable ? (
            <ArrowDown style={{ height: '14px', marginTop: '2px', marginInline: '0' }} />
          ) : (
            icon || undefined
          )
        }
        disabled={disabled}
        variant={variant}
        {...props}
      />
    </Box>
  );
};

export default Tag;
