import Table from 'components/Table/index';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { ETPColumnsConfig, TokensColumnsConfig } from './tableColumnsConfig';
import { ProductsTableRow } from 'shared/Tables/ProductsTable/ProductsTableRow';
import { Instrument, Token, PaginationProps, ProductType } from 'utils/types';
import { ParamsType } from 'hooks/useUrlParams';
import { Card } from 'components/Card/Card';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import Pagination from 'components/Pagination';

interface ProductsTableProps {
  productType: ProductType;
  title?: string;
  activateAction?: (product: Instrument | Token, createSeedOrder?: Boolean) => void;
  archiveAction?: (product: Instrument | Token) => void;
  deleteAction?: (product: Instrument | Token) => void;
  editAction?: (product: Instrument | Token) => void;
  reactivateAction?: (product: Instrument | Token) => void;
  productList?: (Instrument | Token)[];
  loading: boolean;
  pagination: PaginationProps;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
}

export const ProductsTable = ({
  productType,
  title,
  activateAction,
  archiveAction,
  deleteAction,
  editAction,
  productList,
  loading,
  pagination,
  setUrlParams,
  urlParams,
}: ProductsTableProps) => {
  const isETPTable = productType === 'ETP';
  const columsConfig = isETPTable ? ETPColumnsConfig : TokensColumnsConfig;
  const hasContent = !isEmpty(productList);
  const noContentLabel = `No ${isETPTable ? 'ETPs' : 'Tokens'} at the moment.`;

  return (
    <Card
      header={title && <TableTitleWithActions title={title} />}
      headerDivider={false}
      body={
        <Table noPadding>
          <TableHeaderWithMultiSort
            showActionsCell
            defaultSortedColumns={urlParams.sort ?? []}
            columns={columsConfig}
            onColumnSort={(columns) => {
              setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
            }}
          />
          <TableBodyWithStates
            loadingData={Boolean(loading)}
            hasContent={hasContent}
            noContentLabel={noContentLabel}
          >
            {productList?.map((product, index) => (
              <ProductsTableRow
                productType={productType}
                key={product._id + index}
                product={product}
                editAction={() => {
                  editAction && editAction(product);
                }}
                deleteAction={() => {
                  deleteAction && deleteAction(product);
                }}
                archiveAction={() => {
                  archiveAction && archiveAction(product);
                }}
                activateAction={(createSeedOrder) => {
                  activateAction && activateAction(product, createSeedOrder);
                }}
              />
            ))}
          </TableBodyWithStates>
        </Table>
      }
      footer={<Pagination {...pagination} />}
    ></Card>
  );
};
