import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import Table, { TableProps } from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { ParamsType } from 'hooks/useUrlParams';
import { RegisterTableRow } from 'shared/Tables/RegisterTable/RegisterTableRow';
import { SecuritiesRegister } from 'pages/Orders/Register/types';
import { RegisterTableColumnsConfig } from './tableColumnsConfig';

interface RegisterTableProps {
  registers: SecuritiesRegister[];
  editAction: (register: SecuritiesRegister) => void;
  loadingData: boolean;
  tableProperties: TableProps;
  shouldDisplayActions?: boolean;
  urlParams: ParamsType;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  onDonwloadPdf: (singleRegister: SecuritiesRegister) => void;
  onDonwloadXLS: (singleRegister: SecuritiesRegister) => void;
}

export const RegisterTable = ({
  registers,
  editAction,
  loadingData,
  tableProperties,
  setUrlParams,
  urlParams,
  onDonwloadPdf,
  onDonwloadXLS,
}: RegisterTableProps) => {
  return (
    <Table {...tableProperties}>
      <TableHeaderWithMultiSort
        showActionsCell={true}
        defaultSortedColumns={urlParams.sort ?? []}
        columns={RegisterTableColumnsConfig}
        columnAlignment={{
          amount: 'right',
          totalUnitsOutstanding: 'right',
          downloadPDF: 'right',
          downloadXLS: 'right',
        }}
        onColumnSort={(columns) => {
          setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
        }}
      />
      <TableBodyWithStates
        loadingData={Boolean(loadingData)}
        hasContent={!isEmpty(registers)}
        noContentLabel="No register data at the moment."
      >
        {registers?.map((register, index) => {
          return (
            <RegisterTableRow
              key={index + register._id}
              register={register}
              editAction={() => editAction(register)}
              tableColumns={RegisterTableColumnsConfig}
              onDonwloadPdf={onDonwloadPdf}
              onDonwloadXLS={onDonwloadXLS}
            />
          );
        })}
      </TableBodyWithStates>
    </Table>
  );
};
