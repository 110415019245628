import { ReactComponent as ChevronLeft } from 'assets/chevron-left.svg';
import { Button } from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { MINUS_ONE } from 'utils/constants/numbers';

export const BackButton = ({ ...props }) => {
  const navigate = useNavigate();

  return (
    <Button variant="secondary" emphasis="medium" onClick={() => navigate(MINUS_ONE)} {...props}>
      <ChevronLeft style={{ fill: '#fff' }} />
    </Button>
  );
};
