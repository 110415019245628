/* eslint-disable complexity */
import { Button } from 'components/Button/Button';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import Select from 'components/Select';
import SingleDatePicker from 'components/DatepickerSingleDate';
import TextArea from 'components/TextArea';
import isEmpty from 'lodash/isEmpty';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppModal from 'hooks/useAppModal';
import { DefaultValues } from 'react-hook-form';
import { PARTNER_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { Partner, PartnerSchemaPayload, PartnerType } from 'utils/types/partner';
import { PartnerTypeTitles } from 'utils/constants/partners';
import { SaveProgress } from 'pages/Instruments/components/SaveProgress';
import { companiesSchemaUrl, companySchemaUrl } from 'components/Form/formSchemas';
import { compileUrl } from 'utils/url';
import { createNotification } from 'store/notifications/actions';
import {
  getCompaniesQuery,
  getCompanyQuery,
  getCompanySuggestionQuery,
} from 'utils/constants/reactQueries';
import { privateRoutesUrls } from 'router/constants';
import { queryClient } from 'utils/api/queries/queryClient';
import { requestPatchCompany, requestPostCompany } from 'utils/api/partners';
import { useAddressFilters } from 'pages/Partners/hooks/useAddressFilters';
import { useCallback, useEffect, useState } from 'react';
import { useCitiesOptionsQuery, useCountriesOptionsQuery } from 'utils/api/hooks/useLocation.hook';
import { useCompanyQuery } from 'pages/Partners/hooks/useCompanyQuery';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  partnerCreatedNotification,
  partnerUpdatedNotification,
} from 'shared/Notifications/partners.notifications';
import { DateTime } from 'utils/datetime';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import FormGridItem from 'components/Form/components/FormGridItem';

export const PartnerInformation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: partnerId } = useParams();
  const openModal = useAppModal();
  const [isFormDataDirty, setIsFormDataDirty] = useState(false);
  const [partnerType, setPartnerType] = useState<PartnerType>();
  const { data: partnerData } = useCompanyQuery(partnerId ?? '');
  const isNewPartner = isEmpty(partnerData);
  const schemaUrl = isNewPartner ? companySchemaUrl : companiesSchemaUrl;
  const submitText = isNewPartner ? 'Next' : 'Save';

  const [selectedCountry, setSelectedCountry] = useState<number | undefined>(
    partnerData?.country?.id
  );
  const countriesOptions = useCountriesOptionsQuery();
  const citiesOptions = useCitiesOptionsQuery(selectedCountry);

  const { filteredCountriesOptions, filteredCitiesOptions, setCountriesFilter, setCitiesFilter } =
    useAddressFilters(countriesOptions?.data, citiesOptions?.data);

  const updatePartnerMutation = useMutation({
    mutationFn: isNewPartner ? requestPostCompany : requestPatchCompany,
    onSuccess: (data) => {
      const partnerData = data.data;
      dispatch(
        createNotification(
          isNewPartner ? partnerCreatedNotification.success() : partnerUpdatedNotification.success()
        )
      );
      queryClient.invalidateQueries([
        getCompanyQuery,
        getCompaniesQuery,
        getCompanySuggestionQuery,
      ]);
      if (isNewPartner)
        navigate(
          compileUrl(privateRoutesUrls.dashboardRoutes.partnersEdit, {
            label: 'id',
            value: String(partnerData._id),
          }),
          { replace: true }
        );
    },
    onError: (error: Error) => {
      dispatch(
        createNotification(
          isNewPartner
            ? partnerCreatedNotification.error(error.message)
            : partnerUpdatedNotification.error(error.message),
          error
        )
      );
    },
  });

  const handleOnSubmit = useCallback(
    (data: Partner) => {
      const apiPayload = isNewPartner ? { ...data } : { ...data, _id: partnerId };
      updatePartnerMutation.mutate(apiPayload);
    },
    [isNewPartner, partnerId, updatePartnerMutation]
  );

  const handleOnChange = ({ type }: DefaultValues<Partner>) => {
    setIsFormDataDirty(true);
    setPartnerType(type);
  };

  useEffect(() => {
    if (updatePartnerMutation.isSuccess) {
      setIsFormDataDirty(false);
    }
  }, [updatePartnerMutation.isSuccess]);

  const partnerDataTypeOrPartnerType = partnerData?.type || partnerType;
  return (
    <div>
      {((partnerId && partnerData) || !partnerId) && (
        <Form<PartnerSchemaPayload>
          initialValues={
            {
              legalAgreementStartDate: new DateTime().toDateString(),
              ...partnerData,
              countryId: partnerData?.country?.id,
              cityId: partnerData?.city?.id,
            } ?? {}
          }
          onSubmit={handleOnSubmit}
          schemaUrl={schemaUrl}
          onChange={handleOnChange}
        >
          <FormGridContainer>
            <FormGridItem md={12}>
              <ProductConfigHeader title="Partner Details" />
            </FormGridItem>
            {isNewPartner ? (
              <FormGridItem>
                <Select
                  name="type"
                  helperText="Partner type cannot be changed once the company has been created"
                  data-qa-id="partnerTypeSelect"
                  data-qa-options-id="partnerTypeSelect"
                  mapOptions={(options) =>
                    options.map((option) => ({
                      label: PartnerTypeTitles[option.value as keyof typeof PartnerType],
                      value: option.value,
                    }))
                  }
                  capitalize={false}
                />
              </FormGridItem>
            ) : (
              <FormGridItem>
                <Input
                  disabled
                  name="companyType"
                  value={PartnerTypeTitles[partnerData?.type ?? ''] ?? ''}
                  label="Company Type"
                />
              </FormGridItem>
            )}
            {partnerDataTypeOrPartnerType === PartnerType.AUTHORIZED_PARTICIPANT && (
              <FormGridItem>
                <Input name="bpId" />
              </FormGridItem>
            )}
            {partnerDataTypeOrPartnerType === PartnerType.AUTHORIZED_MERCHANT && (
              <FormGridItem>
                <Input name="name" />
              </FormGridItem>
            )}
            {partnerDataTypeOrPartnerType === PartnerType.TECHNICAL_LISTING_AGENT && (
              <FormGridItem>
                <TextArea name="bankingInformation" label="Wire Information" withCounter />
              </FormGridItem>
            )}
            {partnerDataTypeOrPartnerType === PartnerType.AUTHORIZED_MERCHANT && (
              <>
                <FormGridItem>
                  <Input name="email" />
                </FormGridItem>
                <FormGridItem>
                  <SingleDatePicker
                    name="legalAgreementStartDate"
                    data-qa-id="legalAgreementStartDate"
                    inputDateFormat="dd/MM/yyyy"
                  />
                </FormGridItem>
              </>
            )}
            {partnerDataTypeOrPartnerType !== PartnerType.AUTHORIZED_MERCHANT && (
              <>
                <FormGridItem>
                  <Input name="name" />
                </FormGridItem>
                <FormGridItem>
                  <Input name="email" />
                </FormGridItem>
              </>
            )}
            {partnerDataTypeOrPartnerType === PartnerType.ISSUER && (
              <>
                <FormGridItem>
                  <Input name="addressLineOne" />
                </FormGridItem>
                <FormGridItem>
                  <Input name="addressLineTwo" />
                </FormGridItem>
                <FormGridItem>
                  <Select
                    data-qa-id="countryId"
                    data-qa-options-id="countryId"
                    name="countryId"
                    options={filteredCountriesOptions}
                    disabled={countriesOptions.isLoading}
                    onChange={(value) => setSelectedCountry(value)}
                    resetOnChange={['city']}
                    onFilter={setCountriesFilter}
                    filterable
                  />
                </FormGridItem>
                <FormGridItem>
                  <Select
                    data-qa-id="cityId"
                    data-qa-options-id="cityId"
                    name="cityId"
                    options={filteredCitiesOptions}
                    disabled={citiesOptions.isLoading || !selectedCountry}
                    onFilter={setCitiesFilter}
                    filterable
                  />
                </FormGridItem>
                <FormGridItem>
                  <Input name="postalCode" />
                </FormGridItem>
              </>
            )}
            {partnerDataTypeOrPartnerType === PartnerType.AUTHORIZED_PARTICIPANT && (
              <FormGridItem>
                <SingleDatePicker
                  name="legalAgreementStartDate"
                  data-qa-id="legalAgreementStartDate"
                  inputDateFormat="dd/MM/yyyy"
                />
              </FormGridItem>
            )}
            <FormGridItem>{updatePartnerMutation.isLoading && <SaveProgress />}</FormGridItem>
            <FormGridItem display={'flex'} gap={2} justifyContent={'flex-end'} md={12}>
              {!isNewPartner && partnerData.status !== 'DELETED' && (
                <div>
                  <Button
                    type="button"
                    data-qa-id="deactivateButton"
                    fullWidth
                    onClick={() => {
                      openModal(
                        {
                          modalName: PARTNER_MODAL,
                          modalData: {
                            data: partnerData,
                            type: MODAL_ACTIONS.DELETE,
                          },
                        },
                        {
                          onCloseModalAction: () =>
                            navigate(privateRoutesUrls.dashboardRoutes.partners, {
                              replace: true,
                            }),
                        }
                      );
                    }}
                    variant="secondary"
                  >
                    Deactivate
                  </Button>
                </div>
              )}
              <div>
                <Button
                  type="submit"
                  data-qa-id="nextButton"
                  fullWidth
                  disabled={!isFormDataDirty}
                  variant="primary"
                >
                  {submitText}
                </Button>
              </div>
            </FormGridItem>
          </FormGridContainer>
        </Form>
      )}
    </div>
  );
};
