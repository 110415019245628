import { Button } from 'components/Button/Button';
import { IModalWithData } from 'shared/Modals/types';
import { CustomModal } from 'shared/Modals/Modal';
import { useApproveRebalanceDetails } from 'pages/Rebalance/hooks/useCalculateRebalanceDetails';
import { RebalanceWithDetails } from 'utils/types/rebalance';
import { TradesTable } from './TradesTable/TradesTable';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Box, Stack, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

export const ApproveRebalanceModal = ({ data, onCloseModalAction, closeModal }: IModalWithData) => {
  const onSuccessCallback = () => {
    onCloseModalAction && onCloseModalAction();
    closeModal();
  };

  const approveRebalanceMutation = useApproveRebalanceDetails(onSuccessCallback);
  const rebalance = data.data as RebalanceWithDetails;

  const onSubmitHandler = () => {
    if (rebalance) {
      approveRebalanceMutation.mutate({
        id: rebalance?._id,
      });
    }
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        fullWidth
        onClick={() => onSubmitHandler()}
        type="submit"
        data-qa-id="confirmButton"
        isLoading={approveRebalanceMutation.isLoading}
      >
        Approve Trades
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        onClose={closeModal}
        header={<CardHeaderTitleWithLabel label={'Rebalance'} title={'Approval Confirmation'} />}
        body={
          <Stack padding={2} gap={1}>
            <Typography variant="subheadingMedium">
              Would you like to approve the following trades:
            </Typography>
            <TradesTable rebalance={rebalance} />
          </Stack>
        }
        footer={<Box padding={2}>{Footer}</Box>}
      />
    </CustomModal>
  );
};
