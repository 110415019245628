import { Button } from 'components/Button/Button';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { Box, Stack, Typography } from '@mui/material';

export const YesOrNoModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const onSubmitHandler = () => {
    closeModal();
    if (onCloseModalAction) onCloseModalAction(data?.data);
  };

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        onClose={closeModal}
        header={<CardHeaderTitleWithLabel title="Navigate" />}
        body={
          <Stack padding={2}>
            <Typography variant="bodyMedium">You have made changes that are not saved. </Typography>
            <Typography variant="bodyMedium">Do you want to proceed to another page? </Typography>
          </Stack>
        }
        footer={
          <Box padding={2}>
            <MuiStyledModalFooterButtons>
              <Button
                variant="secondary"
                fullWidth
                onClick={closeModal}
                type="button"
                data-qa-id="cancelButton"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                fullWidth
                onClick={() => onSubmitHandler()}
                type="submit"
                data-qa-id="confirmButton"
              >
                Confirm
              </Button>
            </MuiStyledModalFooterButtons>
          </Box>
        }
      />
    </CustomModal>
  );
};
