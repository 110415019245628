import { Button } from 'components/Button/Button';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import Select from 'components/Select';
import timeOptions, { getTimeOptionsAfter } from 'utils/select-options/timeOptions';
import { Calendar } from 'utils/types/calendar';
import { DefaultValues, SubmitHandler, useFormState } from 'react-hook-form';
import { Exchange } from 'utils/types';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { exchangeSchemaUrl } from 'components/Form/formSchemas';
import { mapCalendarToOptions } from 'utils/calendar';
import { useMemo, useState } from 'react';
import { ExchangeFormDataModel, toFormModel } from 'pages/Admin/ExchangesPage/utils';
import { StyledMarketRow } from './ExchangeForm.styles';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { Box, Divider, Stack } from '@mui/material';

type ExchangeFormProps = {
  calendars: Calendar[];
  exchange?: Exchange;
  onCancel?: () => void;
  onSubmit: (values: ExchangeFormDataModel) => void;
  loading?: boolean;
  isUpdating?: boolean;
};

export const ExchangeForm = ({
  exchange,
  onCancel,
  onSubmit,
  calendars,
  loading,
  isUpdating,
}: ExchangeFormProps) => {
  const isCreateMode = !exchange;
  const [marketOpen, setMarketOpen] = useState<string | undefined>(exchange?.marketOpen);
  const calendarOptions = useMemo(() => mapCalendarToOptions(calendars), [calendars]);
  const marketCloseOptions = Boolean(marketOpen) ? getTimeOptionsAfter(marketOpen as string) : [];

  const handleOnSubmit: SubmitHandler<ExchangeFormDataModel> = (props) => {
    onSubmit(props);
  };

  const handleOnChange = ({ marketOpen }: DefaultValues<ExchangeFormDataModel>) => {
    setMarketOpen(marketOpen);
  };

  return (
    <Card
      noPadding
      header={
        <CardHeaderTitleWithLabel
          label="Exchanges"
          title={isCreateMode ? 'Create New Exchange' : 'Edit Exchange'}
        />
      }
      body={
        <Form
          initialValues={isCreateMode ? {} : toFormModel(exchange)}
          onChange={handleOnChange}
          onSubmit={handleOnSubmit}
          schemaUrl={exchangeSchemaUrl}
          loading={loading}
        >
          <Stack padding={2} gap={1}>
            <Input name="name" label="Name" />
            <MultiSelect
              name="countries"
              label="Countries"
              data-qa-id="countries"
              data-qa-options-id="countries"
              size="large"
            />
            <MultiSelect
              name="calendars"
              options={calendarOptions}
              data-qa-id="CalendarsOptionDropdown"
              data-qa-options-id="CalendarsOptionDropdown"
              size="large"
            />
            <StyledMarketRow>
              <Select
                name="marketOpen"
                helperText="*Must be before market close"
                options={timeOptions}
                data-qa-id="MarketOpenDropdown"
                data-qa-options-id="MarketOpenDropdown"
              />
              <Select
                name="marketClose"
                options={marketCloseOptions}
                data-qa-id="MarketCloseDropdown"
                data-qa-options-id="MarketCloseDropdown"
              />
            </StyledMarketRow>
          </Stack>
          <Divider sx={{ width: '100%' }} />
          <Box padding={2}>
            <Footer
              isEditModel={!isCreateMode}
              onCancel={onCancel}
              isLoading={Boolean(isUpdating)}
            />
          </Box>
        </Form>
      }
      onClose={onCancel}
    ></Card>
  );
};

const Footer = ({
  isEditModel,
  onCancel,
  isLoading,
}: {
  isEditModel: boolean;
  onCancel?: () => void;
  isLoading: boolean;
}) => {
  const data = useFormState();

  return (
    <MuiStyledModalFooterButtons>
      <Button
        data-qa-id="cancelButton"
        variant="secondary"
        fullWidth
        type="button"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        data-qa-id="createExchangesButton"
        fullWidth
        type="submit"
        disabled={!data.isValid}
        isLoading={isLoading}
        variant="primary"
      >
        {isEditModel ? 'Save Exchange' : 'Create Exchange'}
      </Button>
    </MuiStyledModalFooterButtons>
  );
};
