import CustodiansTable from './components/CustodianTable/CustodianTable';
import Footer from 'pages/Instruments/components/Footer';
import { CustodiansListProps } from 'pages/Instruments/components/Form/PartnerDetails/PartnerDetails.types';
import { useCurrentInstrument } from 'store/instruments/selectors';
import { useInstrumentCustodiansQuery } from './hooks/useInstrumentCustodians';
import { useInstrumentWalletsQuery } from 'pages/Instruments/components/Form/PartnerDetails/components/WalletsInfoStep/hooks/useInstrumentWallets';
import { useMemo } from 'react';

interface CustodianInfoProps {
  goBack: () => void;
  onSubmit: (data: CustodiansListProps) => void;
}

export const CustodianInfo = ({ goBack, onSubmit }: CustodianInfoProps) => {
  const { saving, instrument } = useCurrentInstrument();

  const {
    isLoading: loadingCustodians,
    isFetching: fetchingCustodians,
    data: custodians,
    refetch: refetchCustodians,
  } = useInstrumentCustodiansQuery(instrument?._id ?? '', 'ACTIVE,DELETED');
  const { isLoading: loadingWallets, refetch: refetchWallets } = useInstrumentWalletsQuery(
    instrument?._id ?? '',
    'ACTIVE,DELETED'
  );

  const partnersCustodians = useMemo(
    () =>
      Array.from(
        new Set(instrument?.partners?.custodians?.map((custodian) => custodian.provider) || [])
      ),
    [instrument]
  );

  const handleSubmit = () => {
    onSubmit(custodians as CustodiansListProps);
  };

  return (
    <>
      <CustodiansTable
        allCustodians={custodians as CustodiansListProps}
        partnersCustodians={partnersCustodians ?? []}
        loadingCustodians={loadingCustodians || fetchingCustodians || saving || loadingWallets}
        onCustodianListUpdate={() => {
          refetchCustodians();
          refetchWallets();
        }}
      />
      <Footer goBack={goBack} onSubmit={handleSubmit} />
    </>
  );
};
