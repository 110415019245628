import { TableBody, TableCell, TableRow } from '@mui/material';
import Table from 'components/Table';
import TooltipContent from 'components/Tooltip/TooltipContent';
import TableHeader from 'components/Table/TableHeader';
import { formatToCurrency } from 'utils/formatting';
import { Order } from 'utils/types/orders';

interface FeeBreakdownTableProps {
  order: Order;
}

const FeeBreakdownTable: React.FC<FeeBreakdownTableProps> = ({ order }) => (
  <Table noPadding>
    <TableHeader
      columns={[
        {
          label: 'Fee breakdown',
          propName: 'feeBreakdown',
          propType: 'string',
          sort: false,
          tooltip: <TooltipContent description="The fees that were charged for this order" />,
        },
      ]}
    />
    <TableBody>
      <TableRow>
        <TableCell>Admin Fee</TableCell>
        <TableCell align="right">
          {Boolean(order?.tradesAdminFee || order?.tradesAdjustedAdminFee)
            ? formatToCurrency(
                order?.tradesAdminFee || order?.tradesAdjustedAdminFee,
                order?.deliveries?.expectedTotalCurrency
              )
            : '-'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Execution Fee (10bps)</TableCell>
        <TableCell align="right">
          {Boolean(order?.tradesExecutionFee)
            ? formatToCurrency(order?.tradesExecutionFee, order?.deliveries?.expectedTotalCurrency)
            : '-'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total Fees</TableCell>
        <TableCell align="right">
          {Boolean(order?.tradesTotalFees)
            ? formatToCurrency(order?.tradesTotalFees, order?.deliveries?.expectedTotalCurrency)
            : '-'}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export default FeeBreakdownTable;
