import { Button } from 'components/Button/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { ContactListModalBatch } from 'shared/Modals/ContactLists/types';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { PartnerContact } from 'utils/types';
import { createNotification } from 'store/notifications/actions';
import { errorNotification, successNotification } from 'shared/Notifications/general.notifications';
import { removeContactsFromContactList } from 'utils/api/contacts';
import { useMutation } from 'react-query';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { Box, Typography } from '@mui/material';

export const RemovePartnerFromContactListModal = ({
  closeModal,
  onCloseModalAction,
  data,
}: IModalWithData) => {
  const dispatch = useAppDispatch();
  const modalData = data.data as ContactListModalBatch;
  const contactList = modalData.contactList;
  const contact = modalData.contact;
  const partner = modalData.partnerContacts as PartnerContact;
  const removePartnerMutation = useMutation({
    mutationFn: () => {
      const payload = {
        contacts: contact?._id ? [contact?._id] : [],
        partners: partner?._id ? [partner._id] : [],
      };
      return removeContactsFromContactList(contactList, payload);
    },
    onSuccess: () => {
      const name = partner?.name ?? contact?.name;
      dispatch(
        createNotification(
          successNotification(
            `${name} has been removed from ${contactList?.name} list successfully.`,
            'Contact list updated'
          )
        )
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (error: any) => {
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  const message = partner?.name
    ? `Are you sure you want to remove ${partner?.name} contacts from this list?`
    : `Are you sure you want to remove ${contact?.name} from this list?`;
  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        onClose={closeModal}
        header={
          <CardHeaderTitleWithLabel label={`${contactList?.name} List`} title="Remove Partner" />
        }
        body={
          <Box padding={2}>
            <Typography variant="bodyMedium">{message}</Typography>
          </Box>
        }
        footer={
          <Box padding={2}>
            <MuiStyledModalFooterButtons>
              <Button data-qa-id="cancelButton" fullWidth variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                data-qa-id="deleteButton"
                fullWidth
                onClick={() => {
                  removePartnerMutation.mutate();
                }}
                isLoading={removePartnerMutation.isLoading}
                variant="danger"
              >
                Delete
              </Button>
            </MuiStyledModalFooterButtons>
          </Box>
        }
      ></Card>
    </CustomModal>
  );
};
