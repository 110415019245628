import { useId, useState } from 'react';
import { Box, IconButton, Popover, useTheme } from '@mui/material';
import { ReactComponent as ArrowRightIcon } from 'assets/chevron-right.svg';
import { primary } from 'theme/utils/paletteColors';

interface ShowMoreProps {
  className?: string;
  items: string[];
}

function ShowMore({ items, ...props }: ShowMoreProps) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = useId();

  return (
    <div {...props}>
      <IconButton color="primary" onClick={handleClick} size="small">
        <ArrowRightIcon fill={primary.main} />
      </IconButton>
      <Popover
        id="popover-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box padding={theme.spacers['spacing-24']} display={'grid'} gap={'16px'}>
          <span
            style={{
              // @TODO font for this copy
              color: theme.palette.secondary.light,
            }}
          >
            Contact List
          </span>
          <ul onClick={handleClose}>
            {items.map((item, index) => (
              <li
                style={{
                  // @TODO font for this element
                  padding: `${theme.spacers['spacing-08']} 0`,
                }}
                key={`${id}_${item}_${index}`}
              >
                {item}
              </li>
            ))}
          </ul>
        </Box>
      </Popover>
    </div>
  );
}

export default ShowMore;
