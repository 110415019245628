import { generatePath, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { IModalWithData } from 'shared/Modals/types';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  NavReviewItem,
  NavReviewItemWithProduct,
  RebookEstimation,
  TransactionLedger,
} from 'utils/types/nav';
import { PortfolioCompositionStatus } from 'utils/types/pcfs';
import ProductIcon from 'components/ProductIcon';
import { Column } from 'components/Grid';
import Notification from 'components/Notification';
import NavReviewDifferencesTable from 'shared/Modals/NavReviewModals/NavReviewDifferencesTable/NavReviewDifferencesTable';
import { useMemo, useState } from 'react';
import { NotificationType } from 'utils/types';
import { StyledNotificationColumn } from './NavReviewModal.styles';
import { useNavReviewModalData } from './hook/useNavReviewModalData';
import { RebookWithNoteModal } from './RebookWithNoteModal';
import { useMutation } from 'react-query';
import { createEntityApiCall, createEntityApiCallWithCustomResponse } from 'utils/api/crudActions';
import { createNotification } from 'store/notifications/actions';
import { filterTableRows } from './utils/filterTableRows';
import { privateRoutesUrls } from 'router/constants';
import { CustomModal } from 'shared/Modals/Modal';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { Stack } from '@mui/material';

export type NavDifferenceRow = {
  onyxValue?: number;
  accountantValue?: number;
  difference?: number;
  transactions?: TransactionLedger[];
  _id: string;
};

export const NavReviewModal = ({ closeModal, data, onCloseModalAction }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navReviewData = data.data as NavReviewItemWithProduct;
  const [showRebookModal, setShowRebookModal] = useState(false);
  const [overrideBalance, setOverrideBalance] = useState<string | number>(
    navReviewData.ledgerBalance ?? '0'
  );

  const { mappedTransactions, navReviewItem, product, updateDeltaNavReviewItem, isLoading } =
    useNavReviewModalData(navReviewData);

  const hasOpenOrders =
    navReviewItem?.openOrders !== undefined && !isEmpty(navReviewItem?.openOrders);
  const rebalanceNotApproved = navReviewItem?.isRebalanceApproved === false;
  const tableRows = filterTableRows(navReviewItem, product);

  const rebookEstimationMutation = useMutation({
    mutationFn: (val: { uri: string; overrideBalance: string | number }) => {
      return createEntityApiCallWithCustomResponse<RebookEstimation, NavReviewItem>(val.uri, {
        overrideBalance: val.overrideBalance,
        reason: '',
      });
    },
    onSuccess: (res) => {
      updateDeltaNavReviewItem(res.data);
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(
        createNotification({
          message: error.message,
          title: 'NAV Rebook Estimation error',
          type: 'error',
        })
      );
    },
  });

  const refreshOnyxDataMutation = useMutation({
    mutationFn: (uri: string) => {
      return createEntityApiCall<NavReviewItem>(null, uri);
    },
    onSuccess: (res) => {
      updateDeltaNavReviewItem(res.data);
      setOverrideBalance(res.data.ledgerBalance ?? '0');
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(
        createNotification({
          message: error.message,
          title: 'NAV refresh error',
          type: 'error',
        })
      );
    },
  });

  const openOrdersPage = async () => {
    closeModal();
    navigate(generatePath(privateRoutesUrls.dashboardRoutes.orders));
  };

  const notificationData = useMemo(() => {
    if (rebalanceNotApproved) {
      return {
        action: () => {
          navigate(`${privateRoutesUrls.dashboardRoutes.rebalance}?tab=today`);
          closeModal();
        },
        actionText: 'Rebalance',
        title: 'Rebalance needed',
        message:
          'NAV cannot be approved on rebalance day for a fund until the rebalance is approved.',
        variant: 'error',
      };
    }
    if (hasOpenOrders) {
      return {
        action: openOrdersPage,
        actionText: 'Review orders',
        title: 'Unsettled orders',
        message: 'There are unsettled orders, please review them before rebooking.',
        variant: 'error',
      };
    }
    if (navReviewItem?.status === PortfolioCompositionStatus.PENDING) {
      return {
        title: 'Discrepancies found in NAV calculation',
        message: '',
        variant: 'error',
      };
    }
    return {
      title: 'No discrepancies found.',
      message: '',
      variant: 'success',
    };
  }, [navReviewItem]);

  if (!navReviewItem) {
    return null;
  }

  return (
    <CustomModal customwidth="980px" open onCloseModal={closeModal}>
      <Card
        noPadding
        header={
          <CardHeaderTitleWithLabel
            label="NAV Review"
            icon={
              <ProductIcon
                iconUrl={navReviewData.product.fundIcon}
                ticker={navReviewData.product.ticker ?? ''}
              />
            }
            title={`${product?.ticker} / ${navReviewItem.ticker}`}
          />
        }
        body={
          <Stack padding={2}>
            <StyledNotificationColumn gutter="noGutter">
              <Notification
                action={notificationData.action}
                actionText={notificationData.actionText}
                title={notificationData.title ?? ''}
                message={notificationData.message ?? ''}
                variant={notificationData.variant as NotificationType}
                messageMaxWidth
                multiline={'false'}
                withcountdown="false"
              />
            </StyledNotificationColumn>
            <Column cols={12}>
              <NavReviewDifferencesTable
                navTransactions={mappedTransactions}
                navReviewItem={navReviewItem}
                onRebook={() => {
                  setShowRebookModal(true);
                }}
                onRebookEstimation={(val) => {
                  rebookEstimationMutation.mutate({
                    uri: navReviewItem._actions?.rebookEstimation?.uri ?? '',
                    overrideBalance: val ?? overrideBalance,
                  });
                }}
                hasError={
                  navReviewItem.status === PortfolioCompositionStatus.PENDING ||
                  rebalanceNotApproved
                }
                hasOpenOrders={hasOpenOrders}
                loading={
                  isLoading ||
                  rebookEstimationMutation.isLoading ||
                  refreshOnyxDataMutation.isLoading
                }
                onRefreshOnyxData={() => {
                  refreshOnyxDataMutation.mutate(
                    navReviewItem._actions?.calculateNetAssetValue?.uri ?? ''
                  );
                }}
                overrideBalance={overrideBalance}
                setOverrideBalance={setOverrideBalance}
                tableRows={tableRows}
              />
            </Column>
          </Stack>
        }
        onClose={closeModal}
      ></Card>
      {showRebookModal && (
        <RebookWithNoteModal
          portfolioId={navReviewData.portfolioId}
          navReviewItem={navReviewItem}
          closeRebookModal={() => setShowRebookModal(false)}
          onCloseModalAction={() => {
            setShowRebookModal(false);
            onCloseModalAction && onCloseModalAction();
            closeModal();
          }}
        />
      )}
    </CustomModal>
  );
};
