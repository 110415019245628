import { Box, styled } from '@mui/material';
import MaterialTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ReactComponent as InformationIcon } from 'assets/information.svg';

const TooltipContent = styled(Box)<{ inline?: boolean }>`
  width: fit-content;
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
`;

interface CustomTooltipProps extends Omit<TooltipProps, 'children'> {
  inline?: boolean;
  wide?: boolean;
  bgColor?: string;
  children?: React.ReactNode; // Make children optional
}

const Tooltip = styled(
  ({
    arrow = true,
    className,
    inline = true,
    wide = false,
    bgColor = 'black',
    children,
    ...props
  }: CustomTooltipProps) => (
    <MaterialTooltip {...props} classes={{ popper: className }} arrow={arrow}>
      <TooltipContent inline={inline}>
        <Box display="inline-block" sx={{ cursor: 'pointer' }}>
          {/* Use children or default to InformationIcon */}
          {children || (
            <Box width="12px">
              <InformationIcon style={{ paddingLeft: 0.5 }} />
            </Box>
          )}
        </Box>
      </TooltipContent>
    </MaterialTooltip>
  )
)(({ bgColor = 'black', color }) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: bgColor,
      color: color,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: bgColor,
    },
  };
});

export default Tooltip;
