import { Box, styled as MuiStyled } from '@mui/material';

export const OnCloseContainer = MuiStyled(Box)(({ theme }) => ({
  cursor: 'pointer',
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

interface StyledCardFooterProps {
  exiticon?: 'true' | 'false';
}

export const StyledCardFooter = MuiStyled('footer')<StyledCardFooterProps>`
  display: flex;
  gap: ${({ theme }) => theme.spacers['spacing-08']};
  padding-top: ${({ theme }) => theme.spacers['spacing-16']};
  ${({ exiticon = 'false' }) => exiticon === 'true' && { 'padding-top': '32px' }};
`;
