import { CustomBasketDeliverable, Order } from 'utils/types';
import Table from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { TableBody } from '@mui/material';
import TooltipContent from 'components/Tooltip/TooltipContent';
import { Checkbox } from 'components/BooleanInputs';
import DeliverableWeight from 'pages/Orders/Orders/Order/components/OrderDetails/components/CustomBasketDeliverables/components/DeliverableWeight';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import { formatToCurrency } from 'utils/formatting';
import BigNumber from 'bignumber.js';
import { useState, useEffect } from 'react';

export interface CustomBasketDeliverablesTableProps {
  deliverables?: CustomBasketDeliverable[];
  onChangeDeliverable?: (deliverables: CustomBasketDeliverable | CustomBasketDeliverable[]) => void;
  order?: Order;
}

const CustomBasketDeliverablesTable = ({
  deliverables,
  onChangeDeliverable,
  order,
}: CustomBasketDeliverablesTableProps) => {
  const [selectedDeliverables, setSelectedDeliverables] = useState<string[]>([]);

  const toggleDeliverables = (isChecked: boolean) => {
    onChangeDeliverable?.(
      deliverables?.map((deliverable) => ({
        isLocked: !isChecked,
        weight: isChecked ? deliverable.weight : '0',
        ticker: deliverable.ticker,
      })) || []
    );
  };

  useEffect(() => {
    if (deliverables?.length) {
      setSelectedDeliverables(
        deliverables
          .filter((deliverable) => new BigNumber(deliverable.weight || 0).gt(new BigNumber(0)))
          .map((deliverable) => deliverable.ticker)
      );
    }
  }, [deliverables]);

  const fxRate = (deliverables && deliverables[0]?.fxRate) || '0';
  const customBasketDeliverablesTableColumnsConfig = [
    { label: 'Asset', propName: 'asset', propType: 'string', sort: false },
    { label: 'Weight (%)', propName: 'weight', propType: 'string', sort: false },
    { label: 'Quantity', propName: 'quantity', propType: 'string', sort: false },
    // TODO: hide in small screens
    {
      label: order?.deliveryType === 'CASH' ? 'Reference Price' : 'Price',
      propName: 'referencePrice',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title="Reference price"
          description={`T-1 Price from PCF with valuation date ${
            deliverables &&
            deliverables[0]?.priceAt &&
            format(new Date(deliverables[0]?.priceAt), DEFAULT_DATE_FORMAT)
          }. This PCF was effective at the time of this order`}
        />
      ),
    },
    { label: 'Market Value', propName: 'marketValue', propType: 'number', sort: false },
    {
      label: 'Expected Delivery',
      propName: 'expectedDelivery',
      propType: 'number',
      sort: false,
      tooltip: order?.deliveryType === 'CASH' && (
        <TooltipContent
          title={`USD/USDC: ${fxRate}`}
          description={`The FX rate between base currency and delivery currency is ${fxRate}`}
        />
      ),
    },
  ];

  return (
    <>
      <Table noPadding={true}>
        <TableHeaderWithMultiSort
          checkboxProps={{
            checked: deliverables?.length === selectedDeliverables?.length,
            dash: true,
            onChange: toggleDeliverables,
          }}
          columns={customBasketDeliverablesTableColumnsConfig}
          columnAlignment={{ weight: 'center' }}
        />
        <TableBody>
          {deliverables?.map((deliverable) => (
            <MuiStyledTableRow key={deliverable.ticker}>
              <MuiStyledTableCell maxWidth="24px" width="24px">
                <Checkbox
                  checked={new BigNumber(deliverable.weight || 0).gt(new BigNumber(0))}
                  onChange={(isChecked) => {
                    onChangeDeliverable?.({
                      isLocked: !isChecked,
                      weight: '0',
                      ticker: deliverable.ticker,
                    });
                  }}
                />
              </MuiStyledTableCell>
              <MuiStyledTableCell>{deliverable.ticker}</MuiStyledTableCell>
              <MuiStyledTableCell align="center" maxWidth="120px">
                <DeliverableWeight
                  key={`${deliverable.ticker}-${deliverable.weight}`}
                  isLocked={deliverable.isLocked}
                  weight={deliverable.weight}
                  ticker={deliverable.ticker}
                  onChangeDeliverable={onChangeDeliverable}
                />
              </MuiStyledTableCell>
              <MuiStyledTableCell>{deliverable.amount}</MuiStyledTableCell>
              <MuiStyledTableCell key="priceT1Nav">
                {/* TODO: hide in small screens */}
                {deliverable.price &&
                  formatToCurrency(
                    new BigNumber(deliverable.price)
                      .decimalPlaces(2, BigNumber.ROUND_HALF_UP)
                      .toNumber(),
                    deliverable?.currency
                  )}
              </MuiStyledTableCell>
              <MuiStyledTableCell>
                {deliverable.totalInCurrency &&
                  formatToCurrency(
                    new BigNumber(deliverable.totalInCurrency)
                      .decimalPlaces(2, BigNumber.ROUND_HALF_UP)
                      .toNumber(),
                    deliverable?.currency
                  )}
              </MuiStyledTableCell>
              <MuiStyledTableCell>{deliverable.totalInDeliveryCurrency}</MuiStyledTableCell>
            </MuiStyledTableRow>
          ))}
          <MuiStyledTableRow key="totals" isExpanded>
            <MuiStyledTableCell maxWidth="24px" width="24px" colSpan={2}>
              TOTAL
            </MuiStyledTableCell>
            <MuiStyledTableCell align="center" maxWidth="120px">
              {deliverables
                ?.reduce(
                  (totalWeight, deliverable) =>
                    totalWeight.plus(new BigNumber(deliverable.weight || 0).multipliedBy(100)),
                  new BigNumber(0)
                )
                .toFixed(2, BigNumber.ROUND_HALF_UP)}
              %
            </MuiStyledTableCell>
            <MuiStyledTableCell></MuiStyledTableCell>
            <MuiStyledTableCell key="priceT1Nav">
              {/* TODO: hide in small screens */}
            </MuiStyledTableCell>
            <MuiStyledTableCell>
              {formatToCurrency(
                new BigNumber(order?.deliveries?.expectedTotalCash || 0)
                  .decimalPlaces(2, BigNumber.ROUND_HALF_UP)
                  .toNumber(),
                order?.deliveries?.expectedTotalCurrency
              )}
            </MuiStyledTableCell>
            <MuiStyledTableCell>
              {order?.deliveryType === 'CASH' && order.deliveries?.expectedTotalInDeliveryCurrency}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default CustomBasketDeliverablesTable;
