import { MuiStyledTableRow } from 'components/Table/TableRow';
import { TABLE_CELL_ALIGNMENTS } from 'shared/Tables/table.utils';
import { TableHeaderColumn } from 'components/Table/Table.types';
import { ReactComponent as SortArrow } from 'assets/sort-arrows.svg';
import { useState } from 'react';
import { MuiStyledHeaderTableCells } from './TableHeaderWithMultiSort.styles';
import { TableHead } from '@mui/material';
import Tooltip from 'components/Tooltip';

const TableHeader = ({
  columns,
  showActionsCell = true,
  columnAlignment = {},
  onColumnSort,
}: {
  columns: TableHeaderColumn[];
  showActionsCell?: boolean;
  columnAlignment?: { [key: string]: TABLE_CELL_ALIGNMENTS };
  onColumnSort?: (col: TableHeaderColumn) => void;
}) => {
  const [sortedColumn, setSortedColumn] = useState<string>();

  return (
    <TableHead>
      <MuiStyledTableRow>
        {columns.map((col) => (
          <MuiStyledHeaderTableCells
            key={col.label}
            align={columnAlignment[col.propName] ?? 'left'}
            onClick={() => {
              if (onColumnSort && col.sort !== false) {
                onColumnSort(col);
                setSortedColumn(col.propName);
              }
            }}
            style={{
              cursor: col.sort !== false ? 'pointer' : undefined,
              width: col.width,
            }}
            colSpan={col.colSpan}
          >
            {col.label}
            {sortedColumn === col.propName && <SortArrow fontSize={16} />}
            {col.tooltip && (
              <Tooltip style={{ marginLeft: '4px ' }} placement="bottom" title={col.tooltip} />
            )}
          </MuiStyledHeaderTableCells>
        ))}
        {showActionsCell && <MuiStyledHeaderTableCells></MuiStyledHeaderTableCells>}
      </MuiStyledTableRow>
    </TableHead>
  );
};

export default TableHeader;
