import { FC } from 'react';

// styles, assets
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import { StyledResetPasswordIcon } from 'pages/Login/UpdatePassword/UpdatePassword.styles';

// components
import { Button } from 'components/Button/Button';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Card } from 'components/Card/Card';
import { Box, Typography } from '@mui/material';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

interface Initialise2faSetupInterface {
  handleSkip: () => void;
  handleNext: () => void;
  firstLogin: boolean;
}

export const RecommendToUpdatePasswordScreen: FC<Initialise2faSetupInterface> = ({
  handleSkip,
  handleNext,
  firstLogin,
}) => {
  return (
    <Card
      noPadding
      header={
        <CardHeaderTitleWithLabel icon={<StyledResetPasswordIcon />} title="Reset Password" />
      }
      body={
        <Box padding={2}>
          <Typography variant="bodyMedium">
            {firstLogin
              ? 'We recommend to reset your password to be in compliance with our security parameters.'
              : 'In order to protect your account, we have added new security layers into the Onyx passwords.We recommend to reset your password to be in compliance with our new security parameters.'}
          </Typography>
        </Box>
      }
      footer={
        <Box padding={2}>
          <MuiStyledModalFooterButtons>
            <Button variant="inverse" fullWidth type="submit" onClick={handleSkip}>
              Skip for now
            </Button>
            <Button
              onClick={handleNext}
              variant="primary"
              fullWidth
              sx={{ '& span': { color: 'white' } }}
            >
              <>
                <span>Continue</span>
                <ArrowRightIcon />
              </>
            </Button>
          </MuiStyledModalFooterButtons>
        </Box>
      }
    />
  );
};
