import { ThemeTokens } from 'theme/themeTokens/types/ThemeTokensTypes';
import { ChipVariantsInterface } from 'theme/themeTokens/types/ThemeComponentTypes';
import { blue, green, neutral, red, yellow } from 'theme/colors/baseColors';

export const chipPalette = (themeVariant: ThemeTokens): ChipVariantsInterface => ({
  default: {
    backgroundColor: themeVariant.static.background.default.standard,
    color: themeVariant.static.text.default.standard,
    borderBottom: neutral[30],
    hover: {
      backgroundColor: themeVariant.static.background.default.weak,
      color: themeVariant.static.text.default.strong,
    },
    focusVisible: {
      boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
    },
    focus: {
      backgroundColor: themeVariant.static.background.default.standard,
    },
    disabled: {
      backgroundColor: themeVariant.interactive.fill.secondary.disabled,
      border: `1px solid ${themeVariant.interactive.outline.secondary.disabled}`,
      color: themeVariant.interactive.fill.tertiary.onTertiaryDisabled,
    },
  },
  green: {
    backgroundColor: themeVariant.static.support.weak.positive,
    color: themeVariant.static.text.sentiment.positive,
    borderBottom: green[30],
    hover: {},
    focus: {
      boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
    },
    disabled: {
      backgroundColor: themeVariant.interactive.fill.secondary.disabled,
      border: `1px solid ${themeVariant.interactive.outline.secondary.disabled}`,
      color: themeVariant.interactive.fill.tertiary.onTertiaryDisabled,
    },
  },
  red: {
    backgroundColor: themeVariant.static.support.weak.negative,
    color: themeVariant.static.text.sentiment.negative,
    borderBottom: red[20],
    hover: {},
    focus: {
      boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
    },
    disabled: {
      backgroundColor: themeVariant.interactive.fill.secondary.disabled,
      border: `1px solid ${themeVariant.interactive.outline.secondary.disabled}`,
      color: themeVariant.interactive.fill.tertiary.onTertiaryDisabled,
    },
  },
  blue: {
    backgroundColor: themeVariant.static.support.weak.info,
    color: themeVariant.static.support.standard.info,
    borderBottom: blue[20],
    hover: {},
    focus: {
      boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
    },
    disabled: {
      backgroundColor: themeVariant.interactive.fill.secondary.disabled,
      border: `1px solid ${themeVariant.interactive.outline.secondary.disabled}`,
      color: themeVariant.interactive.fill.tertiary.onTertiaryDisabled,
    },
  },
  yellow: {
    backgroundColor: themeVariant.static.support.weak.notice,
    color: themeVariant.static.support.standard.notice,
    borderBottom: yellow[20],
    hover: {},
    focus: {
      boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
    },
    disabled: {
      backgroundColor: themeVariant.interactive.fill.secondary.disabled,
      border: `1px solid ${themeVariant.interactive.outline.secondary.disabled}`,
      color: themeVariant.interactive.fill.tertiary.onTertiaryDisabled,
    },
  },
  inverse: {
    backgroundColor: themeVariant.interactive.fill.secondary.hover,
    color: themeVariant.static.text.inverse.strong,
    borderBottom: neutral[70],
    hover: {
      backgroundColor: themeVariant.interactive.fill.secondary.enabled,
    },
    focus: {
      boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
    },
    disabled: {
      backgroundColor: themeVariant.interactive.outline.secondary.disabled,
      border: `1px solid ${themeVariant.interactive.outline.secondary.disabled}`,
      color: themeVariant.static.text.default.weak,
    },
  },
});
