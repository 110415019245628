import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import PartnerTableRow from 'shared/Tables/PartnersTable/PartnerTableRow';
import Table, { TableProps } from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { ParamsType } from 'hooks/useUrlParams';
import { Partner } from 'utils/types';
import { PartnersTableColumnsConfig } from './tableColumnsConfig';

interface PartnersTableProps {
  tableProperties: TableProps;
  editAction?: (partner: Partner) => void;
  deleteAction?: (partner: Partner) => void;
  partners: Partner[];
  loadingData?: boolean;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
}

const doesAnyPartnerHaveActions = (partners: Partner[]) => {
  return Boolean(partners.find((partner) => partner._actions?.update || partner._actions?.delete));
};

const PartnersTable = ({
  tableProperties,
  editAction,
  deleteAction,
  partners,
  loadingData,
  setUrlParams,
  urlParams,
}: PartnersTableProps) => {
  const showActionsCell = doesAnyPartnerHaveActions(partners);

  return (
    <Table {...tableProperties}>
      <TableHeaderWithMultiSort
        defaultSortedColumns={urlParams.sort ?? []}
        columns={PartnersTableColumnsConfig}
        showActionsCell
        onColumnSort={(columns) => {
          setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
        }}
      />
      <TableBodyWithStates
        loadingData={Boolean(loadingData)}
        hasContent={!isEmpty(partners)}
        noContentLabel="No partners at the moment."
      >
        {partners?.map((partner) => (
          <PartnerTableRow
            key={partner._id}
            partner={partner as Partner}
            editAction={() => {
              if (editAction) editAction(partner as Partner);
            }}
            deleteAction={() => {
              if (deleteAction) deleteAction(partner as Partner);
            }}
            showActionsCell={showActionsCell}
          />
        ))}
      </TableBodyWithStates>
    </Table>
  );
};

export default PartnersTable;
