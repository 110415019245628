import { ContactList } from 'utils/types/contacts';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledActionsTableCell } from 'components/Table/table.styles';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';

interface ContactsListTableRowProps {
  editAction: () => void;
  editListInfoAction: () => void;
  contactList: ContactList;
  addContactsToListAction: () => void;
  deleteListAction: () => void;
}

const ContactsListTableRow = ({
  editAction,
  contactList,
  editListInfoAction,
  addContactsToListAction,
  deleteListAction,
}: ContactsListTableRowProps) => {
  const actions = contactList._actions;

  return (
    <MuiStyledTableRow data-qa-id={`${contactList.name}-row`} key={contactList._id}>
      <MuiStyledTableCell
        data-qa-id={`${contactList.name}-name`}
        onClick={() => editAction()}
        pointer
      >
        {contactList.name}
      </MuiStyledTableCell>
      <MuiStyledTableCell
        data-qa-id={`${contactList.name}-type`}
        onClick={() => editAction()}
        pointer
        align={'right'}
      >
        {contactList.type}
      </MuiStyledTableCell>
      <MuiStyledTableCell
        data-qa-id={`${contactList.name}-count`}
        onClick={() => editAction()}
        pointer
        align={'right'}
      >
        {contactList.contactsCount}
      </MuiStyledTableCell>
      <MuiStyledActionsTableCell data-qa-id="editIcons">
        <ButtonDropdown id={contactList._id ?? ''} className="actionsCell">
          {actions?.update && (
            <ButtonDropdownItem
              id="editContactsList"
              label="Edit Distribution List"
              onClick={editListInfoAction}
            />
          )}
          {actions?.assign && (
            <ButtonDropdownItem
              id="addContactToContactsList"
              label="Add contact to Distribution List"
              onClick={addContactsToListAction}
            />
          )}
          {actions?.delete && (
            <ButtonDropdownItem
              id="deleteContactsList"
              label="Delete Distribution List"
              onClick={deleteListAction}
            />
          )}
        </ButtonDropdown>
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};

export default ContactsListTableRow;
