import Product from 'components/Product';
import dateToString from 'utils/decorators/dateToString';
import titelize from 'utils/decorators/titelize';
import { Instrument, ProductStatus, ProductType } from 'utils/types/product';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledActionsTableCell } from 'components/Table/table.styles';
import { TableCellContent } from 'pages/Ledger/NavReviewPage/components/styles';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';

interface ProductsTableRowProps {
  productType: ProductType;
  product?: Instrument;
  editAction?: () => void;
  deleteAction?: () => void;
  archiveAction?: () => void;
  activateAction?: (createSeedOrder?: Boolean) => void;
  reactivateAction?: () => void;
  showActionsCell?: boolean;
}

export const ETPTableRow = ({
  product,
  editAction,
  deleteAction,
  archiveAction,
  activateAction,
  showActionsCell = true,
}: ProductsTableRowProps) => {
  return (
    <MuiStyledTableRow
      data-qa-id={`${product?.name}-row`}
      key={product?._id}
      onClick={
        product?.status === ProductStatus.DELETED || product?._actions?.update
          ? editAction
          : undefined
      }
    >
      <MuiStyledTableCell data-qa-id={`${product?.name}-ticker`}>
        <TableCellContent>
          <Product instrument={product} />
        </TableCellContent>
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${product?.name}-name`}>{product?.name}</MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${product?.name}-type`}>
        {product && titelize(product?.type)}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${product?.name}-updated`}>
        {product && dateToString(product?.updatedAt)}
      </MuiStyledTableCell>
      <MuiStyledActionsTableCell
        data-qa-id="actionBtn"
        onClick={(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        {showActionsCell &&
          (product?.status === ProductStatus.DELETED ||
          product?.status === ProductStatus.PENDING ? (
            <>
              {product?._actions?.archive && (
                <ButtonDropdown id={product._id} className="actionsCell">
                  <ButtonDropdownItem
                    id="resendInvitation"
                    label="Archive"
                    onClick={archiveAction ? archiveAction : () => {}}
                  />
                </ButtonDropdown>
              )}
            </>
          ) : (
            <>
              {(product?._actions?.activate || product?._actions?.delete) &&
                product?.status !== ProductStatus.ARCHIVED && (
                  <ButtonDropdown id={product._id} className="actionsCell">
                    {product?._actions?.activate && product?.status === ProductStatus.IN_REVIEW && (
                      <ButtonDropdownItem
                        id="resendInvitation"
                        label="Create Seed Order"
                        onClick={() => activateAction && activateAction(true)}
                      />
                    )}
                    {product?._actions?.activate && product?.status === ProductStatus.IN_REVIEW && (
                      <ButtonDropdownItem
                        id="resendInvitation"
                        label="Activate"
                        onClick={() => activateAction && activateAction()}
                      />
                    )}
                    {product?._actions?.delete && (
                      <ButtonDropdownItem
                        id="resendInvitation"
                        label="Deactivate"
                        onClick={deleteAction ? deleteAction : () => {}}
                      />
                    )}
                  </ButtonDropdown>
                )}
            </>
          ))}
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};
