import { Box, Stack, Typography } from '@mui/material';
import IconBox from 'components/IconBox';

const CardHeaderTitleWithLabel = ({
  icon,
  label,
  title,
}: {
  icon?: any;
  label?: string;
  title?: string;
}) => (
  <Stack padding={2}>
    {label && (
      <Typography variant="captionTinyStrong" fontSize={'10px'}>
        {label}
      </Typography>
    )}
    {title && (
      <Box display="flex" alignItems={'center'} gap={1}>
        {icon && <IconBox>{icon}</IconBox>}
        <Typography variant="subheadingMedium">{title}</Typography>
      </Box>
    )}
  </Stack>
);

export default CardHeaderTitleWithLabel;
