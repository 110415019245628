import { FC } from 'react';
import { ReactComponent as TwoFAIcon } from 'assets/2fa-line-icon.svg';
import { Button } from 'components/Button/Button';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Card } from 'components/Card/Card';
import { Box, Typography } from '@mui/material';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

interface Initialise2faSetupInterface {
  handleNext: () => void;
}

export const Initialise2faSetup: FC<Initialise2faSetupInterface> = ({ handleNext }) => {
  return (
    <Card
      noPadding
      header={<CardHeaderTitleWithLabel icon={<TwoFAIcon />} title="Set up 2FA" />}
      body={
        <Box padding={2}>
          <Typography variant="bodyMedium">
            2-Factor authentication is the best way to add an additional layer of security when
            logging in to your Onyx account.
          </Typography>
        </Box>
      }
      footer={
        <Box padding={2}>
          <MuiStyledModalFooterButtons>
            <Button style={{ width: '50%' }} onClick={handleNext} variant="primary" fullWidth>
              <>
                <span>Continue</span>
              </>
            </Button>
          </MuiStyledModalFooterButtons>
        </Box>
      }
    />
  );
};
