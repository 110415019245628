import { ReactComponent as MenuDots } from 'assets/Navigation/menu-dots.svg';
import React, { FC } from 'react';
import { ClickAwayListener, IconButton, useTheme, Menu, Box } from '@mui/material';
import { interactive } from 'theme/utils/paletteColors';

interface PopoverProps {
  id: string;
  children: React.ReactNode;
  anchor?: React.ReactNode;
  dropdownPosition?: 'left' | 'right';
  className?: string;
  disabled?: Boolean;
}

export const ButtonDropdown: FC<PopoverProps> = ({
  id,
  children,
  anchor,
  dropdownPosition = 'left',
  disabled,
  ...props
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (event: React.SyntheticEvent | Event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box
        sx={{
          backgroundColor: 'transparent',
          display: 'inline-block',
          position: 'relative',
        }}
        {...props}
      >
        {/* If a button (anchor) is not passed as prop, Menu dots is the default (used on tables) */}
        {anchor ? (
          <Box onClick={handleClick}>{anchor}</Box>
        ) : (
          <IconButton
            sx={{
              display: 'flex',
              backgroundColor: 'transparent',
              width: '32px',
              height: '32px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: theme.palette?.interactive.lighter,
              },
              svg: {
                marginRight: 0,
              },
            }}
            onClick={handleClick}
          >
            <MenuDots fill={interactive.dark} />
          </IconButton>
        )}
        <Menu
          id="button-dropdown"
          sx={{ display: 'inline-block' }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: dropdownPosition === 'right' ? 'left' : 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: dropdownPosition === 'right' ? 'left' : 'right',
          }}
        >
          {children}
        </Menu>
      </Box>
    </ClickAwayListener>
  );
};
