import { Box, Stack, TableBody, TableHead, Typography } from '@mui/material';
import { ReactComponent as ExternalLinkIcon } from 'assets/external-link.svg';
import Table, { TableCell, TableRow } from 'components/Table';
import TooltipContent from 'components/Tooltip/TooltipContent';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { formatToCurrency } from 'utils/formatting';
import { Deliverable, Order, OrderStatus, OrderTrade } from 'utils/types/orders';
import { ProductType } from 'utils/types/product';
import FeeBreakdownTable from './FeeBreakdownTable';
import TotalAmountToSettleTable from './TotalAmountToSettleTable';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'utils/date';

interface ActualDeliverablesProps {
  productType: ProductType;
  order?: Order;
}

const ActualDeliverablesTable = ({ productType, order }: ActualDeliverablesProps) => {
  const deliveryType = order?.deliveryType;
  const showCostBreakDown =
    order?.status == OrderStatus.SETTLED || order?.status == OrderStatus.READY;
  const deliverables = order?.deliveries?.actual;

  const EtpCashColumns = [
    { label: 'Asset', propName: 'asset', propType: 'string', sort: false },
    { label: 'Weight', propName: 'weight', propType: 'string', sort: false },
    { label: 'Quantity', propName: 'quantity', propType: 'string', sort: false },
    {
      label: 'Closing Price',
      propName: 'closingPrice',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title="Closing Price"
          description={`The price of the asset at market close (NAV T) with valuation date ${order?.settlement?.date}. Target to execute on.`}
        />
      ),
    },
    {
      label: 'Execution Price',
      propName: 'executionPrice',
      propType: 'string',
      sort: false,
      tooltip: <TooltipContent description="Price at which the trade executed" />,
    },
    { label: 'Market Value', propName: 'marketValue', propType: 'string', sort: false },
    {
      label: 'Actual delivery',
      propName: 'actualDelivery',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title={`USD/USDC: ${order?.tradesDeliveryCurrencyFxRate || '-'}`}
          description={`The FX rate between base currency and delivery currency is ${
            order?.tradesDeliveryCurrencyFxRate || '-'
          }`}
        />
      ),
    },
  ];

  const EtpInKindColumns = [
    { label: 'Asset', propName: 'asset', propType: 'string', sort: false },
    { label: 'Weight', propName: 'weight', propType: 'string', sort: false },
    { label: 'Quantity', propName: 'quantity', propType: 'string', sort: false },
    {
      label: 'Reference Price',
      propName: 'referencePrice',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title="Reference price"
          description={`T-1 Price from PCF with valuation date ${
            deliverables &&
            deliverables[0]?.priceAt &&
            format(new Date(deliverables[0]?.priceAt), DEFAULT_DATE_FORMAT)
          }. This PCF was effective at the time of this order`}
        />
      ),
    },
    { label: 'Market Value', propName: 'marketValue', propType: 'string', sort: false },
    {
      label: 'Actual delivery',
      propName: 'actualDelivery',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title={`USD/USDC: ${order?.tradesDeliveryCurrencyFxRate || '-'}`}
          description={`The FX rate between base currency and delivery currency is ${
            order?.tradesDeliveryCurrencyFxRate || '-'
          }`}
        />
      ),
    },
  ];

  const EtpActualDeliverablesTable = (
    <Table noPadding>
      <TableHeaderWithMultiSort
        columns={deliveryType === 'CASH' ? EtpCashColumns : EtpInKindColumns}
      />
      {deliveryType === 'CASH' && (
        <TableBody>
          {order &&
            order?.trades?.map((trade: OrderTrade) => (
              <TableRow key={trade._id}>
                {/* Asset */}
                <TableCell key="ticker">{trade?.baseCurrency}</TableCell>
                {/* Weight */}
                <TableCell key="weight">{trade?.weight || '-'}</TableCell>
                {/* Quantity */}
                <TableCell key="coinQty">{trade.quantity}</TableCell>
                {/* Closing Price */}
                <TableCell key="closingPrice">
                  {formatToCurrency(trade.priceFromPriceProvider, String(trade.quoteCurrency))}
                </TableCell>
                {/* Execution Price */}
                <TableCell key="executionPrice">
                  {formatToCurrency(trade.price, String(trade.quoteCurrency))}
                </TableCell>
                {/* Market Value */}
                <TableCell key="marketValue">
                  {formatToCurrency(trade.total, String(trade.quoteCurrency))}{' '}
                </TableCell>
                {/* Market Delivery */}
                <TableCell key="actualDeliverables">
                  {formatToCurrency(trade?.totalInDeliveryCurrency, String(trade.quoteCurrency))}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      )}
      {deliveryType === 'IN_KIND' && (
        <TableBody>
          {order &&
            order?.trades?.map((trade: OrderTrade) => (
              <TableRow key={trade._id}>
                {/* Asset */}
                <TableCell key="ticker">{trade?.baseCurrency}</TableCell>
                {/* Weight */}
                <TableCell key="weight">{trade?.weight || '-'}</TableCell>
                {/* Quantity */}
                <TableCell key="coinQty">{trade.quantity}</TableCell>
                {/* Reference Price */}
                <TableCell key="referencePrice">
                  {formatToCurrency(trade.priceFromTrading, String(trade.quoteCurrency))}
                </TableCell>
                {/* Market Value */}
                <TableCell key="marketValue">
                  {formatToCurrency(trade.total, String(trade.quoteCurrency))}
                </TableCell>
                {/* Actual Delivery */}
                <TableCell key="actualDelivery">
                  {formatToCurrency(trade?.totalInDeliveryCurrency, String(trade.quoteCurrency))}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      )}
    </Table>
  );

  const TokenActualDeliverablesTable = (
    <Table noPadding>
      <TableHead>
        <TableRow>
          <TableCell>Ticker</TableCell>
          <TableCell>Transaction</TableCell>
          <TableCell align="right">Quantity</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {order?.deliveries?.actual?.map((deliverable: Deliverable, index: number) => (
          <TableRow key={`actual-deliverable-${index}`}>
            <TableCell>{deliverable?.ticker}</TableCell>
            <TableCell align="left">
              {deliverable?.transactionIds?.length ? (
                <Stack direction="column" spacing={1}>
                  {deliverable.transactionIds.map((transactionId, idx) =>
                    (deliverable.transactionUrls ?? [])[idx]?.includes(transactionId) ? (
                      <Stack direction={'row'} gap={'1'} alignItems={'center'}>
                        <a
                          href={(deliverable.transactionUrls ?? [])[idx]}
                          key={idx}
                          rel="noreferrer"
                          style={{ textDecoration: 'underline' }}
                          target="_blank"
                        >
                          <Typography variant="bodyMedium">{transactionId}</Typography>
                          <ExternalLinkIcon style={{ minWidth: '25px' }} />
                        </a>
                      </Stack>
                    ) : (
                      <Typography variant="bodySmall" key={idx}>
                        {transactionId}
                      </Typography>
                    )
                  )}
                </Stack>
              ) : (
                <Typography variant="bodySmall">-</Typography>
              )}
            </TableCell>
            <TableCell align="right">{deliverable?.amount}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <>
      {productType === 'ETP' && (
        <Stack gap={3}>
          {EtpActualDeliverablesTable}
          {showCostBreakDown && (
            <Box display="grid" gridTemplateColumns="1fr 1fr" gap={3}>
              {<FeeBreakdownTable order={order} />}
              {<TotalAmountToSettleTable order={order} />}
            </Box>
          )}
        </Stack>
      )}
      {productType === 'Token' && order?.deliveries?.actual?.length && TokenActualDeliverablesTable}
    </>
  );
};

export default ActualDeliverablesTable;
