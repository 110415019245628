import useAppModal from 'hooks/useAppModal';
import { API_KEY_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { useAuthenticatedUser, useUserPermissions } from 'store/user/selectors';
import { ApiKeysTable } from './components/WalletsTable/ApiKeysTable';
import { useGetApiKeysQuery } from './hooks/useApiKeysQuery';
import { ApiKey } from 'utils/types/apiKeys';
import { TitleArea } from 'components/TitleArea/TitleArea';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import { Card } from 'components/Card/Card';
import { ReactComponent as ExternalLinkIcon } from 'assets/external-link.svg';
import { Button } from 'components/Button/Button';

function ApiKeysPage() {
  const { user } = useAuthenticatedUser();
  const openModal = useAppModal();
  const permissions = useUserPermissions();
  const canCreateApiKey = permissions?.isAdmin;

  const { data: apiKeys, isLoading: loadingApiKeys } = useGetApiKeysQuery(
    user?.organization?.companyId ?? ''
  );

  const openApiKeyModal = (type: MODAL_ACTIONS, apiKey?: ApiKey) => {
    openModal({
      modalName: API_KEY_MODAL,
      modalData: {
        data: apiKey,
        companyData: {
          id: user?.organization?.companyId,
        },
        type: type,
      },
    });
  };

  const headerButton = canCreateApiKey && (
    <Button
      data-qa-id="newApiKeyButton"
      variant="primary"
      size="medium"
      onClick={() => openApiKeyModal(MODAL_ACTIONS.CREATE)}
      sx={{ '& span': { color: 'white' } }}
    >
      <span>New API Key</span>
      <PlusIcon />
    </Button>
  );
  return (
    <>
      <TitleArea title={'API Keys'} actionButton={headerButton} />
      <Card
        header={
          <TableTitleWithActions
            title="Active API Keys"
            actions={[
              <a
                href="https://21-co.gitbook.io/onyx-api/api-reference"
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="secondary" size="small" emphasis="medium">
                  <span>API documentation</span>
                  <ExternalLinkIcon style={{ maxHeight: '12px' }} />
                </Button>
              </a>,
            ]}
          />
        }
        headerDivider={false}
        body={
          <ApiKeysTable
            loadingData={loadingApiKeys}
            apiKeys={apiKeys || []}
            deleteAction={(apiKey) => openApiKeyModal(MODAL_ACTIONS.DELETE, apiKey)}
          />
        }
      />
    </>
  );
}

export default ApiKeysPage;
