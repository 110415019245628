import { Box, styled as MuiStyled } from '@mui/material';
import Tag from 'components/Tag/Tag';

export const StyledDeliverableItem = MuiStyled(Box)`
  color: white;
`;

export const StyledButtonsWrapper = MuiStyled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacers['spacing-08']};
  & > * {
    flex: 1;
  }
`;

export const StyledTagButton = MuiStyled(Tag)`
  cursor: pointer;
`;

export const StyledButtonsTagWrapper = MuiStyled('ul')`
  padding: 8px;
  height: auto;
  width: min-content;
`;

export const StyledTagWrapper = MuiStyled(Box)`
  display: flex;
  flex-direction: column;
`;
