import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import NewConstituent from 'shared/Modals/Constituents/CreateConstituentModal/NewConstituent';
import TabNavigation from 'components/TabNavigation';
import useAppDispatch from 'hooks/useAppDispatch';
import { CONSTITUENT_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { Card } from 'components/Card/Card';
import {
  ConstituentAssetType,
  ProductConstituentAssetType,
  TokenConstituentAssetType,
} from 'utils/types/product';
import { CustomModal } from 'shared/Modals/Modal';
import { ExistingConstituents } from 'shared/Modals/Constituents/CreateConstituentModal/ExistingConstituent';
import { IModalWithData } from 'shared/Modals/types';
import { Typography } from '@mui/material';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { loadAssets } from 'store/assets/actions';
import { postAssets } from 'utils/api/assets';
import { useCurrentProduct } from 'hooks/useCurrentProduct';
import { useMutation } from 'react-query';
import { useState } from 'react';

const EXISTING_CONSTITUENTS_TAB = 'Existing Assets';
const NEW_CONSTITUENT = 'New Asset';

export const CreateConstituentModal = ({ closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const isToken = data.custom?.isToken;

  const { product, constituents, etpMutation, tokenMutation } = useCurrentProduct(
    isToken ? 'Token' : 'ETP',
    closeModal
  );

  const isLoading = etpMutation?.isLoading || tokenMutation?.isLoading;

  const [activeTab, setActiveTab] = useState(EXISTING_CONSTITUENTS_TAB);

  const createPostAssetMutation = useMutation({
    mutationFn: (constituent: ConstituentAssetType) => {
      return postAssets({
        coingeckoId: constituent.coingeckoId,
        ticker: constituent.ticker,
        name: constituent.name,
        rounding: constituent.rounding,
        type: constituent.assetType,
      });
    },
    onSuccess: async (_, constituent) => {
      await dispatch(loadAssets());
      const updatedConstituentsList = [...constituents, constituent];

      isToken
        ? tokenMutation?.mutate({
            [CONSTITUENT_STEP]: updatedConstituentsList as TokenConstituentAssetType[],
          })
        : etpMutation?.mutate({
            [CONSTITUENT_STEP]: updatedConstituentsList as ProductConstituentAssetType[],
          });
    },
    onError: (err: Error) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  const handleSubmit = (newConstituents: ConstituentAssetType[], isNewCrypto?: Boolean) => {
    if (isNewCrypto) {
      newConstituents.length && createPostAssetMutation.mutate(newConstituents[0]);
    } else {
      const updatedConstituentsList = [...constituents, ...newConstituents];

      isToken
        ? tokenMutation?.mutate({
            [CONSTITUENT_STEP]: updatedConstituentsList as TokenConstituentAssetType[],
          })
        : etpMutation?.mutate({
            [CONSTITUENT_STEP]: updatedConstituentsList as ProductConstituentAssetType[],
          });
    }
  };

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        header={<CardHeaderTitleWithLabel label="Constituent" title="Add Assets" />}
        onClose={closeModal}
        noPadding
        body={
          <>
            <Typography variant="body2" padding={'8px 16px'}>
              You can add existing cryptos or create new crypto assets.
            </Typography>
            <TabNavigation
              style={{ paddingInline: '16px' }}
              active={activeTab}
              tabs={[
                {
                  name: EXISTING_CONSTITUENTS_TAB,
                  onSelect: () => setActiveTab(EXISTING_CONSTITUENTS_TAB),
                },
                {
                  name: NEW_CONSTITUENT,
                  onSelect: () => setActiveTab(NEW_CONSTITUENT),
                },
              ]}
            >
              {activeTab === NEW_CONSTITUENT ? (
                <NewConstituent
                  onSubmit={(data) => handleSubmit(data, true)}
                  selectedConstituents={
                    constituents?.map((constituent) => constituent.ticker) ?? []
                  }
                  toggleModal={closeModal}
                  isLoading={isLoading || createPostAssetMutation.isLoading}
                />
              ) : (
                <ExistingConstituents
                  isLoading={isLoading || createPostAssetMutation.isLoading}
                  onSubmit={handleSubmit}
                  selectedConstituents={
                    constituents?.map((constituent) => constituent.ticker) ?? []
                  }
                  toggleModal={closeModal}
                  singleAsset={product?.type === 'Single Asset'}
                />
              )}
            </TabNavigation>
          </>
        }
      />
    </CustomModal>
  );
};
