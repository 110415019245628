import { Order } from 'utils/types/orders';
import { ProductType } from 'utils/types/product';
import EmptyActualDeliverablesTable from './components/EmptyActualDeliverablesTable';
import ActualDeliverablesTable from './components/ActualDeliverablesTable';

export interface ActualDeliverablesProps {
  productType: ProductType;
  order?: Order;
}

const ActualDeliverables = ({ productType, order }: ActualDeliverablesProps) => {
  const showEmptyTable =
    productType === 'ETP' && (order?.status === 'PENDING' || order?.status === 'READY');

  return (
    <>
      {showEmptyTable && <EmptyActualDeliverablesTable order={order} />}
      {!showEmptyTable && <ActualDeliverablesTable productType={productType} order={order} />}
    </>
  );
};

export default ActualDeliverables;
