import { Stack, Typography } from '@mui/material';

interface TooltipContentProps {
  title?: string;
  description: string;
}

const TooltipContent: React.FC<TooltipContentProps> = ({ title, description }) => {
  return (
    <Stack gap={1}>
      {title && (
        <Typography variant="labelMedium" color="#fff">
          {title}
        </Typography>
      )}
      <Typography variant="labelSmall" color="#fff">
        {description}
      </Typography>
    </Stack>
  );
};

export default TooltipContent;
