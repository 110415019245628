import { capitalize } from 'lodash';
import { dateFormat } from 'shared/Tables/table.utils';
import { format, parseISO } from 'date-fns';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { PartnersUser } from 'utils/types';
import { MuiStyledActionsTableCell } from 'components/Table/table.styles';
import { isEmpty } from 'lodash';
import Tag from 'components/Tag/Tag';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';

type Props = {
  user: PartnersUser;
  editAction: () => void;
  deleteAction: () => void;
  resendInvitationAction: () => void;
  reset2FA: () => void;
  showActionsCell: boolean;
};

export const UsersTableRow = ({
  user,
  editAction,
  deleteAction,
  resendInvitationAction,
  reset2FA,
  showActionsCell = true,
}: Props) => {
  return (
    <MuiStyledTableRow data-qa-id={`${user.email}-row`} key={user._id} onClick={editAction}>
      <MuiStyledTableCell data-qa-id={`${user.email}-name`}>
        {user.firstName} {user.lastName}{' '}
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${user.email}-email`}>{user.email} </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${user.email}-role`}>
        <Tag label={capitalize(user.role)} variant="yellow" />
      </MuiStyledTableCell>
      <MuiStyledTableCell data-qa-id={`${user.email}-created`}>
        {format(parseISO(user.createdAt), dateFormat)}
      </MuiStyledTableCell>
      <MuiStyledActionsTableCell data-qa-id="actionBtn">
        {showActionsCell && !isEmpty(user._actions) && (
          <ButtonDropdown id={user._id ?? user.createdAt} className="actionsCell">
            {user?._actions?.update && (
              <ButtonDropdownItem id="editUser" label="Edit User" onClick={editAction} />
            )}
            {user?._actions?.resendInvitation && (
              <ButtonDropdownItem
                id="resendInvitation"
                label="Resend Invitation"
                onClick={resendInvitationAction}
              />
            )}
            {user?._actions?.reset2fa && (
              <ButtonDropdownItem id="reset2FA" label="Reset 2FA" onClick={reset2FA} />
            )}
            {user?._actions?.delete && (
              <ButtonDropdownItem id="deleteUser" label="Deactivate User" onClick={deleteAction} />
            )}
          </ButtonDropdown>
        )}
      </MuiStyledActionsTableCell>
    </MuiStyledTableRow>
  );
};
