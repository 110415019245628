import { alpha, Theme } from '@mui/material/styles';
import { svgIconClasses } from '@mui/material/SvgIcon';
import { AutocompleteProps, autocompleteClasses } from '@mui/material/Autocomplete';
import { menuItem, paper } from 'theme/css';
import Chip from '@mui/material/Chip';

// import { paper, menuItem } from '../../css';

// ----------------------------------------------------------------------

export function autocomplete(theme: Theme) {
  const getSizeStyles = (
    ownerState: AutocompleteProps<string, false, false, false, typeof Chip>
  ) => {
    let height;

    switch (ownerState.size) {
      case 'small':
        height = 32;
        break;
      case 'large':
        height = 48;
        break;
      default: // medium
        height = 40;
    }

    return {
      height,
    };
  };

  return {
    MuiAutocomplete: {
      defaultProps: {
        disableElevation: true,
        size: 'medium',
      },
      styleOverrides: {
        root: {
          [`& span.${autocompleteClasses.tag}`]: {
            ...theme.typography.bodyMedium,
            height: 24,
            minWidth: 24,
            lineHeight: '24px',
            textAlign: 'center',
            padding: theme.spacing(0, 0.75),
            color: theme.palette.text.secondary,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.grey[500] ?? '#919EAB', 0.16),
          },
        },
        inputRoot: ({
          ownerState,
        }: {
          ownerState: AutocompleteProps<string, false, false, false, typeof Chip>;
        }) => ({
          ...getSizeStyles(ownerState),
          [`& .${autocompleteClasses.input}`]: {
            flexGrow: 1,
            minWidth: 0,
            flexShrink: 1,
          },
          [`& .${autocompleteClasses.endAdornment}`]: {
            ...getSizeStyles(ownerState),
            [`& .${autocompleteClasses.popupIndicator}, & .${autocompleteClasses.clearIndicator}`]:
              {
                ...getSizeStyles(ownerState),
              },
          },
          alignContent: 'center',
          display: 'flex',
          flexWrap: 'nowrap',
          alignItems: 'center',
          overflow: 'hidden',
          [`& .${autocompleteClasses.tag}`]: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        }),
        endAdornment: ({
          ownerState,
        }: {
          ownerState: AutocompleteProps<string, false, false, false, typeof Chip>;
        }) => {
          return {
            ...getSizeStyles(ownerState),
            [`& .${svgIconClasses.root}`]: {
              width: 18,
              height: 18,
            },
          };
        },
        popupIndicator: ({
          ownerState,
        }: {
          ownerState: AutocompleteProps<string, false, false, false, typeof Chip>;
        }) => {
          return {
            ...getSizeStyles(ownerState),
          };
        },
        paper: {
          ...paper({ theme, dropdown: true }),
        },
        listbox: {
          padding: 0,
          [`& .${autocompleteClasses.option}`]: {
            ...menuItem(theme),
          },
        },
      },
    },
  };
}
