/* eslint-disable max-lines-per-function */
import { DefaultValues } from 'react-hook-form';

// types, constants
import { GeneralDetailsProps } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.types';
import { GENERAL_DETAILS_STEP } from 'pages/Instruments/components/Form/EtpDetails/EtpDetails.steps';
import { CASH, IN_KIND } from 'utils/constants';
import { instrumentGeneralDetailsSchemaUrl } from 'components/Form/formSchemas';

// components
import Form from 'components/Form/Form';
import Footer from 'pages/Instruments/components/Footer';
import renderCutOff from 'pages/Instruments/components/Form/EtpDetails/components/GeneralDetailsStep/utils/renderCutoff';
import renderFees from 'pages/Instruments/components/Form/EtpDetails/components/GeneralDetailsStep/utils/renderFees';

// styles
import Select from 'components/Select';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import Input from 'components/Input';
import useCurrencyOptions from 'pages/Instruments/hooks/useCurrencyOptions';
import { useMatch } from 'react-router-dom';
import { privateRoutesUrls } from 'router/constants';

// hooks
import { useInstrumentStepFormData } from 'store/instruments/selectors';

// utils
import { useSaveETPHook } from 'pages/Instruments/useSaveETPHook';
import { ETPStepSaveProgress } from 'pages/Instruments/components/SaveProgress';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import FormGridItem from 'components/Form/components/FormGridItem';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import { Fragment, useState } from 'react';
import { hourOptionsWithEndOfDay } from 'utils/select-options/timeOptions';
import booleanOptions from 'utils/select-options/booleanOptions';
import { Toggle } from 'components/BooleanInputs';

interface GeneralDetailsStepProps {
  onSubmit: () => void;
}

function GeneralDetailsStep({ onSubmit }: GeneralDetailsStepProps) {
  const { saveInstrumentDraft } = useSaveETPHook();
  const formData = useInstrumentStepFormData(GENERAL_DETAILS_STEP) as GeneralDetailsProps;
  const saveValues = async (data: DefaultValues<GeneralDetailsProps>) => {
    saveInstrumentDraft({
      [GENERAL_DETAILS_STEP]: {
        ...data,
        pcfPublishTime: data.publishPcfOnApproval === 'true' ? null : data.pcfPublishTime,
        pcfPublishTimeTimezone:
          data.publishPcfOnApproval === 'true' ? null : data.pcfPublishTimeTimezone,
      } as GeneralDetailsProps,
    });
  };

  const currencyOptions = useCurrencyOptions();

  const isDisabled =
    Boolean(!formData?.productType) && Boolean(!formData?.name) && Boolean(!formData?.ticker);

  const allowedDeliveries = formData?.allowedDeliveries;

  const [publishPcfOnApproval, setPublishPcfOnApproval] = useState<'true' | 'false'>(
    formData.pcfPublishTime ? 'false' : 'true'
  );

  const isCashDelivery = Boolean(
    allowedDeliveries && allowedDeliveries.find((delivery) => delivery === CASH)
  );
  const isInKindDelivery = Boolean(
    allowedDeliveries && allowedDeliveries.find((delivery) => delivery === IN_KIND)
  );

  const isCreateEtpDetailsRoute = Boolean(
    useMatch(privateRoutesUrls.dashboardRoutes.newEtpProductsEtpDetails)
  );

  return (
    <Form<GeneralDetailsProps>
      initialValues={formData}
      onBlur={saveValues}
      onSubmit={onSubmit}
      schemaUrl={instrumentGeneralDetailsSchemaUrl}
    >
      <FormGridContainer>
        <FormGridItem>
          <ProductConfigHeader title="General Details" />
        </FormGridItem>
        <FormGridItem display={'flex'} justifyContent={'right'}>
          <ETPStepSaveProgress />
        </FormGridItem>
        <FormGridItem>
          <Select name="productType" data-qa-id="productClass" data-qa-options-id="productClass" />
        </FormGridItem>
        <FormGridItem>
          <Input name="ticker" />
        </FormGridItem>
        <FormGridItem>
          <Input name="name" />
        </FormGridItem>
        <FormGridItem>
          <Input disabled={isDisabled} name="isin" />
        </FormGridItem>
        <FormGridItem>
          <Input disabled={isDisabled} name="unitSize" />
        </FormGridItem>
        <FormGridItem>
          <Input disabled={isDisabled} name="annualFee" step="any" />
        </FormGridItem>
        <FormGridItem>
          <MultiSelect
            disabled={isDisabled}
            name="allowedDeliveries"
            data-qa-id="allowedDeliveries"
            data-qa-options-id="allowedDeliveries"
            size="large"
          />
        </FormGridItem>
        <FormGridItem>
          <Input disabled={isDisabled} name="seriesName" />
        </FormGridItem>
        <FormGridItem>
          <Select
            disabled={isDisabled}
            name="standardSettlement"
            data-qa-id="standardSettlement"
            data-qa-options-id="standardSettlement"
          />
        </FormGridItem>
        <FormGridItem>
          <Input disabled={isDisabled} name="seriesLetter" />
        </FormGridItem>
        <FormGridItem>
          <Select
            disabled={isDisabled}
            name="currency"
            options={currencyOptions}
            data-qa-id="currency"
            data-qa-options-id="currency"
            allCaps
          />
        </FormGridItem>
        <FormGridItem>
          <Select
            disabled={isDisabled}
            name="quoteCurrency"
            options={currencyOptions}
            data-qa-id="quoteCurrency"
            data-qa-options-id="quoteCurrency"
            allCaps
          />
        </FormGridItem>
        <FormGridItem>
          <Select
            disabled={isDisabled}
            name="jurisdiction"
            data-qa-id="jurisdiciton"
            data-qa-options-id="jurisdiction"
            allCaps
          />
        </FormGridItem>
        <FormGridItem>
          <Input
            name="pricingTypeThreshold"
            data-qa-id="pricingTypeThreshold"
            data-qa-options-id="pricingTypeThreshold"
          />
        </FormGridItem>
        {/* CUT-OFF TIMES */}
        {(isCashDelivery || isInKindDelivery) &&
          renderCutOff({
            allowedDeliveries: allowedDeliveries as GeneralDetailsProps['allowedDeliveries'],
            isCreate: isCreateEtpDetailsRoute,
          })}
        {/* ADMIN FEES & EXECUTION FEES */}
        {isCashDelivery && renderFees()}
        {/* LEGAL DOCUMENTS */}
        <FormGridItem marginTop={2} md={12}>
          <ProductConfigHeader title="Legal Documents" />
        </FormGridItem>
        <Fragment key="legalDocuments">
          <FormGridItem md={6}>
            <Toggle name="euFtEnabled" sizeVariant="large" switchLabelWithTitle />
          </FormGridItem>
          <FormGridItem md={6}>
            <Toggle name="euIssEnabled" sizeVariant="large" switchLabelWithTitle />
          </FormGridItem>
          <FormGridItem md={6}>
            <Toggle name="ukFtEnabled" sizeVariant="large" switchLabelWithTitle />
          </FormGridItem>
          <FormGridItem md={6}>
            <Toggle name="ukIssEnabled" sizeVariant="large" switchLabelWithTitle />
          </FormGridItem>
        </Fragment>
        {/* PCF PUBLISH TIME */}
        <FormGridItem marginTop={2} md={12}>
          <ProductConfigHeader title="PCF" />
        </FormGridItem>
        <Fragment key="publishPcfTime">
          <FormGridItem md={6}>
            <Select
              name="publishPcfOnApproval"
              label="Publish Pcf On Approval"
              options={booleanOptions}
              onChange={(val) => setPublishPcfOnApproval(val === 'true' ? 'true' : 'false')}
              value={publishPcfOnApproval}
            ></Select>
          </FormGridItem>
          {publishPcfOnApproval === 'false' && (
            <>
              <FormGridItem md={6}>
                <Select
                  data-qa-id="pcfPublishTime"
                  name="pcfPublishTime"
                  options={hourOptionsWithEndOfDay}
                />
              </FormGridItem>
              <FormGridItem md={6}>
                <Select
                  data-qa-id="pcfPublishTimeTimezone"
                  name="pcfPublishTimeTimezone"
                  capitalize={false}
                />
              </FormGridItem>
            </>
          )}
        </Fragment>
      </FormGridContainer>
      <Footer isFirstStep />
    </Form>
  );
}

export default GeneralDetailsStep;
