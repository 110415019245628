import { Theme } from '@mui/material/styles/createTheme';

export const greenChip = (theme: Theme) => {
  const chip = theme.customComponents.chip.green;

  return [
    {
      props: (props: { variant: string }) => props.variant === 'green',
      style: {
        backgroundColor: chip.backgroundColor,
        border: chip.border,
        borderBottom: `1px solid ${chip.borderBottom}`,
        svg: {
          fill: chip.color,
        },
        '& .MuiChip-label': {
          color: chip.color,
        },
        '& .MuiChip-icon': {
          color: chip.color,
        },
        '&:hover': {
          borderBottom: `1px solid ${chip.backgroundColor}`,
        },
        '&:focus': {
          backgroundColor: chip.focus?.backgroundColor,
        },
      },
    },
  ];
};
