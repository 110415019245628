import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface IconBoxProps {
  children: ReactNode;
  background?: string;
  size?: number;
}

const IconBox = ({ children, background = '#F0F4F7', size = 24 }: IconBoxProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={size}
      height={size}
      bgcolor={background} // @TODO useTheme take color from theme when light/dark theme is added
      borderRadius="4px"
    >
      <Box display="flex" width="12px">
        {children}
      </Box>
    </Box>
  );
};

export default IconBox;
