import CustodiansTable from './components/CustodianTable/CustodianTable';
import { useMemo } from 'react';
import { useTokenContext } from 'contexts/tokens';
import { useTokenCustodiansQuery } from './hooks/useTokenCustodians';
import { useTokenWalletsQuery } from 'pages/Tokens/components/Form/PartnerDetails/components/WalletsInfoStep/hooks/useTokenWallets';
import Footer from 'pages/Tokens/components/Footer';
import { CustodiansListProps } from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.types';

interface CustodianInfoProps {
  goBack: () => void;
  onSubmit: (data: CustodiansListProps) => void;
}

export const CustodianInfo = ({ goBack, onSubmit }: CustodianInfoProps) => {
  const { currentToken, isLoadingToken } = useTokenContext();

  const {
    isLoading: loadingCustodians,
    isFetching: fetchingCustodians,
    data: custodians,
    refetch: refetchCustodians,
  } = useTokenCustodiansQuery(currentToken?._id ?? '', 'ACTIVE,DELETED');
  const { isLoading: loadingWallets, refetch: refetchWallets } = useTokenWalletsQuery(
    currentToken?._id ?? '',
    'ACTIVE,DELETED'
  );

  const partnersCustodians = useMemo(
    () =>
      Array.from(
        new Set(currentToken?.partners?.custodians?.map((custodian) => custodian.provider) || [])
      ),
    [currentToken]
  );

  const handleSubmit = () => {
    onSubmit(custodians as CustodiansListProps);
  };

  return (
    <>
      <CustodiansTable
        allCustodians={custodians as CustodiansListProps}
        partnersCustodians={partnersCustodians ?? []}
        loadingCustodians={
          loadingCustodians || fetchingCustodians || isLoadingToken || loadingWallets
        }
        onCustodianListUpdate={() => {
          refetchCustodians();
          refetchWallets();
        }}
      />
      <Footer goBack={goBack} onSubmit={handleSubmit} />
    </>
  );
};
