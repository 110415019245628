import { Button } from 'components/Button/Button';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import Select from 'components/Select';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustodianAccount, CustodianProvider, ProductStatus } from 'utils/types/product';
import { CustodianModalData } from 'shared/Modals/Custodian/CustodianModal';
import { CustomModal } from 'shared/Modals/Modal';
import { DefaultValues, useFormState } from 'react-hook-form';
import { IModalWithData } from 'shared/Modals/types';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { instrumentCustodianSchemaUrl } from 'components/Form/formSchemas';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { StyledApiSignature } from 'shared/Modals/Custodian/CustodianModal.styles';
import { createEntityApiCall, editEntityApiCall } from 'utils/api/crudActions';
import { useCurrentProduct } from 'hooks/useCurrentProduct';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Card } from 'components/Card/Card';
import { Box, Divider, Stack } from '@mui/material';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

export const EditCustodianModal = ({ closeModal, data, onCloseModalAction }: IModalWithData) => {
  const modalData = data.data;
  const isToken = data.custom?.isToken;
  const { product } = useCurrentProduct(isToken ? 'Token' : 'ETP', closeModal);

  const dispatch = useAppDispatch();
  const [custodianProvider, setCustodianProvider] = useState<CustodianProvider>();
  const { custodian } = modalData as CustodianModalData;
  const { partnersCustodians } = modalData as CustodianModalData;
  const isApiSignatureNeeded =
    custodianProvider === CustodianProvider.COINBASE ||
    custodian?.provider === CustodianProvider.COINBASE;
  const isNew = !Boolean(custodian);

  let submitButtonText = isNew ? 'Add' : 'Update';
  if (product?.status === ProductStatus.ACTIVE) {
    submitButtonText = submitButtonText + ' and Save';
  }

  const createUpdateCustodianMutation = useMutation({
    mutationFn: (custodianToSave: CustodianAccount) => {
      if (isNew) {
        return createEntityApiCall(
          custodianToSave,
          isToken ? '/admin/custodian-accounts/product-type=token' : '/admin/custodian-accounts'
        );
      } else {
        return editEntityApiCall(custodianToSave, custodianToSave?._actions?.update?.uri ?? '');
      }
    },
    onSuccess: (res) => {
      dispatch(
        createNotification({
          message: isNew
            ? 'Custodian Account has been added to the product.'
            : `Custodian List has been updated successfully`,
          title: 'Product Updated',
          type: 'success',
        })
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(errorNotification(err.message ?? ''), err));
    },
  });

  const handleSubmit = (custodianWithNewValues: CustodianAccount) => {
    createUpdateCustodianMutation.mutate({ ...custodianWithNewValues, productId: product?._id });
  };

  const handleFormUpdate = (values: DefaultValues<CustodianAccount>) => {
    if (values?.provider) {
      setCustodianProvider(values.provider as CustodianProvider);
    }
  };

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        onClose={closeModal}
        header={
          <CardHeaderTitleWithLabel
            label={'Custodian Info'}
            title={isNew ? 'Add Custodian Account' : 'Update Custodian Account'}
          />
        }
        body={
          <Form
            initialValues={{ ...custodian }}
            onSubmit={handleSubmit}
            onChange={handleFormUpdate}
            schemaUrl={instrumentCustodianSchemaUrl}
          >
            <Stack padding={2} gap={1}>
              <Select
                data-qa-id="provider"
                data-qa-options-id="provider"
                name="provider"
                options={partnersCustodians?.map((custodianOption) => ({
                  label: custodianOption,
                  value: custodianOption,
                }))}
                disabled={Boolean(custodian?.provider)}
              />
              <Input name="name" />
              <Input name="description" />
              <Input name="apiKey" />
              <Input name="apiPassphrase" />
              <StyledApiSignature hidden={!isApiSignatureNeeded}>
                <Input disabled={!isApiSignatureNeeded} name="signatureKey" />
              </StyledApiSignature>
            </Stack>
            <Divider sx={{ width: '100%' }} />
            <Box padding={2}>
              <Footer
                closeModal={closeModal}
                isLoading={createUpdateCustodianMutation.isLoading}
                submitButtonText={submitButtonText}
              />
            </Box>
          </Form>
        }
      />
    </CustomModal>
  );
};

const Footer = ({
  closeModal,
  isLoading,
  submitButtonText,
}: {
  closeModal?: () => void;
  isLoading: boolean;
  submitButtonText: string;
}) => {
  const data = useFormState();
  return (
    <MuiStyledModalFooterButtons>
      <Button data-qa-id="cancelButton" variant="secondary" fullWidth onClick={closeModal}>
        Cancel
      </Button>
      <Button
        data-qa-id="addButton"
        type="submit"
        fullWidth
        isLoading={isLoading}
        disabled={!data.isValid}
        variant="primary"
      >
        {submitButtonText}
      </Button>
    </MuiStyledModalFooterButtons>
  );
};
