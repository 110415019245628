import { useState } from 'react';
import { isEmpty } from 'lodash';

//constants, types
import { PCF_UPLOAD_MODAL } from 'shared/Modals/constants';
import { PortfolioComposition } from 'utils/types/pcfs';

//hooks
import useAppDispatch from 'hooks/useAppDispatch';
import { useActiveModal, useActiveModalData } from 'store/modals/selectors';

// actions
import { createNotification } from 'store/notifications/actions';
import { hideModal } from 'store/modals/slice';

// api, utils
import { uploadPCF } from 'utils/api/pcfs';
import { successNotification, errorNotification } from 'shared/Notifications/general.notifications';

//components
import ProductSelector from 'components/ProductSelector';

//styles
import { Button } from 'components/Button/Button';
import Upload from 'components/Upload';
import { StyledUploadFooter } from 'pages/Ledger/PCF/components/PCFStyles';
import { CustomModal } from 'shared/Modals/Modal';
import { Card } from 'components/Card/Card';
import { Box, Stack } from '@mui/material';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

interface UploadPCFModalProps {
  refetch: () => void;
}

function UploadPCFModal({ refetch }: UploadPCFModalProps) {
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>('');

  const dispatch = useAppDispatch();
  const activeModal = useActiveModal();
  const modalData = useActiveModalData(PCF_UPLOAD_MODAL);
  const closeModal = () => {
    setSelectedFiles([]);
    setIsUploading(false);

    dispatch(hideModal());
  };

  if (activeModal?.name !== PCF_UPLOAD_MODAL) return null;

  const pcf = modalData?.data as PortfolioComposition;
  const isPCFPresent = Boolean(pcf);
  const modalTitle = isPCFPresent ? pcf.product.name : 'Add new PCF';
  const modalLabel = isPCFPresent ? 'Override PCF' : 'New PCF';
  const productId = isPCFPresent ? pcf.product._id : selectedProduct;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles(Array.from(event.target.files || []));
  };

  const onClickUpload = async () => {
    setIsUploading(true);

    uploadPCF(productId, selectedFiles[0])
      .then(() => {
        dispatch(createNotification(successNotification('PCF Uploaded', 'PCF')));
        refetch();
        closeModal();
      })
      .catch((error) => {
        dispatch(createNotification(errorNotification(error.message, 'Failed to upload PCF')));
      })
      .finally(() => setIsUploading(false));
  };

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        onClose={closeModal}
        noPadding
        header={<CardHeaderTitleWithLabel label={modalLabel} title={modalTitle} />}
        body={
          <Stack padding={2} gap={1}>
            {!isPCFPresent && (
              <ProductSelector
                label="Product"
                onChange={(product) => setSelectedProduct(product!)}
                value={selectedProduct}
              />
            )}
            <Upload
              accept=".xlsx, .xls"
              helperText={
                'This will allow you to upload a PCF file. If there are no discrepancies with Onyx’s calculations, the PCF will auto-approve.'
              }
              label="Upload PCF file"
              onChange={handleFileChange}
            />
          </Stack>
        }
        footer={
          <Box padding={2}>
            <StyledUploadFooter>
              <Button
                disabled={isEmpty(selectedFiles) || !Boolean(productId)}
                isLoading={isUploading}
                variant="primary"
                onClick={onClickUpload}
              >
                Upload PCF
              </Button>
            </StyledUploadFooter>
          </Box>
        }
      />
    </CustomModal>
  );
}

export default UploadPCFModal;
