import { Button } from 'components/Button/Button';
import { StyledEditTableCell, StyledInputTableCell } from './EditableTableCell.styles';
import { TableCell } from 'components/Table';
import { useMemo, useState } from 'react';
import { InputWithCustomButtons } from './components/InputWithCustomButtons';

interface EditableTableCellProps {
  value: string | number;
  editable?: Boolean;
  updateValue: (value: string | number) => void;
  formatedValue?: string;
}

export const EditableTableCell = ({
  value,
  editable,
  updateValue,
  formatedValue,
  ...props
}: EditableTableCellProps) => {
  const [editMode, setEditMode] = useState(false);

  const editTableCell = useMemo(
    () =>
      editMode ? (
        <StyledInputTableCell>
          <InputWithCustomButtons
            value={value}
            onConfirm={(val) => {
              val && updateValue(val);
              setEditMode(false);
            }}
            onDecline={() => setEditMode(false)}
          />
        </StyledInputTableCell>
      ) : (
        <StyledEditTableCell {...props}>
          <Button size="small" variant="secondary" onClick={() => setEditMode(true)}>
            Edit
          </Button>
          <span>{formatedValue ?? value}</span>
        </StyledEditTableCell>
      ),
    [editMode, formatedValue, props, updateValue, value]
  );

  return editable ? (
    editTableCell
  ) : (
    <TableCell disabled align="right">
      {formatedValue ?? value}
    </TableCell>
  );
};
