/* eslint-disable complexity */
import Table from 'components/Table';
import { Order } from 'utils/types/orders';
import { formatToCurrency } from 'utils/formatting';
import Box from '@mui/material/Box';
import { TableBody } from '@mui/material';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import Tooltip from 'components/Tooltip';
import TooltipContent from 'components/Tooltip/TooltipContent';
import TableHeader from 'components/Table/TableHeader';

interface DealInformationProps {
  order?: Order;
  showOrderSummary: boolean;
}

const TransactionSummary = ({ order, showOrderSummary }: DealInformationProps) => {
  const baseCurrency = order?.product?.currency || 'USD';
  const deliveryCurrency = order?.deliveryCurrency;

  const orderSummaryColumnsConfig = [
    { label: 'Order Summary', propName: 'orderSummary', propType: 'string', width: '100%' },
  ];

  return (
    <>
      <Table noPadding={true}>
        <TableBody>
          {/* Product Base Currency */}
          <MuiStyledTableRow>
            <MuiStyledTableCell>Product Base Currency</MuiStyledTableCell>
            <MuiStyledTableCell align="right">{baseCurrency}</MuiStyledTableCell>
          </MuiStyledTableRow>
          {/* Order Market Value */}
          <MuiStyledTableRow>
            <MuiStyledTableCell>
              <Box display="flex" gap={1}>
                Order Market Value
                <Tooltip
                  title={
                    <TooltipContent
                      description={
                        order?.status === 'PENDING'
                          ? 'Estimated Settlement Value'
                          : 'Settlement Value'
                      }
                    />
                  }
                ></Tooltip>
              </Box>
            </MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {Boolean(order?.deliveries?.expectedTotalCash)
                ? formatToCurrency(order?.deliveries?.expectedTotalCash, baseCurrency)
                : '-'}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
        </TableBody>
      </Table>
      {showOrderSummary && (
        <Table noPadding={true}>
          <TableHeader columns={orderSummaryColumnsConfig} />
          <TableBody>
            {/* Order Amount */}
            <MuiStyledTableRow>
              <MuiStyledTableCell>Order Amount</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {Boolean(order?.tradesTotalFromPriceProviderInDeliveryCurrency)
                  ? formatToCurrency(
                      order?.tradesTotalFromPriceProviderInDeliveryCurrency,
                      deliveryCurrency
                    )
                  : '-'}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            {/* Slippage */}
            <MuiStyledTableRow>
              <MuiStyledTableCell>Slippage</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {Boolean(order?.tradesTotalSlippageFromTotalAndPriceProvider)
                  ? formatToCurrency(
                      order?.tradesTotalSlippageFromTotalAndPriceProvider,
                      baseCurrency
                    )
                  : '-'}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            {/* Trade amount */}
            <MuiStyledTableRow>
              <MuiStyledTableCell>
                <Box display="flex" gap={1}>
                  Trade amount
                  <Tooltip
                    title={<TooltipContent description={'The order amount including slippage'} />}
                  ></Tooltip>
                </Box>
              </MuiStyledTableCell>
              <MuiStyledTableCell align="right" nowrap="true">
                {Boolean(order?.tradesTotalInDeliveryCurrency)
                  ? formatToCurrency(order?.tradesTotalInDeliveryCurrency, deliveryCurrency)
                  : '-'}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            {/* Total Fees */}
            <MuiStyledTableRow>
              <MuiStyledTableCell>Total Fees</MuiStyledTableCell>
              <MuiStyledTableCell align="right" nowrap="true">
                {Boolean(order?.tradesTotalFeesInDeliveryCurrency)
                  ? formatToCurrency(order?.tradesTotalFeesInDeliveryCurrency, deliveryCurrency)
                  : '-'}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            {/* Total amount to settle */}
            <MuiStyledTableRow>
              <MuiStyledTableCell>Total amount to settle</MuiStyledTableCell>
              <MuiStyledTableCell align="right" nowrap="true">
                {Boolean(order?.tradesTotalWithTotalFeeInDeliveryCurrency)
                  ? formatToCurrency(
                      order?.tradesTotalWithTotalFeeInDeliveryCurrency,
                      deliveryCurrency
                    )
                  : '-'}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default TransactionSummary;
