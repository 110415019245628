import useAppDispatch from 'hooks/useAppDispatch';
import { ConstituentAssetType } from 'utils/types/product';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { NewRebalanceConstituent } from './NewRebalanceConstituent';
import { RebalanceWithConstitentsDetails } from 'utils/types/rebalance';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { postAssets } from 'utils/api/assets';
import { useMutation } from 'react-query';
import { useProductData } from 'shared/Modals/NavReviewModals/hook/useProductData';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { Card } from 'components/Card/Card';
import { Box, Typography, useTheme } from '@mui/material';

export const AddConstituentsModal = ({ closeModal, data }: IModalWithData) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const rebalanceData = data.data as RebalanceWithConstitentsDetails;
  const productId = rebalanceData.rebalance.product._id;
  const { isLoading: isLoadingProduct } = useProductData(productId);
  const newConstituent = rebalanceData.constituent;

  const createPostAssetMutation = useMutation({
    mutationFn: (constituent: ConstituentAssetType) => {
      return postAssets({
        coingeckoId: constituent.coingeckoId,
        ticker: constituent.ticker,
        name: constituent.name,
        rounding: constituent.rounding,
        type: constituent.assetType,
      });
    },
    onError: (err: Error) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  const handleSubmit = (newConstituent: ConstituentAssetType) => {
    return newConstituent && createPostAssetMutation.mutate(newConstituent);
  };

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        header={
          <Box>
            <CardHeaderTitleWithLabel label="Constituent" title="Add Assets" />
          </Box>
        }
        body={
          <Box p={2}>
            <Typography variant="bodyMedium" color={theme.palette.secondary.light} pb={1}>
              You can add existing cryptos or create new crypto assets.
            </Typography>
            <NewRebalanceConstituent
              onSubmit={(data) => handleSubmit(data)}
              selectedConstituent={newConstituent}
              toggleModal={closeModal}
              isLoading={createPostAssetMutation.isLoading || isLoadingProduct}
            />
          </Box>
        }
        onClose={closeModal}
      />
    </CustomModal>
  );
};
