import Table from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { Deliverable, Order } from 'utils/types/orders';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { ProductType } from 'utils/types';
import { TableBody, Typography } from '@mui/material';
import { formatToCurrency } from 'utils/formatting';
import TooltipContent from 'components/Tooltip/TooltipContent';
import { format } from 'date-fns';
import { DEFAULT_DATE_FORMAT } from 'utils/date';

interface ExpectedDeliverablesExpectedTableProps {
  productType: ProductType;
  order?: Order;
}

const ExpectedDeliverablesTable = ({
  productType,
  order,
}: ExpectedDeliverablesExpectedTableProps) => {
  const deliverables = order?.deliveries?.expected;

  const etpColumns = [
    { label: 'Asset', propName: 'asset', propType: 'string', sort: false },
    { label: 'Weight', propName: 'weight', propType: 'string', sort: false },
    { label: 'Quantity', propName: 'quantity', propType: 'string', sort: false },
    {
      label: 'Reference Price',
      propName: 'referencePrice',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title="Reference price"
          description={`T-1 Price from PCF with valuation date ${
            deliverables &&
            deliverables[0]?.priceAt &&
            format(new Date(deliverables[0]?.priceAt), DEFAULT_DATE_FORMAT)
          }. This PCF was effective at the time of this order`}
        />
      ),
    },
    { label: 'Market Value', propName: 'marketValue', propType: 'string', sort: false },
    {
      label: 'Expected delivery',
      propName: 'expectedDelivery',
      propType: 'string',
      sort: false,
      tooltip: (
        <TooltipContent
          title={`USD/USDC: ${deliverables && deliverables[0]?.fxRate}`}
          description={`The FX rate between base currency and delivery currency is ${
            deliverables && deliverables[0]?.fxRate
          }`}
        />
      ),
    },
  ];

  const tokenColumns = [
    { label: 'Asset', propName: 'ticker', propType: 'string' },
    { label: 'Quantity', propName: 'quantity', propType: 'number' },
  ];

  return (
    <>
      {productType === 'ETP' && (
        <Table noPadding={true}>
          <TableHeaderWithMultiSort columns={etpColumns} />
          <TableBody>
            {deliverables?.length ? (
              deliverables?.map((deliverable: Deliverable) => (
                <MuiStyledTableRow key={deliverable.ticker}>
                  {/* Asset */}
                  <MuiStyledTableCell key="ticker">{deliverable?.ticker}</MuiStyledTableCell>
                  {/* Weight */}
                  <MuiStyledTableCell key="weight">{deliverable?.weight || '-'}</MuiStyledTableCell>
                  {/* Quantity */}
                  <MuiStyledTableCell key="coinQty">{deliverable?.amount}</MuiStyledTableCell>
                  {/* Reference Price */}
                  <MuiStyledTableCell key="priceT1Nav">
                    {formatToCurrency(deliverable.price, deliverable?.currency)}
                  </MuiStyledTableCell>
                  {/* Market Value */}
                  <MuiStyledTableCell key="value">
                    {formatToCurrency(deliverable.totalInCurrency, deliverable?.currency)}
                  </MuiStyledTableCell>
                  {/* Actual Delivery */}
                  <MuiStyledTableCell key="expectedTotalWithCash">
                    {formatToCurrency(deliverable.totalInDeliveryCurrency, order?.deliveryCurrency)}
                  </MuiStyledTableCell>
                </MuiStyledTableRow>
              ))
            ) : (
              <MuiStyledTableRow>
                {/* Asset */}
                <MuiStyledTableCell key="ticker">
                  <Typography variant="labelMedium">Total</Typography>
                </MuiStyledTableCell>
                {/* Weight */}
                <MuiStyledTableCell key="weight">-</MuiStyledTableCell>
                {/* Quantity */}
                <MuiStyledTableCell key="coinQty">-</MuiStyledTableCell>
                {/* Reference Price */}
                <MuiStyledTableCell key="priceT1Nav">-</MuiStyledTableCell>
                {/* Market Value */}
                <MuiStyledTableCell key="value">-</MuiStyledTableCell>
                {/* Actual Delivery */}
                <MuiStyledTableCell key="expectedTotalWithCash">-</MuiStyledTableCell>
              </MuiStyledTableRow>
            )}
          </TableBody>
        </Table>
      )}
      {productType === 'Token' && (
        <Table noPadding={true}>
          <TableHeaderWithMultiSort
            columns={tokenColumns}
            columnAlignment={{ quantity: 'right' }}
          />
          <TableBody>
            {deliverables?.length ? (
              deliverables?.map((deliverable: Deliverable, index) => (
                <MuiStyledTableRow key={index}>
                  <MuiStyledTableCell key="ticker">{deliverable?.ticker}</MuiStyledTableCell>
                  <MuiStyledTableCell align="right" key="coinQty">
                    {deliverable.amount}
                  </MuiStyledTableCell>
                </MuiStyledTableRow>
              ))
            ) : (
              <MuiStyledTableRow>
                <MuiStyledTableCell key="ticker">-</MuiStyledTableCell>
                <MuiStyledTableCell align="right" key="cointQty">
                  -
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default ExpectedDeliverablesTable;
