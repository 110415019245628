import { alpha } from '@mui/material/styles';
import { ThemeTokens } from 'theme/themeTokens/types/ThemeTokensTypes';
import { ligthThemeColors } from './colors';

export const lightThemeTokens: ThemeTokens = {
  static: {
    background: {
      default: {
        weak: ligthThemeColors.neutral[0],
        standard: ligthThemeColors.neutral[10],
        strong: ligthThemeColors.neutral[20],
      },
      inverse: {
        weak: ligthThemeColors.neutral[80],
        standard: ligthThemeColors.neutral[90],
        strong: ligthThemeColors.neutral[100],
      },
    },
    text: {
      default: {
        weak: ligthThemeColors.neutral[50],
        standard: ligthThemeColors.neutral[60],
        strong: ligthThemeColors.neutral[90],
      },
      inverse: {
        weak: ligthThemeColors.neutral[50],
        standard: ligthThemeColors.neutral[40],
        strong: ligthThemeColors.neutral[10],
      },
      sentiment: {
        negative: ligthThemeColors.red[80],
        positive: ligthThemeColors.green[80],
        notice: ligthThemeColors.yellow[80],
        info: ligthThemeColors.blue[80],
      },
      sentimentInverse: {
        negative: ligthThemeColors.red[40],
        positive: ligthThemeColors.green[40],
        notice: ligthThemeColors.yellow[40],
        info: ligthThemeColors.blue[40],
      },
      divider: {
        default: {
          weak: alpha('#98ABB8', 0.1),
          standard: alpha('#98ABB8', 0.2),
          strong: alpha('#98ABB8', 0.32),
        },
        inverse: {
          weak: alpha('#DBE6EC', 0.1),
          standard: alpha('#DBE6EC', 0.2),
          strong: alpha('#E7ECF1', 0.32),
        },
      },
    },
    icon: {
      default: {
        weak: ligthThemeColors.neutral[50],
        standard: ligthThemeColors.neutral[60],
        strong: ligthThemeColors.neutral[90],
      },
      inverse: {
        weak: ligthThemeColors.neutral[50],
        standard: ligthThemeColors.neutral[40],
        strong: ligthThemeColors.neutral[10],
      },
    },
    support: {
      weak: {
        negative: ligthThemeColors.red[10],
        positive: ligthThemeColors.green[10],
        notice: ligthThemeColors.yellow[10],
        info: ligthThemeColors.blue[10],
        neutral: alpha('#B4BBBF', 0.08),
      },
      standard: {
        negative: ligthThemeColors.red[80],
        positive: ligthThemeColors.green[80],
        notice: ligthThemeColors.yellow[80],
        info: ligthThemeColors.blue[80],
        neutral: ligthThemeColors.neutral[80],
      },
      strong: {
        negative: ligthThemeColors.red[70],
        positive: ligthThemeColors.green[70],
        notice: ligthThemeColors.yellow[70],
        info: ligthThemeColors.blue[70],
        neutral: ligthThemeColors.neutral[70],
      },
    },
  },
  interactive: {
    fill: {
      primary: {
        enabled: ligthThemeColors.orange[60],
        hover: ligthThemeColors.orange[70],
        pressed: ligthThemeColors.orange[80],
        disabled: ligthThemeColors.neutral[20],
        onPrimary: ligthThemeColors.neutral[10],
        onPrimaryDisabled: ligthThemeColors.neutral[40],
      },
      secondary: {
        enabled: ligthThemeColors.neutral[60],
        hover: ligthThemeColors.neutral[70],
        pressed: ligthThemeColors.neutral[50],
        disabled: ligthThemeColors.neutral[20],
        onSecondary: ligthThemeColors.neutral[10],
        onSecondaryDisabled: ligthThemeColors.neutral[60],
      },
      tertiary: {
        enabled: ligthThemeColors.neutral[20],
        hover: ligthThemeColors.neutral[30],
        pressed: ligthThemeColors.neutral[40],
        disabled: ligthThemeColors.neutral[20],
        onTertiary: ligthThemeColors.neutral[100],
        onTertiaryDisabled: ligthThemeColors.neutral[40],
      },
      support: {
        enabled: ligthThemeColors.gold[70],
        hover: ligthThemeColors.gold[60],
        pressed: ligthThemeColors.gold[50],
        disabled: ligthThemeColors.neutral[20],
        onSupport: ligthThemeColors.neutral[0],
        onSupportDisabled: ligthThemeColors.neutral[40],
      },
      inverse: {
        enabled: ligthThemeColors.neutral[20],
        hover: ligthThemeColors.neutral[30],
        pressed: ligthThemeColors.neutral[40],
        disabled: ligthThemeColors.neutral[80],
        onInverse: ligthThemeColors.neutral[100],
        onInverseDisabled: ligthThemeColors.neutral[60],
      },
      danger: {
        enabled: ligthThemeColors.red[60],
        hover: ligthThemeColors.red[80],
        pressed: ligthThemeColors.red[90],
        disabled: ligthThemeColors.neutral[20],
        onDanger: ligthThemeColors.neutral[0],
        onDangerDisabled: ligthThemeColors.neutral[40],
      },
    },
    overlay: {
      primary: {
        enabled: alpha(ligthThemeColors.orange[60], 0.001),
        hover: alpha(ligthThemeColors.orange[60], 0.1),
        pressed: alpha(ligthThemeColors.orange[60], 0.2),
      },
      primarySelected: {
        enabled: alpha(ligthThemeColors.orange[60], 0.25),
        hover: alpha(ligthThemeColors.orange[60], 0.3),
        pressed: alpha(ligthThemeColors.orange[60], 0.4),
      },
      secondary: {
        enabled: alpha('#A1BAD0', 0.001),
        hover: alpha('#A1BAD0', 0.08),
        pressed: alpha('#A1BAD0', 0.12),
      },
      secondarySelected: {
        enabled: alpha('#A1BAD0', 0.16),
        hover: alpha('#A1BAD0', 0.24),
        pressed: alpha('#A1BAD0', 0.32),
      },
      inverse: {
        enabled: alpha(ligthThemeColors.neutral[0], 0.001),
        hover: alpha(ligthThemeColors.neutral[0], 0.09),
        pressed: alpha(ligthThemeColors.neutral[0], 0.16),
      },
      inverseSelected: {
        enabled: alpha(ligthThemeColors.neutral[0], 0.15),
        hover: alpha(ligthThemeColors.neutral[0], 0.25),
        pressed: alpha(ligthThemeColors.neutral[0], 0.35),
      },
      danger: {
        enabled: alpha(ligthThemeColors.red[70], 0.001),
        hover: alpha(ligthThemeColors.red[70], 0.06),
        pressed: alpha(ligthThemeColors.red[70], 0.1),
      },
      dangerSelected: {
        enabled: alpha(ligthThemeColors.red[70], 0.05),
        hover: alpha(ligthThemeColors.red[70], 0.1),
        pressed: alpha(ligthThemeColors.red[70], 0.15),
      },
    },
    outline: {
      primary: {
        default: ligthThemeColors.orange[60],
        hover: ligthThemeColors.orange[70],
        active: ligthThemeColors.neutral[100],
        pressed: ligthThemeColors.orange[80],
        disabled: ligthThemeColors.neutral[30],
        error: ligthThemeColors.red[70],
        focus: alpha(ligthThemeColors.orange[60], 0.001),
      },
      secondary: {
        default: ligthThemeColors.neutral[30],
        hover: ligthThemeColors.neutral[40],
        active: ligthThemeColors.neutral[90],
        pressed: ligthThemeColors.neutral[60],
        disabled: ligthThemeColors.neutral[20],
        error: ligthThemeColors.red[70],
        focus: alpha('#A1BAD0', 0.001),
      },
      inverse: {
        default: ligthThemeColors.neutral[70],
        hover: ligthThemeColors.neutral[60],
        active: ligthThemeColors.neutral[50],
        pressed: ligthThemeColors.neutral[30],
        disabled: ligthThemeColors.neutral[80],
        error: ligthThemeColors.red[70],
        focus: alpha(ligthThemeColors.neutral[0], 0.001),
      },
      danger: {
        default: ligthThemeColors.red[60],
        enabled: ligthThemeColors.red[60],
        hover: ligthThemeColors.red[80],
        active: ligthThemeColors.neutral[100],
        pressed: ligthThemeColors.red[90],
        disabled: ligthThemeColors.red[20],
      },
    },
  },
};
