import { Theme } from '@mui/material/styles/createTheme';

export const defaultChip = (theme: Theme) => {
  const chip = theme.customComponents.chip.default;
  return [
    {
      props: (props: { variant: string }) => props.variant === 'default',
      style: {
        backgroundColor: chip.backgroundColor,
        border: chip.border,
        borderBottom: `1px solid ${chip.borderBottom}`,
        svg: {
          fill: chip.color,
        },
        '& .MuiChip-label': {
          color: chip.color,
        },
        '& .MuiChip-icon': {
          color: chip.color,
        },
        '&:hover': {
          borderBottom: `1px solid ${chip.backgroundColor}`,
        },
        '&:focus': {
          backgroundColor: chip.focus?.backgroundColor,
        },
      },
    },
  ];
};
