import { Button } from 'components/Button/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { useActiveModalData } from 'store/modals/selectors';
import { IModalWithData } from 'shared/Modals/types';
import { YES_OR_NO_MODAL } from 'shared/Modals/constants';
import { createNotification } from 'store/notifications/actions';
import { CustomModal } from 'shared/Modals/Modal';
import { useMutation } from 'react-query';
import { createEntityApiCall } from 'utils/api/crudActions';
import { errorNotification, successNotification } from 'shared/Notifications/general.notifications';
import { RebalanceWithDetails } from 'utils/types/rebalance';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Box, Stack, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

export const SkipRebalanceModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const modalData = useActiveModalData(YES_OR_NO_MODAL);
  const rebalance = data.data as RebalanceWithDetails;

  const skipRebalanceMutation = useMutation({
    mutationFn: (apiUri: string) => {
      return createEntityApiCall(null, apiUri);
    },
    onSuccess: () => {
      dispatch(
        createNotification(
          successNotification(
            'The ETP rebalancing has been skipped for this occurrence',
            'Rebalance succesfully skipped'
          )
        )
      );
      closeModal();
      if (onCloseModalAction) onCloseModalAction(modalData?.data);
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message, 'Error'), error));
    },
  });

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        fullWidth
        onClick={() =>
          rebalance._actions.skip?.uri && skipRebalanceMutation.mutate(rebalance._actions.skip?.uri)
        }
        type="submit"
        data-qa-id="confirmButton"
      >
        Skip rebalance
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        onClose={closeModal}
        header={<CardHeaderTitleWithLabel title="Skip Rebalance" />}
        body={
          <Stack padding={2} gap={1}>
            <Typography variant="bodyMedium">
              Are you sure you want to skip the rebalance?
            </Typography>
            <Typography variant="bodyMedium">
              No rebalance trades will be sent to the OMS, and the ledger will not be updated.
            </Typography>
            <Typography variant="bodyMedium">
              Once submitted, this action cannot be changed.
            </Typography>
          </Stack>
        }
        footer={<Box padding={2}>{Footer}</Box>}
      />
    </CustomModal>
  );
};
