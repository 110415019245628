import { LinkVariantsInterface } from 'theme/themeTokens/types/ThemeComponentTypes';
import { ThemeTokens } from 'theme/themeTokens/types/ThemeTokensTypes';

export const linkPalette = (themeVariant: ThemeTokens): LinkVariantsInterface => ({
  primary: {
    color: themeVariant.interactive.fill.primary.enabled,
    hover: {
      backgroundColor: themeVariant.interactive.overlay.primary.hover,
    },
  },
  secondary: {
    color: themeVariant.static.support.strong.info,
    hover: {
      backgroundColor: themeVariant.interactive.overlay.secondary.hover,
    },
  },
});
