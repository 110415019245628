import Tag from 'components/Tag/Tag';
import ProductIcon from 'components/ProductIcon';
import {
  StyledHeaderNotes,
  StyledHeaderTitleComponent,
  StyledHeaderTitleWrapper,
  StyledRebalancesHeader,
} from 'pages/Rebalance/RebalancePage.styles';
import { getUtcDateString } from 'utils/date';
import { DateTime } from 'utils/datetime';
import { escapeNaN, formatAsPercentage } from 'utils/formatting';
import { Rebalance } from 'utils/types/rebalance';

export type RebalancesCardHeaderProps = {
  rebalance: Rebalance;
  isPastTab?: boolean;
};

const getTagLabel = (date: string) => {
  return new DateTime(date).isSameDate(new DateTime()) ? 'Today' : getUtcDateString(date);
};

export const RebalancesCardHeader = ({ rebalance, isPastTab }: RebalancesCardHeaderProps) => {
  return (
    <StyledRebalancesHeader>
      <StyledHeaderTitleWrapper>
        <StyledHeaderTitleComponent>
          <ProductIcon ticker={rebalance.product?.ticker} iconUrl={rebalance.product?.fundIcon} />
          {rebalance.product?.ticker}
          {rebalance?.skipAt && <Tag label={'Skipped'} variant="green" />}
          {rebalance?.approvedAt && !rebalance?.skipAt && (
            <Tag label={'Approved'} variant="green" />
          )}
        </StyledHeaderTitleComponent>
        <Tag
          label={getTagLabel(isPastTab ? rebalance.date : rebalance.nextRebalanceDate)}
          variant="yellow"
        />
      </StyledHeaderTitleWrapper>
      <StyledHeaderNotes>
        <span>{`Turnover: ${formatAsPercentage(rebalance.turnover)}`}</span>
        <span>{`Cost of Trades: ${
          rebalance.product?.quoteCurrency === 'USD'
            ? `$${escapeNaN(rebalance.costOfTrades)}`
            : `${escapeNaN(rebalance.costOfTrades)} ${rebalance.product?.quoteCurrency}`
        }`}</span>
      </StyledHeaderNotes>
    </StyledRebalancesHeader>
  );
};
