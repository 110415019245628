import { ChipProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { defaultChip } from './variants/defaultChip';
import { greenChip } from './variants/greenChip';
import { redChip } from './variants/redChip';
import { blueChip } from './variants/blueChip';
import { yellowChip } from './variants/yellowChip';
import { inverseChip } from './variants/inverseChip';
import { neutral } from 'theme/colors/baseColors';
import { twentyOneCoVariants } from 'theme/utils/typography';

export function chip(theme: Theme) {
  return {
    MuiChip: {
      variants: [
        ...defaultChip(theme),
        ...greenChip(theme),
        ...redChip(theme),
        ...blueChip(theme),
        ...yellowChip(theme),
        ...inverseChip(theme),
      ],
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ChipProps & { expandable?: boolean } }) => {
          return {
            borderRadius: '6px',
            padding: '6px 8px',
            boxShadow: 'none',
            gap: '4px',
            '& .MuiChip-label': {
              padding: 0,
              overflow: 'visible',
              ...twentyOneCoVariants.labelSmall,
              lineHeight: 1,
            },
            '& .MuiChip-deleteIcon': {
              margin: 0,
              marginLeft: '4px',
            },
            '& .MuiChip-icon': {
              margin: 0,
            },
            '&.Mui-disabled': {
              backgroundColor: neutral[20],
              borderBottom: `1px solid ${neutral[30]}`,
              '& .MuiChip-label': {
                color: neutral[60],
              },
              '& .MuiChip-icon': {
                color: neutral[60],
              },
            },
            '&:focus-visible': {
              boxShadow: theme.customComponents.chip.default.focusVisible?.boxShadow ?? 'none',
            },
          };
        },
      },
    },
  };
}
