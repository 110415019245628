import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { ReactComponent as Logo } from 'assets/logo.svg';
import {
  StyledLoggedOutContentWrapper,
  StyledLoginContainer,
  StyledLoginLogoWrapper,
  StyledNeedHelp,
} from 'pages/Login/Login.styles';
import { contactOnyxSupport } from 'utils/externalLinks';
import { isLoggedIn, removeUserLoggedOut } from 'utils/user/storage';
import { privateRoutesUrls, publicRoutesUrls } from 'router/constants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { Column } from 'components/Grid';

export const LoggedOutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    removeUserLoggedOut();
    if (isLoggedIn()) {
      navigate(privateRoutesUrls.dashboardRoutes.rootPath);
    }
  }, [navigate]);

  return (
    <StyledLoginContainer>
      <StyledLoggedOutContentWrapper>
        <StyledLoginLogoWrapper>
          <Logo width={192} height={96} />
        </StyledLoginLogoWrapper>
        <Card
          noPadding
          header={
            <Box padding={2}>
              <Typography variant="subheadingMedium">Log out</Typography>
            </Box>
          }
          body={
            <Box padding={2}>
              <Typography variant="bodyMedium">You have been logged out.</Typography>
            </Box>
          }
          footer={
            <Column
              offset={6}
              lg={{ cols: 6, offset: 6 }}
              md={{ cols: 4, offset: 4 }}
              xs={{ cols: 4, offset: 0 }}
            >
              <Button
                variant="primary"
                fullWidth
                type="submit"
                onClick={() => navigate(publicRoutesUrls.login)}
              >
                Sign In
              </Button>
            </Column>
          }
        />
        <StyledNeedHelp cols={12}>
          Need Help?{' '}
          <a href={contactOnyxSupport} target="_blank" rel="noreferrer">
            Contact Onyx support.
          </a>
        </StyledNeedHelp>
      </StyledLoggedOutContentWrapper>
    </StyledLoginContainer>
  );
};
