import BigNumber from 'bignumber.js';
import useAppModal from 'hooks/useAppModal';
import { MODAL_ACTIONS, NAV_REVIEW_MODAL } from 'shared/Modals/constants';
import { NavReviewInforBar } from './components/NavReviewInforBar';
import { format } from 'date-fns';
import { useNavReviewDataQuery } from './hooks/useNavReviewDataQuery';
import { useMemo } from 'react';
import { NavReviewItem, NavReviewItemWithProduct, NavReviewResponseDto } from 'utils/types/nav';
import { PortfolioCompositionStatus } from 'utils/types/pcfs';
import { NavStatusBarInfo } from 'utils/types/nav';
import { DEFAULT_DATE_FORMAT } from 'utils/date';
import NavReviewTable from 'shared/Tables/NavReviewTable/NavReviewTable';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { Card } from 'components/Card/Card';
import { useUrlParams } from 'hooks/useUrlParams';
import { privateRoutesUrls } from 'router/constants';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { useBackendPagination } from 'hooks/useBackendPagination';
import Pagination from 'components/Pagination';

const defaultParams = {
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_ITEMS_PER_PAGE,
  productId: null,
  sort: null,
  valuationDate: format(new Date(), DEFAULT_DATE_FORMAT),
};

const mapNavReviewItems = (navReviewItems: NavReviewResponseDto[]): NavReviewItemWithProduct[] => {
  return navReviewItems
    .map((navItem) => {
      return navItem.netAssetValueComparison.map((item) => {
        return {
          ...item,
          rawDifference: new BigNumber(item.difference).toNumber(),
          product: navItem.product,
          portfolioId: navItem._id,
        };
      });
    })
    .flat();
};

const getInforBarCountData = (navReviewItems: NavReviewItemWithProduct[]): NavStatusBarInfo => ({
  total: navReviewItems.length,
  totalApproved: navReviewItems.filter(
    (curr) => curr.status === PortfolioCompositionStatus.APPROVED
  ).length,
  totalWithError: navReviewItems.filter(
    (curr) => curr.status === PortfolioCompositionStatus.PENDING
  ).length,
});

export const NavReviewPage = () => {
  const openModal = useAppModal();

  const { urlParams, setUrlParams } = useUrlParams(
    defaultParams,
    privateRoutesUrls.dashboardRoutes.nav
  );

  const {
    data: navPaginatedData,
    isLoading: loading,
    isFetching: fetching,
    refetch,
  } = useNavReviewDataQuery({ ...urlParams });
  const navReviewItems = useMemo(
    () => mapNavReviewItems(navPaginatedData?.data ?? []),
    [navPaginatedData?.data]
  );

  const openNavReviewModal = (type: MODAL_ACTIONS, navReviewItem?: NavReviewItem) => {
    openModal(
      {
        modalName: NAV_REVIEW_MODAL,
        modalData: {
          data: navReviewItem as unknown as NavReviewItem,
          type: type,
        },
      },
      {
        onCloseModalAction: () => refetch(),
      }
    );
  };

  const reviewBarInfoData = useMemo(
    () => getInforBarCountData(navReviewItems ?? []),
    [navReviewItems]
  );

  const pagination = useBackendPagination(navPaginatedData ?? null, setUrlParams);

  return (
    <>
      <TitleArea title="NAV Review" />
      <Card
        header={
          <NavReviewInforBar
            setQueryParams={setUrlParams}
            queryParams={urlParams}
            overviewBarInfoData={navReviewItems.length ? reviewBarInfoData : null}
          />
        }
        headerDivider={false}
        body={
          <NavReviewTable
            navReviewItems={navReviewItems ?? []}
            editAction={(navReviewItem) => openNavReviewModal(MODAL_ACTIONS.EDIT, navReviewItem)}
            queryParams={urlParams}
            setQueryParams={setUrlParams}
            loading={loading || fetching}
          />
        }
        footer={<Pagination {...pagination} />}
      />
    </>
  );
};
