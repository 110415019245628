import { Box, Stack, Typography } from '@mui/material';
import { Button } from 'components/Button/Button';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { Card } from 'components/Card/Card';
import Notification from 'components/Notification';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { IModalWithData } from 'shared/Modals/types';
import { UserForm } from 'shared/Modals/Users/UpdateUser/UpdateUserModal';

interface ExistingContactProps extends IModalWithData {
  user: UserForm;
  handleUpdate: (formValues: UserForm) => void;
}

export const ExistingContact = ({ user, handleUpdate, closeModal }: ExistingContactProps) => {
  const handleSubmit = () => {
    handleUpdate({ ...user, replaceContact: true });
  };
  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button data-qa-id="cancelButton" variant="secondary" fullWidth onClick={closeModal}>
        Cancel
      </Button>
      <Button
        data-qa-id="addButton"
        variant="primary"
        type="submit"
        fullWidth
        onClick={handleSubmit}
      >
        Merge & Confirm Invite
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <Card
      noPadding
      header={<CardHeaderTitleWithLabel label="Contacts" title="Duplicate contact" />}
      footer={<Box padding={2}>{Footer}</Box>}
      body={
        <Stack padding={2}>
          <Typography>Email ${user?.email} has been identified as an existing contact</Typography>
          <Notification
            message={`Inviting this user will merge them with the contact "${user?.email}" to avoid duplicate notifications.`}
            variant="warning"
            messageMaxWidth
          />
        </Stack>
      }
      onClose={closeModal}
    />
  );
};
