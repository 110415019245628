import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import OrderDetails from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails';
import downloadOrderCsv from 'utils/orders/downloadOrderCsv';
import { OrderPageHeader } from 'pages/Orders/Orders/Order/components/OrderPageHeader';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useCurrentOrder } from 'pages/Orders/Orders/Order/hooks/useCurrentOrder';
import { useOrdersActions } from 'pages/Orders/Orders/hooks/useOrdersActions/useOrdersActions';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { usePusherInitializer } from 'hooks/usePusherInitializer';
import { OrderActions } from 'pages/Orders/Orders/Order/components/OrderActions';

export const OrderView: React.FC = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const { user } = useAuthenticatedUser();

  const { isLoading: loading, data: order, refetch } = useCurrentOrder(orderId!);

  const { downloadPdf } = useOrdersActions(orderId);

  const handleOrderUpdate = useCallback(() => {
    console.log(`Order ${orderId} needs to be refreshed`);
    refetch().then(() => {
      console.log(`Order ${orderId} has been refreshed`);
    });
  }, [orderId, refetch]);

  usePusherInitializer(handleOrderUpdate);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <TitleArea
        title={<OrderPageHeader productType="ETP" order={order} />}
        showBackButton={true}
        actionButton={<OrderActions productType="ETP" order={order} />}
      />
      {order && (
        <OrderDetails
          productType="ETP"
          loading={loading}
          user={user}
          order={order}
          orderFlow="confirm"
          downloadCsv={() => downloadOrderCsv(order, user!, 'ETP')}
          downloadPdf={() => downloadPdf(order)}
        />
      )}
    </>
  );
};
