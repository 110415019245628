import { Button } from 'components/Button/Button';
import { DEFER_ORDER_MODAL } from 'shared/Modals/constants';
import { DeferOrderModalData } from 'shared/Modals/Orders/OrdersModal';
import { IModalWithData } from 'shared/Modals/types';
import { useButtonsActions } from 'pages/Orders/Orders/hooks/useButtonsActions';
import { CustomModal } from 'shared/Modals/Modal';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Card } from 'components/Card/Card';
import { Box, Typography } from '@mui/material';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

function OrderDeferConfirmationModal({ closeModal, data }: IModalWithData) {
  const orderModalData = data.data as DeferOrderModalData;
  const actions = useButtonsActions(orderModalData?.order, orderModalData?.refreshQueryKey);

  const confirmAction = actions.find((action) => action.modal === DEFER_ORDER_MODAL);

  const onConfirm = () => {
    confirmAction && confirmAction.callback();
    closeModal();
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button variant="secondary" fullWidth onClick={closeModal}>
        Cancel
      </Button>
      <Button variant="primary" fullWidth onClick={onConfirm}>
        Confirm
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        onClose={closeModal}
        noPadding
        header={<CardHeaderTitleWithLabel label={'Order'} title={'Defer Settlement'} />}
        body={
          <Box padding={2}>
            <Typography variant="bodyMedium">
              A deferred order will count towards the nav and shares outstanding on its settlement
              date even if the order is not yet settled.
            </Typography>
          </Box>
        }
        footer={<Box padding={2}>{Footer}</Box>}
      />
    </CustomModal>
  );
}

export default OrderDeferConfirmationModal;
