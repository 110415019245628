import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import Table, { TableProps } from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { Calendar, CalendarEvent } from 'utils/types/calendar';
import { CalendarsTableRow } from 'shared/Tables/CalendarsTable/CalendarsTableRow';
import { ParamsType } from 'hooks/useUrlParams';
import { CalendarsTableColumnsConfig } from './tableColumnsConfig';

interface CalendarsTableProps {
  calendars: Calendar[];
  deleteAction: (calendar: Calendar) => void;
  editAction: (calendar: Calendar) => void;
  subitemEditAction: (calendar: Calendar, calendarEvent?: CalendarEvent) => void;
  subitemDeleteAction: (calendar: Calendar, calendarEvent: CalendarEvent) => void;
  loadingData: boolean;
  tableProperties: TableProps;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
}

const doesAnyCalendarHaveActions = (calendars: Calendar[]) => {
  return Boolean(
    calendars.find((calendar) => calendar._actions?.update || calendar._actions?.delete)
  );
};

export const CalendarsTable = ({
  calendars,
  deleteAction,
  editAction,
  subitemEditAction,
  subitemDeleteAction,
  loadingData,
  tableProperties,
  setUrlParams,
  urlParams,
}: CalendarsTableProps) => {
  const showActionsCell = doesAnyCalendarHaveActions(calendars);

  return (
    <Table {...tableProperties} noPadding>
      <TableHeaderWithMultiSort
        showActionsCell={showActionsCell}
        defaultSortedColumns={urlParams.sort ?? []}
        columns={CalendarsTableColumnsConfig}
        onColumnSort={(columns) => {
          setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
        }}
      />
      <TableBodyWithStates
        loadingData={Boolean(loadingData)}
        hasContent={!isEmpty(calendars)}
        noContentLabel="No calendars at the moment."
      >
        {calendars?.map((calendar) => (
          <CalendarsTableRow
            key={calendar._id}
            calendar={calendar}
            editAction={() => {
              editAction(calendar);
            }}
            deleteAction={() => {
              deleteAction(calendar);
            }}
            subitemEditAction={(calendarEvent?: CalendarEvent) => {
              subitemEditAction(calendar, calendarEvent);
            }}
            subitemDeleteAction={(calendarEvent: CalendarEvent) => {
              subitemDeleteAction(calendar, calendarEvent);
            }}
            showActionsCell={showActionsCell}
          />
        ))}
      </TableBodyWithStates>
    </Table>
  );
};
