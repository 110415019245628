import { Button } from 'components/Button/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { Column } from 'components/Grid';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { Styled2FaSetupContentWrapper } from 'pages/Login/Setup2fa/Setup2FA.styles';
import { StyledLoginContainer, StyledLoginLogoWrapper } from 'pages/Login/Login.styles';
import { StyledPasscodeInput } from 'shared/CompositeComponents/2FASetupFlow/TwoFaSetup.styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { apiUrls } from 'utils/constants/apiUrls';
import { auth2fa } from 'utils/api/auth';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { privateRoutesUrls } from 'router/constants';
import { removeWeakToken, storeStrongToken } from 'utils/user/storage';
import { useNavigate } from 'react-router-dom';
import { Card } from 'components/Card/Card';
import { Box, Typography } from '@mui/material';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

export const EnterOTPCode = () => {
  const {
    register,
    formState: { isValid },
    handleSubmit,
  } = useForm<{ passcodeOTPInput: number }>({ mode: 'onChange' });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<{ passcodeOTPInput: number }> = ({ passcodeOTPInput }) => {
    auth2fa(apiUrls.auth2Fa, String(passcodeOTPInput))
      .then((res) => {
        if (res.data.token) {
          removeWeakToken();
          storeStrongToken(res.data.token);
          navigate(privateRoutesUrls.dashboardRoutes.rootPath);
        }
      })
      .catch((err) => {
        dispatch(createNotification(errorNotification(err.message, err.name)));
      });
  };

  return (
    <StyledLoginContainer>
      <Styled2FaSetupContentWrapper>
        <StyledLoginLogoWrapper>
          <Logo width={192} height={96} />
        </StyledLoginLogoWrapper>
        <Card
          noPadding
          header={<CardHeaderTitleWithLabel label={'2FA Setup'} title={'Security code'} />}
          body={
            <Box padding={2}>
              <Typography variant="bodyMedium">
                Enter your authenticator 6-digit passcode
              </Typography>
              <form id="passcodeForm" onSubmit={handleSubmit(onSubmit)}>
                <StyledPasscodeInput
                  autoFocus
                  {...register('passcodeOTPInput', {
                    required: 'Enter otp code',
                    minLength: {
                      value: 6,
                      message: 'OTP code length should be 6 digits',
                    },
                    maxLength: {
                      value: 6,
                      message: 'OTP code length should be 6 digits',
                    },
                  })}
                  type="number"
                />
              </form>
            </Box>
          }
          footer={
            <Column
              offset={6}
              lg={{ cols: 6, offset: 6 }}
              md={{ cols: 4, offset: 4 }}
              xs={{ cols: 4, offset: 0 }}
            >
              <Button
                variant="primary"
                type="submit"
                form="passcodeForm"
                disabled={!isValid}
                fullWidth
              >
                Verify
              </Button>
            </Column>
          }
        />
      </Styled2FaSetupContentWrapper>
    </StyledLoginContainer>
  );
};
