import { ComponentPropsWithoutRef, useEffect, useRef } from 'react';
import useToggle from 'hooks/useToggle';
import useEvents from 'components/Select/hooks/useEvents';
import {
  GroupedOptionsType,
  OptionType,
  OptionValueType,
  OnChangeType,
} from 'components/Select/Select.types';
import { PopperPlacementType } from '@mui/material/Popper';
import { SelectAutocomplete } from './components/SelectAutocomplete';
import { Dropdown } from './components/Dropdown';

export interface SelectProps<T extends OptionValueType>
  extends Omit<ComponentPropsWithoutRef<'select'>, 'onChange' | 'value' | 'size'> {
  clearable?: boolean;
  ['data-qa-id']?: string;
  ['data-qa-options-id']?: string;
  errorMessage?: string;
  filterable?: boolean;
  helperText?: string;
  inline?: boolean;
  isInvalid?: boolean;
  label?: string;
  options?: OptionType<T>[] | GroupedOptionsType<T>;
  onChange?: OnChangeType<T>;
  onFilter?: (filter: string | undefined) => void;
  value?: T;
  mapOptions?: (options: OptionType<T>[]) => OptionType<T>[];
  resetOnChange?: string[];
  optionsPlacement?: PopperPlacementType;
  isPaginationSelect?: boolean;
  size?: 'small' | 'medium' | 'large';
  allCaps?: Boolean;
  capitalize?: Boolean;
  backgroundColor?: string;
}

function SelectComponent<T extends OptionValueType>({
  className,
  clearable,
  'data-qa-id': dataQaId,
  'data-qa-options-id': dataQaOptionsId,
  disabled,
  errorMessage,
  helperText,
  filterable,
  inline,
  isInvalid,
  label,
  onChange,
  onFilter,
  options,
  placeholder,
  value,
  optionsPlacement,
  isPaginationSelect,
  size = 'large',
  backgroundColor = 'transparent',
  ...props
}: SelectProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLButtonElement>(null);

  const clearFilter = () => {
    if (onFilter) {
      onFilter(undefined);
    }
  };
  const [isOptionsOpen, toggleOptions] = useToggle(false, { onClose: clearFilter });

  useEffect(() => {
    if (isInvalid && !filterable) {
      innerRef?.current?.setCustomValidity('invalid');
    } else {
      innerRef?.current?.setCustomValidity('');
    }
  }, [isInvalid, filterable]);

  useEvents(isOptionsOpen, containerRef, toggleOptions, optionsRef);

  return filterable ? (
    <SelectAutocomplete
      size={size}
      helperText={helperText}
      options={options}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      label={label}
      errorMessage={errorMessage}
      disabled={disabled}
      {...props}
    />
  ) : (
    <Dropdown
      backgroundColor={backgroundColor}
      helperText={helperText}
      options={options}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      label={label}
      errorMessage={errorMessage}
      disabled={disabled}
      isPaginationSelect={isPaginationSelect}
      size={size}
      {...props}
    />
  );
}

export default SelectComponent;
