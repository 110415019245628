import { Button } from 'components/Button/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { createNotification } from 'store/notifications/actions';
import { MuiStyledFooterWrapper } from 'shared/CompositeComponents/2FASetupFlow/TwoFaSetup.styles';
import { Box, Typography } from '@mui/material';
import { useDeleteApiKeysMutation } from 'pages/Admin/ApiKeysPage/hooks/useApiKeysQuery';
import { useAuthenticatedUser } from 'store/user/selectors';
import { ApiKey } from 'utils/types/apiKeys';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

export const DeleteApiKeyModal = ({ data, closeModal }: IModalWithData) => {
  const { data: apiKey } = data;
  const {
    issuer: { name },
    _actions: {
      delete: { uri },
    },
  } = apiKey as ApiKey;

  const { user } = useAuthenticatedUser();
  const { mutate: deleteApiKey } = useDeleteApiKeysMutation(user?.organization?.companyId || '');

  const dispatch = useAppDispatch();

  function handleDelete() {
    deleteApiKey(uri, {
      onSuccess: () => {
        dispatch(
          createNotification({
            title: 'Admin',
            message: `API Key deleted`,
            type: 'success',
          })
        );
        closeModal();
      },
      onError: (error) => {
        dispatch(
          createNotification(
            {
              title: 'Admin',
              message: `API Key not deleted`,
              type: 'error',
            },
            error as Error
          )
        );
        closeModal();
      },
    });
  }

  const Footer = (
    <MuiStyledFooterWrapper>
      <Button
        data-qa-id="cancelButton"
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
      >
        Cancel
      </Button>
      <Button
        data-qa-id="deleteButton"
        variant="danger"
        fullWidth
        onClick={() => handleDelete()}
        type="button"
      >
        Delete
      </Button>
    </MuiStyledFooterWrapper>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        onClose={closeModal}
        header={<CardHeaderTitleWithLabel label="API Keys" title="Delete API Key" />}
        body={
          <Box padding={2}>
            <Typography variant="bodyMedium">
              {`Are you sure you want to delete API key created for ${name}?`}
            </Typography>
          </Box>
        }
        footer={<Box padding={2}>{Footer}</Box>}
      />
    </CustomModal>
  );
};
