import { LinkOwnProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import spaceTokens from 'theme/utils/spacers';
import { neutral } from 'theme/colors/baseColors';

export function link(theme: Theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: LinkOwnProps }) => {
          return {
            gap: spaceTokens['spacing-08'],
            fontWeight: 500,
            minWidth: 'unset',
            textDecoration: 'none',
            '&:hover': {
              cursor: 'pointer',
            },
            '&:focus-visible': {
              borderRadius: '4px',
              boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
              background: theme.customPalette.interactive.overlay.inverse.enabled,
            },
            '&:visited': {
              color: neutral[70],
            },
          };
        },
      },
    },
  };
}
