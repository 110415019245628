import Tooltip from 'components/Tooltip';
import { Box } from '@mui/material';
import { Button } from 'components/Button/Button';

export type ActionButtonWithTooltipProps = {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  isLoading?: boolean;
  enableTooltip?: Boolean;
  tooltipText?: string;
};
export const ActionButtonWithTooltip = ({
  button,
}: {
  button: ActionButtonWithTooltipProps | null;
}) => {
  const ActionButton = (
    <Button
      onClick={button?.onClick}
      size="medium"
      variant="primary"
      isLoading={button?.isLoading}
      disabled={button?.disabled}
    >
      {button?.label}
    </Button>
  );

  const ButtonTooltip = (
    <Tooltip
      title={<Box sx={{ padding: 1, color: 'white' }}>{button?.tooltipText}</Box>}
      arrow={true}
      placement="bottom"
      bgColor="black"
    >
      <span>{ActionButton}</span>
    </Tooltip>
  );

  return button && (button.enableTooltip ? ButtonTooltip : ActionButton);
};
