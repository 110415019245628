import { Button } from 'components/Button/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { Exchange } from 'utils/types';
import { IModalWithData } from 'shared/Modals/types';
import { createNotification } from 'store/notifications/actions';
import { deleteExchange } from 'utils/api/exchanges';
import { exchangeDeletedNotifications } from 'shared/Notifications/exchange.notifications';
import { useMutation } from 'react-query';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Box, Stack, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';

const DeleteExchangeModal = ({ closeModal, data, onCloseModalAction }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const exchange = data?.data as Exchange;

  const deleteExchangeMutation = useMutation({
    mutationFn: (exchangeId: string) => deleteExchange(exchangeId),
    onSuccess: () => {
      dispatch(createNotification(exchangeDeletedNotifications.success(exchange.name)));
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (error: Error) => {
      dispatch(createNotification(exchangeDeletedNotifications.error(error.message), error));
    },
  });

  const onDeleteHandler = (exchange: Exchange) => {
    exchange._id && deleteExchangeMutation.mutate(exchange._id);
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="danger"
        fullWidth
        onClick={() => onDeleteHandler(exchange)}
        type="submit"
        data-qa-id="deleteButton"
        isLoading={deleteExchangeMutation.isLoading}
      >
        Delete
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        onClose={closeModal}
        noPadding
        header={
          <Box padding={2}>
            <Typography variant="subheadingMedium">Exchange Deletion</Typography>
          </Box>
        }
        body={
          <Stack padding={2} gap={1}>
            <Typography variant="bodyMedium">
              Are you sure you want to delete {exchange.name}?
            </Typography>
            <Typography variant="bodyMedium">This action cannot be undone.</Typography>
          </Stack>
        }
        footer={<Box padding={2}>{Footer}</Box>}
      />
    </CustomModal>
  );
};

export default DeleteExchangeModal;
