import { TableHeaderColumn } from 'components/Table/Table.types';

export const NavReviewDifferencesColumnConfig: TableHeaderColumn[] = [
  {
    label: 'Name',
    propName: 'name',
    propType: 'string',
    sort: false,
    width: '25%',
  },
  {
    label: 'Onyx',
    propName: 'onyx',
    propType: 'string',
    sort: false,
    width: '25%',
  },
  {
    label: 'Accountant',
    propName: 'accountant',
    propType: 'string',
    sort: false,
    width: '25%',
  },
  {
    label: 'Difference',
    propName: 'difference',
    propType: 'string',
    sort: false,
    width: '25%',
  },
];

export const NavReviewDifferencesColumnConfigFA: TableHeaderColumn[] = [
  {
    label: 'Name',
    propName: 'name',
    propType: 'string',
    sort: false,
    width: '50%',
  },
  {
    label: 'Accountant',
    propName: 'accountant',
    propType: 'string',
    sort: false,
    width: '50%',
  },
];
